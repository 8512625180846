import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Input, Select } from "antd";
import Lottie from "react-lottie-player";

import { ImBin2 } from "react-icons/im";

import { Button, Text, TextField, Modal, Layout } from "../../components";
import { Colors, MockData } from "../../config";

import { UniversityAction } from "../../store/actions";

import Done from "../../assets/images/57767-done.json";
import styles from "./styles";

function VisaInformation() {
  const dispatch = useDispatch();
  const location = useLocation();

  const data = useSelector((state) => state.university.university);
  const id = location.pathname.split("/")[2];

  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [state, setState] = useState({
    costOfApplication: data.visaInformation?.costOfApplication
      ? data.visaInformation?.costOfApplication?.split(" ")[1]
      : "NA",
    costOfApplicationCurrency: data.visaInformation?.costOfApplication
      ? data.visaInformation?.costOfApplication?.split(" ")[0]
      : "USD",
    medicalFees:
      !data.visaInformation?.medicalFees ||
      data.visaInformation?.medicalFees === "NA"
        ? "NA"
        : data.visaInformation?.medicalFees?.split(" ")[1],
    medicalFeesCurrency:
      !data.visaInformation?.medicalFees ||
      data.visaInformation?.medicalFees === "NA"
        ? "USD"
        : data.visaInformation?.medicalFees?.split(" ")[0],
    miscellFees:
      !data.visaInformation?.miscellFees ||
      data.visaInformation?.miscellFees === "NA"
        ? "NA"
        : data.visaInformation?.miscellFees?.split(" ")[1],
    miscellFeesCurrency:
      !data.visaInformation?.miscellFees ||
      data.visaInformation?.miscellFees === "NA"
        ? "USD"
        : data.visaInformation?.miscellFees?.split(" ")[0],
    durationOfVisa: data.visaInformation?.durationOfVisa
      ? data.visaInformation?.durationOfVisa
      : "NA",
    processing: data.visaInformation?.processing
      ? data.visaInformation?.processing
      : "NA",
    interview: data.visaInformation?.interview
      ? data.visaInformation?.interview
      : "NA",
    studentVisa: data.visaInformation?.studentVisa
      ? data.visaInformation?.studentVisa
      : "NA",
  });

  useEffect(() => {
    dispatch(UniversityAction.getUniversityDetails(id));
  }, []);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleClear = () => {
    setState({
      costOfApplication: "",
      costOfApplicationCurrency: "USD",
      medicalFees: "",
      medicalFeesCurrency: "USD",
      miscellFees: "",
      miscellFeesCurrency: "USD",
      durationOfVisa: "",
      processing: "",
      interview: "",
      studentVisa: "",
    });
  };

  const handleNext = () => {
    setSaveButtonDisabled(true);
    if (
      !state.durationOfVisa ||
      !state.costOfApplication ||
      !state.processing ||
      !state.studentVisa
    ) {
      setSaveButtonDisabled(false);
      setError("Please fill all required fields");
    } else {
      setError("");
      const body = {
        visaInformation: {
          costOfApplication: `${state.costOfApplicationCurrency} ${state.costOfApplication}`,
          medicalFees: !state.medicalFees
            ? "NA"
            : `${state.medicalFeesCurrency} ${state.medicalFees}`,
          miscellFees: !state.miscellFees
            ? "NA"
            : `${state.miscellFeesCurrency} ${state.miscellFees}`,
          durationOfVisa: state.durationOfVisa,
          processing: state.processing,
          interview: !state.interview ? "NA" : state.interview,
          studentVisa: state.studentVisa,
        },
      };
      let dataToUpload = new FormData();
      dataToUpload.append(
        "visaInformation",
        JSON.stringify(body.visaInformation)
      );
      dataToUpload.append("tab", "visaInformation");

      dispatch(
        UniversityAction.editUniversity(id, dataToUpload, (res) => {
          setSaveButtonDisabled(false);
          if (res.status === 200) {
            setShowModal(true);
          }
        })
      );
    }
  };

  return (
    <div style={styles.inputContainer}>
      <div style={styles.justifyBetween}>
        <Text.Heading
          fontSize={"16px"}
          fontWeight={"700"}
          color={Colors.Primary}
          text="Visa Requirements"
        />
        <div onClick={handleClear}>
          <Text.Heading
            fontSize={"10px"}
            fontWeight={"400"}
            color={Colors.Gray}
            text="Clear Fields"
          />
          <ImBin2 color={Colors.Primary} fontSize={14} />
        </div>
      </div>
      <div style={styles.costTitleContainer}>
        <div>
          <div style={styles.inputLabel}>Duration of Visa *</div>
          <TextField.CustomInput
            placeholder="4"
            name="durationOfVisa"
            value={state.durationOfVisa}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div className="input-group">
          <div style={styles.inputLabel}>Cost of Application *</div>
          <Input.Group compact>
            <Select
              defaultValue="USD"
              name="costOfApplicationCurrency"
              value={state.costOfApplicationCurrency}
              onChange={(value) =>
                setState({
                  ...state,
                  costOfApplicationCurrency: value,
                })
              }
              style={styles.currencyInput}
              showSearch
            >
              {MockData.Currency.map((item) => (
                <Select.Option value={item.value}>{item.name}</Select.Option>
              ))}
            </Select>
            <Input
              style={styles.currencyValueInput}
              placeholder="Amount"
              name="costOfApplication"
              value={state.costOfApplication}
              onChange={handleChange}
            />
          </Input.Group>
        </div>
        <div className="input-group">
          <div style={styles.inputLabel}>Medical Fees</div>
          <Input.Group compact>
            <Select
              defaultValue="USD"
              name="medicalFeesCurrency"
              value={state.medicalFeesCurrency}
              onChange={(value) =>
                setState({
                  ...state,
                  medicalFeesCurrency: value,
                })
              }
              style={styles.currencyInput}
              showSearch
            >
              {MockData.Currency.map((item) => (
                <Select.Option value={item.value}>{item.name}</Select.Option>
              ))}
            </Select>
            <Input
              style={styles.currencyValueInput}
              placeholder="Amount"
              name="medicalFees"
              value={state.medicalFees}
              onChange={handleChange}
            />
          </Input.Group>
        </div>
        <div className="input-group">
          <div style={styles.inputLabel}>Miscellaneous Fees</div>
          <Input.Group compact>
            <Select
              defaultValue="USD"
              name="miscellFeesCurrency"
              value={state.miscellFeesCurrency}
              onChange={(value) =>
                setState({
                  ...state,
                  miscellFeesCurrency: value,
                })
              }
              style={styles.currencyInput}
              showSearch
            >
              {MockData.Currency.map((item) => (
                <Select.Option value={item.value}>{item.name}</Select.Option>
              ))}
            </Select>
            <Input
              style={styles.currencyValueInput}
              placeholder="Amount"
              name="miscellFees"
              value={state.miscellFees}
              onChange={handleChange}
            />
          </Input.Group>
        </div>
      </div>
      <Layout.Spacer height={30} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"700"}
        color={Colors.Primary}
        text="Visa Processing Journey"
      />
      <div style={styles.costTitleContainer}>
        <div>
          <div style={styles.inputLabel}>Visa Processing *</div>
          <TextField.CustomInput
            placeholder="2"
            name="processing"
            optional={true}
            value={state.processing}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Visa Interview *</div>
          <TextField.CustomInput
            placeholder="4"
            name="interview"
            value={state.interview}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Student Visa *</div>
          <TextField.CustomInput
            placeholder="1"
            name="studentVisa"
            value={state.studentVisa}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
      </div>
      {error && (
        <div style={styles.flexColumnCenter}>
          <Layout.Spacer height={20} />
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"500"}
            color={Colors.Danger}
            text={error}
          />
        </div>
      )}
      <div style={styles.flexColumnCenter}>
        <Button.Primary
          style={styles.buttonStyle}
          onClick={handleNext}
          disabled={saveButtonDisabled}
        >
          Save/Continue
        </Button.Primary>
      </div>
      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => setShowModal(false)}
          closeIcon={true}
          className="ant-modal"
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={styles.modalIcon}
            ></Lottie>
            <div style={styles.modalText}>Successful!</div>
            <Button.Primary
              style={styles.modalButtonStyle}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Done
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
    </div>
  );
}
export default VisaInformation;
