import { Colors } from "../../config";

const styles = {
  flexRowSpace: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexRowStart: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  mb5: {
    marginBottom: "5px",
  },

  // cardRow
  cardRowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "20px",
    backgroundColor: Colors.LightGray,
    padding: "15px 15px",
    borderRadius: "5px",
  },
  leftSide: {
    marginLeft: "15px",
    display: "flex",
    flexDirection: "row",
    minWidth: "600px",
  },
  cardImageStyle: {
    height: "40px",
    width: "40px",
    borderRadius: "100%",
    background: "white",
    objectFit: "contain",
  },
  applicationNameContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "15px",
  },
  cardHeading: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#081C15",
    textTransform: "capitalize",
    textAlign: "left",
  },
  cardSubHeading: {
    fontSize: "12px",
    fontWeight: "400",
    color: Colors.GrayText,
    textTransform: "capitalize",
    textAlign: "left",
  },
  // index
  profileImageContainer: {
    width: "10%",
    margin: "0px 30px",
  },
  studentProfileImage: {
    width: "100px",
    height: "100px",
    objectFit: "contain",
    borderRadius: "100%",
    background: "white",
    border: `1px solid ${Colors.Primary}`,
  },
  studentName: {
    fontSize: "22px",
    fontWeight: "500",
    color: Colors.Primary,
    textTransform: "capitalize",
    textAlign: "left",
  },
  studentInfo: {
    fontSize: "12px",
    fontWeight: "400",
    color: Colors.BlackText2,
    textTransform: "capitalize",
  },
  tabsContainer: {
    display: "flex",
    background: Colors.Primary,
    height: "40px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "500",
  },
  tabName: { marginLeft: "60px", marginRight: "60px", cursor: "pointer" },

  // general
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.LightGray,
    borderRadius: "10px",
    width: "250px",
    height: "190px",
  },
  cardContainerRightSide: {
    display: "flex",
    background: Colors.LightGray,
    borderRadius: "10px",
  },
  mapContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "85%",
    height: "100%",
    padding: "20px",
    position: "relative",
    background: Colors.LightGray,
    borderRadius: "10px",
  },
  mapCircle: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#479c90",
    borderRadius: "100%",
    height: "50px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mapValue: { color: "white", fontSize: "15px", marginTop: "8px" },

  // application
  cardContainerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    background: Colors.LightGray,
    width: "70%",
    height: "100%",
    borderRadius: "10px",
    margin: "auto",
  },
};

export default styles;
