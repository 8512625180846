import {
  GET_SCHOOLS,
  GET_SCHOOLS_FAILURE,
  GET_SCHOOLS_SUCCESS,
  GET_SCHOOL_DETAILS,
  GET_SCHOOL_DETAILS_FAILURE,
  GET_SCHOOL_DETAILS_SUCCESS,
  ADD_SCHOOL,
  ADD_SCHOOL_FAILURE,
  ADD_SCHOOL_SUCCESS,
  CHANGE_SCHOOL_STATUS,
  CHANGE_SCHOOL_STATUS_FAILURE,
  CHANGE_SCHOOL_STATUS_SUCCESS,
} from "../constants";

export default class SchoolAction {
  static getSchools(payload, cb) {
    return {
      type: GET_SCHOOLS,
      payload,
      cb,
    };
  }
  static getSchoolsSuccess(payload) {
    return {
      type: GET_SCHOOLS_SUCCESS,
      payload,
    };
  }
  static getSchoolsFailure(payload) {
    return {
      type: GET_SCHOOLS_FAILURE,
      payload,
    };
  }
  static getSchoolDetails(id, cb) {
    return {
      type: GET_SCHOOL_DETAILS,
      id,
      cb,
    };
  }
  static getSchoolDetailsSuccess(payload) {
    return {
      type: GET_SCHOOL_DETAILS_SUCCESS,
      payload,
    };
  }
  static getSchoolDetailsFailure(payload) {
    return {
      type: GET_SCHOOL_DETAILS_FAILURE,
      payload,
    };
  }
  static addSchool(payload, cb) {
    return {
      type: ADD_SCHOOL,
      payload,
      cb,
    };
  }
  static addSchoolSuccess(payload) {
    return {
      type: ADD_SCHOOL_SUCCESS,
      payload,
    };
  }
  static addSchoolFailure(payload) {
    return {
      type: ADD_SCHOOL_FAILURE,
      payload,
    };
  }
  static changeSchoolStatus(payload, cb) {
    return {
      type: CHANGE_SCHOOL_STATUS,
      payload,
      cb,
    };
  }
  static changeSchoolStatusSuccess(payload) {
    return {
      type: CHANGE_SCHOOL_STATUS_SUCCESS,
      payload,
    };
  }
  static changeSchoolStatusFailure(payload) {
    return {
      type: CHANGE_SCHOOL_STATUS_FAILURE,
      payload,
    };
  }
}
