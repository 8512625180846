import { Colors } from "../../config";

const styles = {
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexRowBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  justifyCenter: { display: "flex", justifyContent: "center" },

  profileContainer: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr",
  },

  line: {
    background: "rgba(3, 129, 117, 0.22)",
    height: "100%",
    width: "1px",
    marginInline: "30px",
  },
  profileImage: {
    borderRadius: "100%",
    height: "170px",
    width: "170px",
    objectFit: "contain",
    background: "white",
    border: `1px solid ${Colors.Primary}`,
  },
};

export default styles;
