import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Select, DatePicker } from "antd";

import {
  AnnouncementAction,
  IndividualStudentAction,
  UniversityAction,
} from "../../store/actions";

import { Button, Layout, Text, TextField, Loader } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

function AddAnnouncement() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const individualStudents = useSelector(
    (state) => state?.individualStudents?.individualStudents?.individualStudents
  );
  const individualStudentsLoading = useSelector(
    (state) => state?.individualStudents?.isLoading
  );
  const universityLoading = useSelector((state) => state.university);
  const universityState = useSelector(
    (state) => state.university.allUniversities
  );

  const [error, setError] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [state, setState] = useState({
    subject: "",
    recepient: "all",
    studentId: "all",
    expiryDate: "",
    message: "",
    universityAssociatedWith: "",
  });

  useEffect(() => {
    if (universityState?.length === 0) {
      dispatch(UniversityAction.getAllUniversities());
    }
    const payload = {
      limit: "",
      search: "",
      filter: "",
      offset: 0,
    };
    dispatch(IndividualStudentAction.getIndividualStudents(payload));
  }, []);

  const handleAddAnnouncement = () => {
    if (
      !state.subject ||
      !state.recepient ||
      !state.studentId ||
      !state.message ||
      !state.expiryDate
    ) {
      setError("Please fill all fields");
    } else {
      setError("");
      setButtonDisable(true);
      let body;
      if (state.universityAssociatedWith) {
        body = {
          subject: state.subject,
          recepient: state.recepient,
          studentId: state.studentId,
          expiryDate: state.expiryDate,
          message: state.message,
          universityAssociatedWith: state.universityAssociatedWith,
        };
      } else {
        body = {
          subject: state.subject,
          recepient: state.recepient,
          studentId: state.studentId,
          expiryDate: state.expiryDate,
          message: state.message,
        };
      }
      console.log(body);
      dispatch(
        AnnouncementAction.addAnnouncement(body, (res) => {
          if (res.status !== 201) {
            setButtonDisable(false);
          } else {
            navigate("/announcements");
          }
        })
      );
    }
  };

  return (
    <>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="New Announcement"
      />
      <Layout.Spacer height={10} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.BlackText2}
        text="Have an important news for your students? You can notify them by making an announcement."
      />
      <Layout.Spacer height={20} />
      {universityLoading?.isLoading || individualStudentsLoading ? (
        <Loader.Circular />
      ) : (
        <div style={styles.flexColumn}>
          <TextField.CustomInput
            placeholder="Enter the subject of the announcement"
            value={state.subject}
            onChange={(e) =>
              setState({
                ...state,
                subject: e.target.value,
              })
            }
            label="Subject*"
            style={styles.textfieldStyle}
          />

          <Layout.Spacer height={10} />
          <div className="announcement-select" style={styles.flexColumn}>
            <label style={styles.labelStyle}>Recipient </label>
            <Select
              value={state.recepient}
              onChange={(value) => {
                setState({
                  ...state,
                  recepient: value,
                  studentId: value,
                });
              }}
              style={styles.selectField}
            >
              <Select.Option value="all">All</Select.Option>
              {individualStudents?.map((item) => (
                <Select.Option
                  style={{ textTransform: "capitalize" }}
                  value={item?.studentId?._id}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Layout.Spacer height={20} />
          <div style={styles.flexColumn}>
            <label style={styles.labelStyle}>University Associated With </label>
            <div className="announcement-select">
              <Select
                style={styles.selectField}
                onChange={(universityJSON) => {
                  var university = JSON.parse(universityJSON);
                  setState({
                    ...state,
                    universityAssociatedWith: university._id,
                  });
                }}
                showSearch
                placeholder="Search University "
                optionFilterProp="children"
              >
                {universityState.map((dropdown) => {
                  return (
                    <Select.Option
                      value={JSON.stringify(dropdown)}
                      style={{ textTransform: "capitalize" }}
                    >
                      {dropdown.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <Layout.Spacer height={20} />

          <div style={styles.flexColumn}>
            <label style={styles.labelStyle}>Expiry Date* </label>
            <DatePicker
              style={styles.datepicker}
              onChange={(date, dateString) =>
                setState({ ...state, expiryDate: dateString })
              }
            />
          </div>

          <Layout.Spacer height={10} />
          <div>
            <label style={styles.labelStyle}>Message</label>
            <TextField.CustomTextArea
              rows={4}
              value={state.message}
              onChange={(e) =>
                setState({
                  ...state,
                  message: e.target.value,
                })
              }
              placeholder={"Message of the announcement"}
              style={styles.textareaStyle}
            />
          </div>
          <Layout.Spacer height={20} />
          {error && (
            <Text.Heading
              fontSize={"16px"}
              fontWeight={"500"}
              color={Colors.Red}
              text={error}
            />
          )}
          <div style={styles.justifyRightAlignCenter}>
            <Button.Primary
              style={styles.submitButton}
              disabled={buttonDisable}
              onClick={handleAddAnnouncement}
            >
              Continue
            </Button.Primary>
          </div>
        </div>
      )}
    </>
  );
}

export default AddAnnouncement;
