import {
  CHANGE_APPLICATION_STATUS,
  CHANGE_APPLICATION_STATUS_FAILURE,
  CHANGE_APPLICATION_STATUS_SUCCESS,
  GET_APPLICATIONS,
  GET_APPLICATIONS_FAILURE,
  GET_APPLICATIONS_SUCCESS,
} from "../constants";

const initialState = {
  totalApplicationsCount: 0,
  approvedApplicationsCount: 0,
  pendingApplicationsCount: 0,
  declinedApplicationsCount: 0,
  applications: [],
  application: {},
  isLoading: true,
  error: null,
};

export default function ApplicationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_APPLICATIONS:
    case CHANGE_APPLICATION_STATUS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case CHANGE_APPLICATION_STATUS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_APPLICATIONS_SUCCESS:
      state = {
        ...state,
        applications: action.payload.data,
        totalApplicationsCount: action.payload.totalApplicationsCount,
        approvedApplicationsCount: action.payload.approvedApplicationsCount,
        pendingApplicationsCount: action.payload.pendingApplicationsCount,
        declinedApplicationsCount: action.payload.declinedApplicationsCount,
        isLoading: false,
      };
      break;
    case GET_APPLICATIONS_FAILURE:
    case CHANGE_APPLICATION_STATUS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
