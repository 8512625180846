import { Colors } from "../../config";

const styles = {
  loginFormContainer: {
    width: "100%",
    margin: "0 auto",
  },
  inputFieldBorder: { borderColor: Colors.Primary },
  passwordContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
  },
  passwordIcon: {
    position: "absolute",
    right: "10",
    top: "10",
    fontSize: "20px",
    cursor: "pointer",
    color: Colors.Primary,
  },
  forgotPassword: {
    textAlign: "right",
  },
  forgotPasswordText: {
    textDecoration: "none",
    color: Colors.BlackText,
    fontSize: "16px",
  },
  buttonStyle: {
    width: "100%",
    backgroundColor: Colors.Primary,
    borderRadius: "15px",
    fontFamily: "Montserrat",
  },
  signupContainer: {
    textAlign: "center",
  },
  signupText: {
    color: Colors.LabelText,
    fontSize: "16px",
    fontWeight: "500",
  },
  signupLink: {
    textDecoration: "none",
    color: Colors.Primary,
    fontSize: "16px",
    marginLeft: "5px",
    fontWeight: "700",
  },
};

export default styles;
