import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { ProfileAction } from "../actions";

export default class ProfileMiddleware {
  static *getUserProfile({ id, cb }) {
    try {
      const res = yield ApiCaller.Get("/user/get-profile-data");
      if (res.status === 200) {
        yield put(ProfileAction.getUserProfileSuccess(res.data.data));
      } else {
        yield put(ProfileAction.getUserProfileFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(ProfileAction.getUserProfileFailure());
    }
  }

  static *editUserProfile({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/user/edit-profile-data`, payload);
      if (res.status === 200) {
        yield put(ProfileAction.editUserProfileSuccess(res.data.data));
      } else {
        yield put(ProfileAction.editUserProfileFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(ProfileAction.editUserProfileFailure());
    }
  }

  static *changePassword({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/user/change-password`, payload);
      if (res.data.status === 400 && res.data.message === "Wrong password.") {
        yield put(ProfileAction.changePasswordFailure());
      } else {
        yield put(ProfileAction.changePasswordSuccess(res.data.data));
        Utils.showSnackBar({
          message: `${res.data.message}. Login again with new credentials`,
        });
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(ProfileAction.changePasswordFailure());
    }
  }
}
