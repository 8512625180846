import { Colors } from "../../config";

const styles = {
  flexRowSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  universityTableText: {
    fontSize: "12px",
    fontWeight: "500",
    color: Colors.BlackText,
  },
};

export default styles;
