import { combineReducers } from "redux";

import AuthReducer from "./AuthReducer";
import AnnouncementReducer from "./AnnouncementReducer";
import ApplicationReducer from "./ApplicationReducer";
import BillingReducer from "./BillingReducer";
import ContactReducer from "./ContactReducer";
import ConverterReducer from "./ConverterReducer";
import IndividualStudentReducer from "./IndividualStudentReducer";
import ProfileReducer from "./ProfileReducer";
import PopularUniversityReducer from "./PopularUniversityReducer";
import RequestReducer from "./RequestReducer";
import SchoolReducer from "./SchoolReducers";
import StudentReducer from "./StudentReducer";
import TrackerReducer from "./TrackerReducer";
import UniversityReducer from "./UniversityReducer";

const RootReducer = combineReducers({
  auth: AuthReducer,
  announcement: AnnouncementReducer,
  application: ApplicationReducer,
  billings: BillingReducer,
  contact: ContactReducer,
  converter: ConverterReducer,
  individualStudents: IndividualStudentReducer,
  profile: ProfileReducer,
  popularUniversity: PopularUniversityReducer,
  request: RequestReducer,
  school: SchoolReducer,
  student: StudentReducer,
  tracker: TrackerReducer,
  university: UniversityReducer,
});

export default RootReducer;
