import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { UniversityAction } from "../actions";

export default class UniversityMiddleware {
  static *getUniversities({ payload, cb }) {
    try {
      const { region, search, limit, offset, status, filter } = payload;

      const res = yield ApiCaller.Get(
        `/university/search-university?searchInput=${search}&filterRegion=${region}&limit=${limit}&offset=${offset}&status=${status}&filter=${filter}`
      );

      if (res.status === 200) {
        yield put(UniversityAction.getUniversitiesSuccess(res.data.data));
      } else {
        yield put(UniversityAction.getUniversitiesFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(UniversityAction.getUniversitiesFailure());
    }
  }
  static *getAllUniversities({ cb }) {
    try {
      const res = yield ApiCaller.Get(`/university/getAllUniversities`);
      if (res.status === 200) {
        yield put(UniversityAction.getAllUniversitiesSuccess(res.data.data));
      } else {
        yield put(UniversityAction.getAllUniversitiesFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(UniversityAction.getAllUniversitiesFailure());
    }
  }
  static *getTotalUniversities({ cb }) {
    try {
      const res = yield ApiCaller.Get("/university/search-university");
      if (res.status === 200) {
        yield put(
          UniversityAction.getTotalUniversitiesSuccess(res.data.data.length)
        );
      } else {
        yield put(UniversityAction.getTotalUniversitiesFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(UniversityAction.getTotalUniversitiesFailure());
    }
  }

  static *getUniversityDetails({ id, cb }) {
    try {
      const res = yield ApiCaller.Get(
        `/university/get-universityDetails/${id}`
      );
      if (res.status === 200) {
        yield put(UniversityAction.getUniversityDetailsSuccess(res.data.data));
      } else {
        yield put(UniversityAction.getUniversityDetailsFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(UniversityAction.getUniversityDetailsFailure());
    }
  }

  static *addUniversitySheet({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        "/university/add-university-sheet",
        payload
      );
      if (res.status === 201) {
        yield put(UniversityAction.addUniversitySheetSuccess(res.data.data));
      } else {
        yield put(
          UniversityAction.addUniversitySheetFailure(res?.data?.message)
        );
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(UniversityAction.addUniversitySheetFailure("Server error"));
    }
  }

  static *editUniversitySheet({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        "/university/edit-university-sheet",
        payload
      );
      if (res.status !== 201) {
        yield put(
          UniversityAction.editUniversitySheetFailure(res?.data?.message)
        );
      } else {
        yield put(UniversityAction.editUniversitySheetSuccess());
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(UniversityAction.editUniversitySheetFailure("Server error"));
    }
  }

  static *deleteUniversity({ id, cb }) {
    try {
      const res = yield ApiCaller.Delete(`/university/delete-university/${id}`);
      if (res.status === 201) {
        yield put(UniversityAction.deleteUniversitySuccess(res.data.data));
      } else {
        yield put(UniversityAction.deleteUniversityFailure(res?.data?.message));
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(UniversityAction.deleteUniversityFailure("Server error"));
    }
  }

  static *editUniversity({ id, payload, cb }) {
    try {
      const res = yield ApiCaller.Patch(
        `/university/edit-university/${id}`,
        payload
      );
      if (res.status === 200) {
        yield put(UniversityAction.editUniversitySuccess(res.data.data));
      } else {
        yield put(UniversityAction.editUniversityFailure(res?.data?.message));
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(UniversityAction.deleteUniversityFailure("Server error"));
    }
  }
}
