import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, message } from "antd";

import { FaSearch } from "react-icons/fa";
import { FiUpload } from "react-icons/fi";
import { AiFillRightCircle } from "react-icons/ai";
import { MdLocationPin } from "react-icons/md";

import { UniversityAction } from "../../store/actions";

import {
  Button,
  Layout,
  Modal,
  Table,
  Text,
  TextField,
} from "../../components";
import { Colors, Images } from "../../config";

import styled from "styled-components";
import styles from "./styles";

function Universities() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const csvSelect = useRef();

  const currency = useSelector((state) => state.converter.usd);
  const role = useSelector((state) => state.auth.user.user.role);
  const universityState = useSelector((state) => state.university);

  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedValue, setSelectedValue] = useState("qsWorldRanking");
  const [search, setSearch] = useState("");
  const [showAddUniversityModal, setShowAddUniversityModal] = useState(false);
  const [isFileSelected, setFileSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [filterRegions, setFilterRegions] = useState([
    {
      name: "All",
      selected: true,
    },
    {
      name: "Asia",
      value: "asia",
      selected: false,
    },
    {
      name: "North America",
      value: "north america",
      selected: false,
    },
    {
      name: "Australia",
      value: "australia",
      selected: false,
    },
    {
      name: "Europe",
      value: "europe",
      selected: false,
    },
    {
      name: "Africa",
      value: "africa",
      selected: false,
    },

    {
      name: "South America",
      value: "south america",
      selected: false,
    },
  ]);

  useEffect(() => {
    const allRegions = filterRegions.filter((item) => item.selected === true);
    const payload = {
      search,
      region: allRegions[0].name === "All" ? "" : allRegions[0].value,
      limit,
      offset: 0,
      filter: selectedValue,
      status: "all",
    };
    dispatch(UniversityAction.getUniversities(payload));

    setPage(1);
  }, [filterRegions, selectedValue, search]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const fileSelected = (event) => {
    const formData = new FormData();
    Array.from(event.target.files).forEach((file) => {
      formData.append("file", file);
    });
    setFileSelected(true);
    onChange(formData);
    setTimeout(() => {
      setFileSelected(false);
    }, 1000);
  };

  const onChange = (formData) => {
    dispatch(
      UniversityAction.addUniversitySheet(formData, (res) => {
        setShowAddUniversityModal(false);
        setTimeout(() => {
          setButtonDisabled(false);
        }, 5000);
        if (res.status === 500) {
          message.error(res.data.message);
        } else {
          message.success("University is being added");
        }
      })
    );
  };

  const handleFilterStatus = (status) => {
    const newStatusFilters = filterRegions.map((filter) => {
      if (filter.name === status.name) {
        return {
          ...filter,
          selected: !filter.selected,
        };
      } else {
        return {
          ...filter,
          selected: false,
        };
      }
    });
    setFilterRegions(newStatusFilters);
  };

  const handlePaginationChange = (pageNumber) => {
    const allRegions = filterRegions.filter((item) => item.selected === true);
    let offset = (pageNumber - 1) * limit;

    const payload = {
      search: search,
      region: allRegions[0].name === "All" ? "" : allRegions[0].value,
      limit: limit,
      offset: offset,
      filter: selectedValue,
      status: "all",
    };
    dispatch(UniversityAction.getUniversities(payload));

    setOffset(offset);
    setPage(pageNumber);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 450,
      onCell: (record) => ({
        onClick: () => navigate(`/universities/${record._id}/general`),
      }),
      render: (text, record) => (
        <ColumnDiv>
          <img src={record?.profilePic} alt="img" />
          <span>
            <Text.Heading
              fontSize="12px"
              fontWeight="600"
              color="#081C15"
              textTransform="capitalize"
              textAlign="left"
              text={record.name}
            />
            <span>
              <MdLocationPin color="#474747" style={{ marginRight: "5px" }} />
              <Text.Heading
                fontSize="10px"
                fontWeight="400"
                color="#474747"
                textTransform="capitalize"
                textAlign="left"
                text={record.location}
              />
            </span>
          </span>
        </ColumnDiv>
      ),
    },
    {
      title: "QS World Ranking",
      dataIndex: "QSWorldRanking",
      align: "center",
      width: 200,
      onCell: (record) => ({
        onClick: () => navigate(`/universities/${record._id}/general`),
      }),
      render: (text, record) => (
        <div style={styles.universityTableText}>
          {record?.summary?.qsWorldRanking}
        </div>
      ),
    },
    {
      title: !currency ? "Average Fee" : "Average Fee (USD)",
      dataIndex: "averageFee",
      align: "center",
      width: 350,
      onCell: (record) => ({
        onClick: () => navigate(`/universities/${record._id}/general`),
      }),
      render: (text, record) => {
        if (!currency) {
          return (
            <div style={styles.universityTableText}>
              {record?.summary?.fee_structure}
            </div>
          );
        } else {
          const converted = convertStringToUSD(record?.summary?.fee_structure);
          return <div style={styles.universityTableText}>{converted}</div>;
        }
      },
    },
  ];

  const superAdminTableData = [
    ...columns,
    {
      title: " ",
      dataIndex: "actions",
      align: "center",
      width: 80,
      render: (text, record) => (
        <AiFillRightCircle
          fontSize={"30px"}
          cursor={"pointer"}
          color={Colors.Primary}
          onClick={() => navigate(`/universities/${record._id}/general`)}
        />
      ),
    },
  ];

  const adminTableData = [
    ...columns,
    {
      title: "Action",
      dataIndex: "actions",
      key: "actions",
      width: 200,
      render: (text, record) => (
        <div style={styles.justifyBetweenAlignCenter}>
          <div
            style={{
              ...styles.statusTitle,
              color: record?.status === "accepted" ? "#00d15b" : "black",
            }}
          >
            {record?.status === "accepted" ? "Verified" : "Pending"}
          </div>
          <div style={styles.flexRow}>
            <img
              src={Images.Edit}
              alt="Edit"
              style={styles.editIcon}
              onClick={() => navigate(`/edit-university/${record._id}`)}
            />
            <AiFillRightCircle
              fontSize={"30px"}
              cursor={"pointer"}
              color={Colors.Primary}
              onClick={() => navigate(`/universities/${record._id}/general`)}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div style={{ ...styles.flexRow, width: "100%" }}>
        <div
          style={{
            width: "100%",
            marginRight: "10px",
          }}
        >
          <TextField.CustomInput
            placeholder="Search Universities"
            suffix={<FaSearch color={Colors.Primary} />}
            style={styles.customTextfieldStyle}
            onChange={handleChange}
          />
        </div>
        <div
          style={{
            marginLeft: "10px",
            position: "relative",
          }}
        >
          <div style={styles.filterButton} onClick={toggleDropdown}>
            <img src={Images.Sort} style={{ width: "100%" }} />
          </div>
          {isOpen && (
            <div style={styles.filterDropdown}>
              <div
                style={{
                  ...styles.filterDropdownValue,
                  fontWeight:
                    selectedValue === "qsWorldRanking" ? "700" : "400",
                }}
                onClick={() => {
                  setSelectedValue("qsWorldRanking");
                  toggleDropdown();
                }}
              >
                QS World Ranking
              </div>

              <div
                style={{
                  cursor: "pointer",
                  fontWeight: selectedValue === "name" ? "700" : "400",
                }}
                onClick={() => {
                  setSelectedValue("name");
                  toggleDropdown();
                }}
              >
                Alphabetical
              </div>
            </div>
          )}
        </div>
        {role === "admin" && (
          <div
            style={{
              width: "20%",
              marginLeft: "20px",
            }}
          >
            <Button.Primary
              style={styles.addUniversityButton}
              onClick={() => setShowAddUniversityModal(true)}
              disabled={isButtonDisabled}
            >
              Add New University
            </Button.Primary>
          </div>
        )}
      </div>
      <Layout.Spacer height={20} />
      <div style={styles.flexRow}>
        <Text.Heading style={styles.filterRegionText} text="Filter by Region" />
        <div style={styles.flexRow}>
          {filterRegions.map((region) => (
            <div
              style={{
                ...styles.filterRegion,
                backgroundColor: region?.selected
                  ? Colors.Primary
                  : Colors.White,
                color: region?.selected ? Colors.White : Colors.BlackText2,
                border: region?.selected ? "" : "1px solid #387f768c",
              }}
              onClick={() => handleFilterStatus(region)}
              key={region?.name}
            >
              {region?.name}
            </div>
          ))}
        </div>
      </div>
      <Layout.Spacer height={15} />

      <div style={styles.justifyBetweenAlignCenter}>
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="Universities"
        />

        {!filterRegions[0].selected && (
          <div
            style={{ color: "black", cursor: "pointer" }}
            onClick={() =>
              handleFilterStatus({
                name: "All",
                selected: true,
              })
            }
          >
            See All
          </div>
        )}
      </div>

      <Layout.Spacer height={20} />
      <Table.AntDesign
        rowKey={(record) => record._id}
        columns={role === "admin" ? adminTableData : superAdminTableData}
        data={universityState.universities}
        loading={universityState.isLoading}
        pagination={false}
      />
      <Layout.Spacer height={20} />
      <div style={styles.universityActions}>
        <Pagination
          responsive="true"
          showSizeChanger={false}
          hideOnSinglePage={true}
          current={page}
          pageSize={10}
          total={universityState.count}
          onChange={handlePaginationChange}
        />
      </div>

      {showAddUniversityModal && (
        <Modal.Basic
          isModalVisible={showAddUniversityModal}
          centered={true}
          handleCancel={() => {
            setShowAddUniversityModal(false);
            setButtonDisabled(false);
          }}
          closeIcon={true}
          className="ant-modal"
        >
          <Layout.Spacer height={20} />
          <div style={styles.flexColumnCenter}>
            <div style={styles.uploadUniTitle}>Upload University File</div>
            <Layout.Spacer height={20} />
            <input
              style={{ display: "none" }}
              type="file"
              name="csv"
              id="getFile"
              ref={csvSelect}
              onChange={fileSelected}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <Button.Primary
              style={{ ...styles.addUniversityButton, width: "80%" }}
              onClick={() => {
                setButtonDisabled(true);
                document.getElementById("getFile").click();
              }}
            >
              <span style={{ marginRight: "10px" }}>
                <FiUpload style={{ height: "20px", width: "20px" }} />
              </span>
              <span>Upload Excel Sheet</span>
            </Button.Primary>
          </div>
          <Layout.Spacer height={20} />
        </Modal.Basic>
      )}
    </>
  );
}

export default Universities;

const ColumnDiv = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: contain;
    margin-right: 10px;
    background: white;
  }
  span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    span:last-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;
