//login flow constants
export const SIGNIN = "SIGNIN";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

//universities
export const GET_UNIVERSITIES = "GET_UNIVERSITIES";
export const GET_UNIVERSITIES_SUCCESS = "GET_UNIVERSITIES_SUCCESS";
export const GET_UNIVERSITIES_FAILURE = "GET_UNIVERSITIES_FAILURE";

export const GET_ALL_UNIVERSITIES = "GET_ALL_UNIVERSITIES";
export const GET_ALL_UNIVERSITIES_SUCCESS = "GET_ALL_UNIVERSITIES_SUCCESS";
export const GET_ALL_UNIVERSITIES_FAILURE = "GET_ALL_UNIVERSITIES_FAILURE";

export const GET_POPULAR_UNIVERSITIES = "GET_POPULAR_UNIVERSITIES";
export const GET_POPULAR_UNIVERSITIES_SUCCESS =
  "GET_POPULAR_UNIVERSITIES_SUCCESS";
export const GET_POPULAR_UNIVERSITIES_FAILURE =
  "GET_POPULAR_UNIVERSITIES_FAILURE";

export const GET_TOTAL_UNIVERSITIES = "GET_TOTAL_UNIVERSITIES";
export const GET_TOTAL_UNIVERSITIES_SUCCESS = "GET_TOTAL_UNIVERSITIES_SUCCESS";
export const GET_TOTAL_UNIVERSITIES_FAILURE = "GET_TOTAL_UNIVERSITIES_FAILURE";

export const GET_UNIVERSITY_DETAILS = "GET_UNIVERSITY_DETAILS";
export const GET_UNIVERSITY_DETAILS_SUCCESS = "GET_UNIVERSITY_DETAILS_SUCCESS";
export const GET_UNIVERSITY_DETAILS_FAILURE = "GET_UNIVERSITIES_FAILURE";

export const ADD_UNIVERSITY_SHEET = "ADD_UNIVERSITY_SHEET";
export const ADD_UNIVERSITY_SHEET_SUCCESS = "ADD_UNIVERSITY_SHEET_SUCCESS";
export const ADD_UNIVERSITY_SHEET_FAILURE = "ADD_UNIVERSITY_SHEET_FAILURE";

export const DELETE_UNIVERSITY = "DELETE_UNIVERSITY";
export const DELETE_UNIVERSITY_SUCCESS = "DELETE_UNIVERSITY_SUCCESS";
export const DELETE_UNIVERSITY_FAILURE = "DELETE_UNIVERSITY_FAILURE";

export const EDIT_UNIVERSITY = "EDIT_UNIVERSITY";
export const EDIT_UNIVERSITY_SUCCESS = "EDIT_UNIVERSITY_SUCCESS";
export const EDIT_UNIVERSITY_FAILURE = "EDIT_UNIVERSITY_FAILURE";

export const EDIT_UNIVERSITY_SHEET = "EDIT_UNIVERSITY_SHEET";
export const EDIT_UNIVERSITY_SHEET_SUCCESS = "EDIT_UNIVERSITY_SHEET_SUCCESS";
export const EDIT_UNIVERSITY_SHEET_FAILURE = "EDIT_UNIVERSITY_SHEET_FAILURE";

//schools
export const GET_SCHOOLS = "GET_SCHOOLS";
export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";
export const GET_SCHOOLS_FAILURE = "GET_SCHOOLS_FAILURE";

export const ADD_SCHOOL = "ADD_SCHOOL";
export const ADD_SCHOOL_SUCCESS = "ADD_SCHOOL_SUCCESS";
export const ADD_SCHOOL_FAILURE = "ADD_SCHOOL_FAILURE";

export const GET_SCHOOL_DETAILS = "GET_SCHOOL_DETAILS";
export const GET_SCHOOL_DETAILS_SUCCESS = "GET_SCHOOL_DETAILS_SUCCESS";
export const GET_SCHOOL_DETAILS_FAILURE = "GET_SCHOOL_DETAILS_FAILURE";

export const CHANGE_SCHOOL_STATUS = "CHANGE_SCHOOL_STATUS";
export const CHANGE_SCHOOL_STATUS_SUCCESS = "CHANGE_SCHOOL_STATUS_SUCCESS";
export const CHANGE_SCHOOL_STATUS_FAILURE = "CHANGE_SCHOOL_STATUS_FAILURE";

//students
export const GET_STUDENTS = "GET_STUDENTS";
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS";
export const GET_STUDENTS_FAILURE = "GET_STUDENTS_FAILURE";

export const GET_ONBOARDED_STUDENTS = "GET_ONBOARDED_STUDENTS";
export const GET_ONBOARDED_STUDENTS_SUCCESS = "GET_ONBOARDED_STUDENTS_SUCCESS";
export const GET_ONBOARDED_STUDENTS_FAILURE = "GET_ONBOARDED_STUDENTS_FAILURE";

export const GET_STUDENT_DETAILS = "GET_STUDENT_DETAILS";
export const GET_STUDENT_DETAILS_SUCCESS = "GET_STUDENT_DETAILS_SUCCESS";
export const GET_STUDENT_DETAILS_FAILURE = "GET_STUDENT_DETAILS_FAILURE";

//requests
export const GET_REQUESTS = "GET_REQUESTS";
export const GET_REQUESTS_SUCCESS = "GET_REQUESTS_SUCCESS";
export const GET_REQUESTS_FAILURE = "GET_REQUESTS_FAILURE";

export const CHANGE_REQUEST_STATUS = "CHANGE_REQUEST_STATUS";
export const CHANGE_REQUEST_STATUS_SUCCESS = "CHANGE_REQUEST_STATUS_SUCCESS";
export const CHANGE_REQUEST_STATUS_FAILURE = "CHANGE_REQUEST_STATUS_FAILURE";

export const LOADER_TRUE = "LOADER_TRUE";
export const LOADER_FALSE = "LOADER_FALSE";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

//profile
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const EDIT_PROFILE = "EDIT_PROFILE";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAILURE = "EDIT_PROFILE_FAILURE";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

//miscellaneous
export const ADD_CONTACT = "ADD_CONTACT";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAILURE = "ADD_CONTACT_FAILURE";

//billing
export const EDIT_BILLING = "EDIT_BILLING";
export const EDIT_BILLING_SUCCESS = "EDIT_BILLING_SUCCESS";
export const EDIT_BILLING_FAILURE = "EDIT_BILLING_FAILURE";

export const GET_ALL_BILLING = "GET_ALL_BILLING";
export const GET_ALL_BILLING_SUCCESS = "GET_ALL_BILLING_SUCCESS";
export const GET_ALL_BILLING_FAILURE = "GET_ALL_BILLING_FAILURE";

export const GET_BILLING = "GET_BILLING";
export const GET_BILLING_SUCCESS = "GET_BILLING_SUCCESS";
export const GET_BILLING_FAILURE = "GET_BILLING_FAILURE";

export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_FAILURE = "GET_INVOICES_FAILURE";

export const UPDATE_BILLING = "UPDATE_BILLING";
export const UPDATE_BILLING_SUCCESS = "UPDATE_BILLING_SUCCESS";
export const UPDATE_BILLING_FAILURE = "UPDATE_BILLING_FAILURE";

export const UPLOAD_INVOICE = "UPLOAD_INVOICE";
export const UPLOAD_INVOICE_SUCCESS = "UPLOAD_INVOICE_SUCCESS";
export const UPLOAD_INVOICE_FAILURE = "UPLOAD_INVOICE_FAILURE";

// tracker
export const ADD_TRACKER = "ADD_TRACKER";
export const ADD_TRACKER_SUCCESS = "ADD_TRACKER_SUCCESS";
export const ADD_TRACKER_FAILURE = "ADD_TRACKER_FAILURE";

export const GET_ALL_TRACKER = "GET_ALL_TRACKER";
export const GET_ALL_TRACKER_SUCCESS = "GET_ALL_TRACKER_SUCCESS";
export const GET_ALL_TRACKER_FAILURE = "GET_ALL_TRACKER_FAILURE";

export const GET_TRACKER = "GET_TRACKER";
export const GET_TRACKER_SUCCESS = "GET_TRACKER_SUCCESS";
export const GET_TRACKER_FAILURE = "GET_TRACKER_FAILURE";

export const UPDATE_TRACKER = "UPDATE_TRACKER";
export const UPDATE_TRACKER_SUCCESS = "UPDATE_TRACKER_SUCCESS";
export const UPDATE_TRACKER_FAILURE = "UPDATE_TRACKER_FAILURE";

export const DELETE_TRACKER = "DELETE_TRACKER";
export const DELETE_TRACKER_SUCCESS = "DELETE_TRACKER_SUCCESS";
export const DELETE_TRACKER_FAILURE = "DELETE_TRACKER_FAILURE";

// converter
export const GET_CONVERTER = "GET_CONVERTER";
export const GET_CONVERTER_SUCCESS = "GET_CONVERTER_SUCCESS";
export const GET_CONVERTER_FAILURE = "GET_CONVERTER_FAILURE";

export const UPDATE_CONVERTER = "UPDATE_CONVERTER";
export const UPDATE_CONVERTERG_SUCCESS = "UPDATE_CONVERTER_SUCCESS";
export const UPDATE_CONVERTER_FAILURE = "UPDATE_CONVERTER_FAILURE";

// individual student
export const GET_INDIVIDUAL_STUDENTS = "GET_INDIVIDUAL_STUDENTS";
export const GET_INDIVIDUAL_STUDENTS_SUCCESS =
  "GET_INDIVIDUAL_STUDENTS_SUCCESS";
export const GET_INDIVIDUAL_STUDENTS_FAILURE =
  "GET_INDIVIDUAL_STUDENTS_FAILURE";
export const UPDATE_INDIVIDUAL_STUDENT = "UPDATE_INDIVIDUAL_STUDENT";
export const UPDATE_INDIVIDUAL_STUDENT_SUCCESS =
  "UPDATE_INDIVIDUAL_STUDENT_SUCCESS";
export const UPDATE_INDIVIDUAL_STUDENT_FAILURE =
  "UPDATE_INDIVIDUAL_STUDENT_FAILURE";
export const UPDATE_INDIVIDUAL_STUDENT_PAYMENT =
  "UPDATE_INDIVIDUAL_STUDENT_PAYMENT";
export const UPDATE_INDIVIDUAL_STUDENT_PAYMENT_SUCCESS =
  "UPDATE_INDIVIDUAL_STUDENT_PAYMENT_SUCCESS";
export const UPDATE_INDIVIDUAL_STUDENT_PAYMENT_FAILURE =
  "UPDATE_INDIVIDUAL_STUDENT_PAYMENT_FAILURE";

//Announcements
export const ADD_ANNOUNCEMENT = "ADD_ANNOUNCEMENT";
export const ADD_ANNOUNCEMENT_SUCCESS = "ADD_ANNOUNCEMENT_SUCCESS";
export const ADD_ANNOUNCEMENT_FAILURE = "ADD_ANNOUNCEMENT_FAILURE";

export const GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS";
export const GET_ANNOUNCEMENTS_SUCCESS = "GET_ANNOUNCEMENTS_SUCCESS";
export const GET_ANNOUNCEMENTS_FAILURE = "GET_ANNOUNCEMENTS_FAILURE";

export const CHANGE_ANNOUNCEMENT_STATUS = "CHANGE_ANNOUNCEMENT_STATUS";
export const CHANGE_ANNOUNCEMENT_STATUS_SUCCESS =
  "CHANGE_ANNOUNCEMENT_STATUS_SUCCESS";
export const CHANGE_ANNOUNCEMENT_STATUS_FAILURE =
  "CHANGE_ANNOUNCEMENT_STATUS_FAILURE";

//applications
export const GET_APPLICATIONS = "GET_APPLICATIONS";
export const GET_APPLICATIONS_SUCCESS = "GET_APPLICATIONS_SUCCESS";
export const GET_APPLICATIONS_FAILURE = "GET_APPLICATIONS_FAILURE";

export const CHANGE_APPLICATION_STATUS = "CHANGE_APPLICATION_STATUS";
export const CHANGE_APPLICATION_STATUS_SUCCESS =
  "CHANGE_APPLICATION_STATUS_SUCCESS";
export const CHANGE_APPLICATION_STATUS_FAILURE =
  "CHANGE_APPLICATION_STATUS_FAILURE";
