import {
  EDIT_BILLING,
  EDIT_BILLING_SUCCESS,
  EDIT_BILLING_FAILURE,
  GET_BILLING,
  GET_BILLING_FAILURE,
  GET_BILLING_SUCCESS,
  GET_ALL_BILLING,
  GET_ALL_BILLING_FAILURE,
  GET_ALL_BILLING_SUCCESS,
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  UPDATE_BILLING,
  UPDATE_BILLING_FAILURE,
  UPDATE_BILLING_SUCCESS,
  UPLOAD_INVOICE,
  UPLOAD_INVOICE_SUCCESS,
  UPLOAD_INVOICE_FAILURE,
} from "../constants";

const initialState = {
  billings: [],
  schoolBilling: {},
  invoices: [],
  count: 0,
  isLoading: true,
  error: null,
};

export default function BillingReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_BILLING:
    case GET_BILLING:
    case GET_ALL_BILLING:
    case GET_INVOICES:
    case UPDATE_BILLING:
    case UPLOAD_INVOICE:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;

    case GET_BILLING_SUCCESS:
      state = {
        ...state,

        schoolBilling: action.payload,
        isLoading: false,
      };
      break;
    case GET_ALL_BILLING_SUCCESS:
      state = {
        ...state,

        billings: action.payload.billingInfo,
        count: action.payload.count,
        isLoading: false,
      };
      break;
    case GET_INVOICES_SUCCESS:
      state = {
        ...state,
        invoices: action.payload,
        isLoading: false,
      };
      break;
    case EDIT_BILLING_SUCCESS:
    case UPDATE_BILLING_SUCCESS:
    case UPLOAD_INVOICE_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case EDIT_BILLING_FAILURE:
    case GET_BILLING_FAILURE:
    case GET_ALL_BILLING_FAILURE:
    case GET_INVOICES_FAILURE:
    case UPDATE_BILLING_FAILURE:
    case UPLOAD_INVOICE_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
