import { useSelector } from "react-redux";

import { Colors } from "../../config";
import { Layout, Text, convertStringToUSD } from "../../components";
import { Images } from "../../config";

import styles from "./styles";

function Summary({ data }) {
  const currency = useSelector((state) => state.converter.usd);

  const handleLink = () => {
    window.open(data?.courses, "_blank");
  };

  const summaryLeftSide = [
    {
      id: 1,
      image: Images.Ranking,
      title: "Ranking",
      value: data?.qsWorldRanking
        ? `#${data?.qsWorldRanking} QS World Ranking`
        : "NA",
    },
    {
      id: 2,
      image: Images.Book,
      title: "Courses Offered",
      value: data?.courses
        ? data?.courses?.length > 50
          ? data?.courses?.slice(0, 50) + "..."
          : data?.courses
        : "NA",
    },
    {
      id: 3,
      image: Images.Duration,
      title: "Courses Duration",
      value: data?.courses_duration ? data?.courses_duration : "NA",
    },
    {
      id: 4,
      image: Images.Clipboard,
      title: "Status",
      value: data?.status ? data?.status : "NA",
    },
  ];
  const summaryRightSide = [
    {
      id: 1,
      image: Images.Campus,
      title: "Primary Campus",
      value: data?.primary_campus ? data?.primary_campus : "NA",
    },
    {
      id: 2,
      image: Images.MoneyBag,
      title: "Fee Structure",
      value: !currency
        ? data?.fee_structure
          ? data?.fee_structure
          : "NA"
        : convertStringToUSD(data?.fee_structure ? data?.fee_structure : "NA"),
    },
    {
      id: 3,
      image: Images.Approval,
      title: "Scholarship Availablity",
      value: data?.scholarship_availability
        ? data?.scholarship_availability
        : "NA",
    },
    {
      id: 4,
      image: Images.Research,
      title: "Research Output",
      value:
        data?.research_output === "veryHigh"
          ? "Very High"
          : data?.research_output === "veryLow"
          ? "Very Low"
          : data?.research_output,
    },
  ];
  return (
    <>
      <Layout.Spacer height={15} />
      <p style={styles.uniDetailsHeader}>Summary</p>
      <Layout.Spacer height={10} />
      <div style={styles.summaryContainer}>
        <div
          style={{
            ...styles.flexColumn,
            borderRight: `1px solid ${Colors.Primary}`,
          }}
        >
          {summaryLeftSide?.map((item) => (
            <div
              style={{
                ...styles.summaryPoint,
                borderBottom:
                  item.id === 4 ? "" : `1px solid ${Colors.Primary}`,
              }}
              key={item.id}
            >
              <div style={styles.flexCenter}>
                <img
                  src={item.image}
                  style={{
                    ...styles.uniDetailsIcon,
                    height: "28px",
                    width: "28px",
                    marginBottom: "0px",
                  }}
                  alt=""
                />
              </div>
              <div
                style={{
                  ...styles.flexColumnJustifyCenter,
                  overflow: "hidden",
                }}
              >
                <Text.Heading
                  fontSize={"14px"}
                  fontWeight={"700"}
                  color={Colors.BlackText}
                  textAlign="left"
                  text={item.title}
                />
                <Text.Heading
                  fontSize={"16px"}
                  fontWeight={"500"}
                  color={Colors.Primary}
                  textAlign="left"
                  onClick={() => (item.id === 2 ? handleLink() : null)}
                  text={item.value}
                />
              </div>
            </div>
          ))}
        </div>
        <div style={styles.flexColumn}>
          {summaryRightSide?.map((item) => (
            <div
              style={{
                ...styles.summaryPoint,
                borderBottom:
                  item.id === 4 ? "" : `1px solid ${Colors.Primary}`,
              }}
              key={item.id}
            >
              <div style={styles.flexCenter}>
                <img
                  src={item.image}
                  style={{
                    ...styles.uniDetailsIcon,
                    height: "28px",
                    width: "28px",
                    marginBottom: "0px",
                  }}
                  alt=""
                />
              </div>
              <div style={styles.flexColumnJustifyCenter}>
                <Text.Heading
                  fontSize={"14px"}
                  fontWeight={"700"}
                  color={Colors.BlackText}
                  textAlign="left"
                  text={item.title}
                />
                <Text.Heading
                  fontSize={"16px"}
                  fontWeight={"500"}
                  color={Colors.Primary}
                  textAlign="left"
                  text={item.value}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Summary;
