var BASE_URL = process.env.REACT_APP_BACKEND_URL;

const configVariables = {
  BASE_URL,
};

const constantVariables = {
  TempUser: {
    name: "David Smith",
    email: "david@yopmail.com",
    pass: "asd123",
    userId: 12,
  },
};

const constants = {
  ...configVariables,
  ...constantVariables,
};

export default constants;
