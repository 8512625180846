import { MockData } from "../../config";

const convertStringToUSD = (currencyString) => {
  if (!currencyString) {
    return "NA";
  }
  const replacedCurrencyString = currencyString.replace(/–/g, "-");
  const regex = /([A-Z]+)\s*([\d,]+)(?:\s*-\s*([\d,]+))?/;
  const matches = replacedCurrencyString.match(regex);

  if (!matches) {
    console.log("Invalid salary string format.");
    return "NA";
  }

  const currencyCode = matches[1];
  const value1 = parseInt(matches[2].replace(/,/g, ""));
  const value2 = matches[3] ? parseInt(matches[3].replace(/,/g, "")) : null;

  const currency = MockData.Currencies.find((c) => c.currency === currencyCode);
  if (!currency) {
    console.log("Currency not found in the conversion rates.");
    return "NA";
  }

  const value1USD = value1 * currency.amount;
  const value2USD = value2 ? value2 * currency.amount : null;

  const value1fixed = value1USD?.toLocaleString().split(".")[0];
  const value2fixed = value2USD?.toLocaleString().split(".")[0];

  let currencyStringUSD = "";
  if (value2USD !== null) {
    currencyStringUSD = `USD ${value1fixed}-${value2fixed}`;
  } else {
    currencyStringUSD = `${currencyString.replace(
      matches[0],
      `USD ${value1fixed}`
    )}`;
  }

  return currencyStringUSD;
};

export default convertStringToUSD;
