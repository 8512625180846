import { Colors } from "../../config";

const styles = {
  flexColumnCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  justifyBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  rowStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  flexRowSpace: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexRowEnd: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    cursor: "pointer",
  },

  mx10: {
    marginInline: "5px",
  },

  labelStyle: {
    textAlign: "left",
    color: Colors.LabelText,
    fontWeight: "400",
    fontSize: "16px",
  },
  textfieldStyle: {
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    padding: "5px",
    width: "100%",
    fontSize: "14px",
    fontWeight: "400",
    color: Colors.BlackText2,
  },
  textBox: {
    borderRadius: "6px",
    padding: "10px",
    width: "100%",
    fontSize: "14px",
    fontWeight: "400",
    color: Colors.BlackText2,
  },
  inputContainer: { width: "80%", margin: "auto" },
  buttonStyle: {
    margin: "30px",
    width: "180px",
  },
  modalButtonStyle: {
    width: "100px",
    height: "30px",
    borderRadius: "30px",
    fontSize: "18px",
    fontWeight: "700",
  },
  modalText: {
    fontSize: "24px",
    fontWeight: "700",
    color: Colors.Primary,
    marginBottom: "10px",
  },
  modalIcon: { width: "68px", height: "68px" },

  // general
  imageStyle: {
    objectFit: "cover",
    borderRadius: "100%",
    border: `1px solid ${Colors.Primary}`,
    height: "110px",
    width: "110px",
  },
  cameraIcon: {
    position: "absolute",
    bottom: "0",
    right: "0",
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "50px",
    padding: "2.5px 5px",
    cursor: "pointer",
  },
  clearFieldContainer: {
    display: "flex",
    justifyContent: "end",
  },
  inputFieldsContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "80px",
    gridRowGap: "20px",
  },
  inputLabel: { fontSize: "14px", fontWeight: "500" },
  inputStyle: {
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "5px",
    padding: "5px",
    color: "black",
  },
  phoneInput: {
    width: "100%",
    fontSize: "14px",
    borderRadius: "5px",
    outline: "none",
    height: "30px",
  },
  satStyle: { fontSize: "13px", fontWeight: "500" },

  // student lifew
  costTitleContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "80px",
    gridRowGap: "15px",
    marginTop: "20px",
  },

  currencyInput: {
    width: "34%",
  },
  currencyValueInput: {
    width: "66%",
    padding: "5.2px",
  },
  restaurantContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridColumnGap: "20px",
    gridRowGap: "20px",
    marginTop: "20px",
  },

  // programs
  programNumber: { color: Colors.Primary, fontSize: "14px", fontWeight: "600" },
  removeButton: {
    backgroundColor: Colors.Danger,
    border: "none",
    marginTop: "20px",
    width: "180px",
    height: "30px",
    borderRadius: "6px",
    fontSize: "14px",
  },
};

export default styles;
