import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Dropdown } from "antd";

import { AiOutlineUser } from "react-icons/ai";

import { ProfileAction } from "../../store/actions";

import { ProfileDropDown } from "./ProfileDropDown";

import { Images } from "../../config";

import styles from "./styles";

const NavHeader = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const loginUser = useSelector((state) => state.auth.user.user);
  const profile = useSelector((state) => state.profile);
  const path = location.pathname;

  useEffect(() => {
    dispatch(ProfileAction.getUserProfile());
  }, []);

  return (
    <div style={styles.navbarContainer}>
      {!path.includes("/home") ? (
        <div
          onClick={() => {
            window.history.back();
          }}
          style={{ display: "flex" }}
        >
          <img src={Images.LeftArrowCircle} style={styles.backButton} />
        </div>
      ) : (
        <div></div>
      )}
      <div style={styles.navbarRightPart}>
        <Dropdown
          trigger="click"
          overlay={<ProfileDropDown />}
          placement="bottom"
        >
          {profile?.user?.profileImage ? (
            <img
              src={profile?.user?.profileImage}
              alt="user"
              style={styles.profileImage}
            />
          ) : (
            <AiOutlineUser style={styles.defaultImage} />
          )}
        </Dropdown>

        <div style={styles.profileContainer}>
          <div style={styles.profileName}>{profile?.user?.fullName}</div>
          <div style={styles.profileRole}>{loginUser?.role}</div>
        </div>
      </div>
    </div>
  );
};
export default NavHeader;
