import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select } from "antd";
import Lottie from "react-lottie-player";

import { ImBin2 } from "react-icons/im";

import { UniversityAction } from "../../store/actions";

import { Loader } from "../../components";
import { Button, Layout, Text, TextField, Modal } from "../../components";

import { Colors, MockData } from "../../config";

import styles from "./styles";

import Done from "../../assets/images/57767-done.json";

function Programs({ locationData }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.university.university);

  const id = location.pathname.split("/")[2];

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = useState([
    {
      program: "",
      programLink: "",
      degree: "",
      name: "",
      duration: "",
      localCost: "",
      localCostCurrency: "USD",
      internationalCost: "",
      internationalCostCurrency: "USD",
      tutionFee: "",
      tutionFeeCurrency: "USD",
    },
  ]);

  useEffect(() => {
    dispatch(UniversityAction.getUniversityDetails(id));
  }, []);

  useEffect(() => {
    const programsData = data.programs.map((item) => {
      return {
        program: item.program ? item.program : "NA",
        programLink: item.programLink ? item.programLink : "NA",
        degree: item.degree ? item.degree : "NA",
        name: item.name ? item.name : "NA",
        duration: item.duration ? item.duration : "NA",
        localCost: item?.localCost ? item?.localCost.split(" ")[1] : "NA",
        localCostCurrency: item?.localCost
          ? item?.localCost.split(" ")[0]
          : "NA",
        internationalCost: item?.internationalCost
          ? item?.internationalCost.split(" ")[1]
          : "NA",
        internationalCostCurrency: item?.internationalCost
          ? item?.internationalCost.split(" ")[0]
          : "NA",
        tutionFee: item?.tutionFee ? item?.tutionFee.split(" ")[1] : "NA",
        tutionFeeCurrency: item?.tutionFee
          ? item?.tutionFee.split(" ")[0]
          : "NA",
      };
    });
    setState(programsData);
  }, [data]);

  const handleChange = (value, key, index) => {
    let newvalue = [...state];
    newvalue[index][key] = value;
    setState(newvalue);
    setError("");
  };

  const handleClear = () => {
    setState([
      {
        program: "",
        programLink: "",
        degree: "",
        name: "",
        duration: "",
        localCost: "",
        localCostCurrency: "USD",
        internationalCost: "",
        internationalCostCurrency: "USD",
        tutionFee: "",
        tutionFeeCurrency: "USD",
      },
    ]);
  };

  const handleRemoveProgram = (index) => {
    var array = [...state];
    array.splice(index, 1);
    setState(array);
  };

  const handleAddMoreProgram = () => {
    const checkValidation = locationData?.toLowerCase().includes("malaysia")
      ? checkFieldsMalaysia()
      : checkFields();

    if (checkValidation) {
      setError("Please fill all required fields");
    } else {
      setError("");
      setState([
        ...state,
        {
          program: "",
          programLink: "",
          degree: "",
          name: "",
          duration: "",
          localCost: "",
          localCostCurrency: "USD",
          internationalCost: "",
          internationalCostCurrency: "USD",
          tutionFee: "",
          tutionFeeCurrency: "USD",
        },
      ]);
    }
  };

  const handleNext = () => {
    setSaveButtonDisabled(true);
    let programs = [];

    const checkValidation = locationData?.toLowerCase().includes("malaysia")
      ? checkFieldsMalaysia()
      : checkFields();

    if (checkValidation) {
      setSaveButtonDisabled(false);
      setError("Please fill all required fields");
    } else {
      if (locationData?.toLowerCase().includes("malaysia")) {
        setError("");
        programs = state.map((item) => {
          return {
            program: item.program ? item.program : "NA",
            programLink: item.programLink ? item.programLink : "NA",
            degree: item.degree ? item.degree : "NA",
            name: item.name ? item.name : "NA",
            duration: item.duration ? item.duration : "NA",
            localCost:
              item.localCostCurrency && item.localCost
                ? `${item.localCostCurrency} ${item.localCost}`
                : "NA NA",
            internationalCost:
              item.internationalCostCurrency && item.internationalCost
                ? `${item.internationalCostCurrency} ${item.internationalCost}`
                : "NA NA",
            tutionFee: "NA NA",
          };
        });
      } else {
        setError("");
        programs = state.map((item) => {
          return {
            program: item.program ? item.program : "NA",
            programLink: item.programLink ? item.programLink : "NA",
            degree: item.degree ? item.degree : "NA",
            name: item.name ? item.name : "NA",
            duration: item.duration ? item.duration : "NA",
            localCost: "NA NA",
            internationalCost: "NA NA",
            tutionFee:
              item.tutionFeeCurrency && item.tutionFee
                ? `${item.tutionFeeCurrency} ${item.tutionFee}`
                : "NA NA",
          };
        });
      }

      let dataToUpload = new FormData();
      dataToUpload.append("programs", JSON.stringify(programs));
      dataToUpload.append("tab", "programs");

      dispatch(
        UniversityAction.editUniversity(id, dataToUpload, (res) => {
          setSaveButtonDisabled(false);
          if (res.status === 200) {
            setShowModal(true);
          }
        })
      );
    }
  };

  const checkFields = () => {
    let flag = false;
    state.forEach((item) => {
      if (!item.program) {
        flag = true;
      } else if (!item.degree) {
        flag = true;
      } else if (!item.tutionFee) {
        flag = true;
      } else if (!item.duration) {
        flag = true;
      } else if (!item.name) {
        flag = true;
      } else if (!item.programLink) {
        flag = true;
      }
    });
    return flag;
  };
  const checkFieldsMalaysia = () => {
    let flag = false;
    state.forEach((item) => {
      if (!item.program) {
        flag = true;
      } else if (!item.degree) {
        flag = true;
      } else if (!item.localCost) {
        flag = true;
      } else if (!item.internationalCost) {
        flag = true;
      } else if (!item.duration) {
        flag = true;
      } else if (!item.name) {
        flag = true;
      } else if (!item.programLink) {
        flag = true;
      }
    });
    return flag;
  };

  return (
    <div style={styles.inputContainer}>
      <div style={styles.justifyBetween}>
        <Text.Heading
          fontSize={"16px"}
          fontWeight={"700"}
          color={Colors.Primary}
          text="Programs"
        />
        <div onClick={handleClear}>
          <Text.Heading
            fontSize={"10px"}
            fontWeight={"400"}
            color={Colors.Gray}
            text="Clear Fields"
          />
          <ImBin2 color={Colors.Primary} fontSize={14} />
        </div>
      </div>
      <Layout.Spacer height={10} />

      {isLoading ? (
        <Loader.Circular />
      ) : (
        <div>
          {state.map((item, index) => (
            <>
              <Layout.Spacer height={20} />
              <div style={styles.programNumber}>Program {index + 1}</div>
              <div style={styles.costTitleContainer}>
                <div>
                  <div style={styles.inputLabel}>Program *</div>
                  <TextField.CustomInput
                    placeholder="Business"
                    optional={true}
                    value={item.program}
                    onChange={(e) =>
                      handleChange(e.target.value, "program", index)
                    }
                    style={styles.textfieldStyle}
                  />
                </div>
                <div>
                  <div style={styles.inputLabel}>Program (Link) *</div>
                  <TextField.CustomInput
                    placeholder="https://link.com"
                    optional={true}
                    value={item.programLink}
                    onChange={(e) =>
                      handleChange(e.target.value, "programLink", index)
                    }
                    style={styles.textfieldStyle}
                  />
                </div>
                <div>
                  <div style={styles.inputLabel}>Type *</div>
                  <TextField.CustomInput
                    placeholder="Enter degree"
                    optional={true}
                    value={item.degree}
                    onChange={(e) =>
                      handleChange(e.target.value, "degree", index)
                    }
                    style={styles.textfieldStyle}
                  />
                </div>
                <div>
                  <div style={styles.inputLabel}>Name *</div>
                  <TextField.CustomInput
                    placeholder="Enter Name"
                    optional={true}
                    value={item.name}
                    onChange={(e) =>
                      handleChange(e.target.value, "name", index)
                    }
                    style={styles.textfieldStyle}
                  />
                </div>
                <div>
                  <div style={styles.inputLabel}>Duration *</div>
                  <TextField.CustomInput
                    placeholder="4"
                    optional={true}
                    value={item.duration}
                    onChange={(e) =>
                      handleChange(e.target.value, "duration", index)
                    }
                    style={styles.textfieldStyle}
                  />
                </div>
                {locationData?.toLowerCase().includes("malaysia") ? (
                  <div className="input-group">
                    <div style={styles.inputLabel}>
                      Tution Fee (International) *
                    </div>

                    <Input.Group compact>
                      <Select
                        defaultValue="USD"
                        value={item.internationalCostCurrency}
                        onChange={(e) =>
                          handleChange(e, "internationalCostCurrency", index)
                        }
                        style={styles.currencyInput}
                        showSearch
                      >
                        {MockData.Currency.map((item) => (
                          <Select.Option value={item.value}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                      <Input
                        style={styles.currencyValueInput}
                        placeholder="Amount"
                        value={item.internationalCost}
                        onChange={(e) =>
                          handleChange(
                            e.target.value,
                            "internationalCost",
                            index
                          )
                        }
                      />
                    </Input.Group>
                  </div>
                ) : (
                  <div className="input-group">
                    <div style={styles.inputLabel}>Tution Fee (Per Year) *</div>

                    <Input.Group compact>
                      <Select
                        defaultValue="USD"
                        value={item.tutionFeeCurrency}
                        onChange={(e) =>
                          handleChange(e, "tutionFeeCurrency", index)
                        }
                        style={styles.currencyInput}
                        showSearch
                      >
                        {MockData.Currency.map((item) => (
                          <Select.Option value={item.value}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                      <Input
                        style={styles.currencyValueInput}
                        placeholder="Amount"
                        value={item.tutionFee}
                        onChange={(e) =>
                          handleChange(e.target.value, "tutionFee", index)
                        }
                      />
                    </Input.Group>
                  </div>
                )}
                {locationData?.toLowerCase().includes("malaysia") ? (
                  <div className="input-group">
                    <div style={styles.inputLabel}>Tution Fee (Locals) *</div>
                    <Input.Group compact>
                      <Select
                        defaultValue="USD"
                        value={item.localCostCurrency}
                        onChange={(e) =>
                          handleChange(e, "localCostCurrency", index)
                        }
                        style={styles.currencyInput}
                        showSearch
                      >
                        {MockData.Currency.map((item) => (
                          <Select.Option value={item.value}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                      <Input
                        style={styles.currencyValueInput}
                        placeholder="Amount"
                        value={item.localCost}
                        onChange={(e) =>
                          handleChange(e.target.value, "localCost", index)
                        }
                      />
                    </Input.Group>
                  </div>
                ) : null}
              </div>
              <Layout.Spacer height={10} />

              {state.length > 1 && index !== 0 && (
                <div style={styles.flexRowEnd}>
                  <Button.Primary
                    style={styles.removeButton}
                    onClick={() => handleRemoveProgram(index)}
                  >
                    Remove Program
                  </Button.Primary>
                </div>
              )}
            </>
          ))}
        </div>
      )}
      <Layout.Spacer height={20} />
      <div style={styles.flexRowEnd}>
        <Button.Primary
          style={{ ...styles.buttonStyle, margin: "0px" }}
          onClick={handleAddMoreProgram}
        >
          Add More Program
        </Button.Primary>
      </div>

      {error && (
        <div style={styles.flexColumnCenter}>
          <Layout.Spacer height={20} />
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"500"}
            color={Colors.Danger}
            text={error}
          />
        </div>
      )}

      <Layout.Spacer height={20} />
      <div style={styles.flexColumnCenter}>
        <Button.Primary
          style={styles.buttonStyle}
          onClick={handleNext}
          disabled={saveButtonDisabled}
        >
          Save/Continue
        </Button.Primary>
      </div>

      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => setShowModal(false)}
          closeIcon={true}
          className="ant-modal"
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={styles.modalIcon}
            ></Lottie>
            <div style={styles.modalText}>Successful!</div>
            <Button.Primary
              style={styles.modalButtonStyle}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Done
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
    </div>
  );
}
export default Programs;
