import {
  GET_PROFILE,
  GET_PROFILE_FAILURE,
  GET_PROFILE_SUCCESS,
  EDIT_PROFILE,
  EDIT_PROFILE_FAILURE,
  EDIT_PROFILE_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
} from "../constants";

const initialState = {
  user: {},
  billInfo: [],
  isLoading: true,
  error: null,
};

export default function ProfileReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE:
    case EDIT_PROFILE:
    case CHANGE_PASSWORD:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_PROFILE_SUCCESS:
      state = {
        ...state,
        user: action.payload,
        isLoading: false,
      };
      break;

    case EDIT_PROFILE_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_PROFILE_FAILURE:
    case EDIT_PROFILE_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }
  return state;
}
