import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";

import { AiFillCamera } from "react-icons/ai";

import { SchoolAction } from "../../store/actions";

import { Button, Layout, Text, TextField } from "../../components";
import { Colors, Images } from "../../config";

import styles from "./styles";
import "react-phone-input-2/lib/style.css";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

function AddSchool() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileImgRef = useRef(null);

  const [buttonText, setButtonText] = useState("Save");
  const [buttonDsiabled, setButtonDsiabled] = useState(false);
  const [file, setFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(Images.SchoolProfile);
  const [error, setError] = useState("");
  const [state, setState] = useState({
    name: "",
    imageUrl: "",
    website: "",
    phoneNumber: "",
    email: "",
    location: "",
    city: "",
    country: "",
    noOfStudents: "",
    paymentType: "trial",
    subPeriod: "12",
    amount: "",
    deptHead: "",
    linkedIn: "",
  });

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
          setState({
            ...state,
            imageUrl: result,
          });
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const changeHandler = (e) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid");
      return;
    }
    setFile(file);
  };

  const defaultStates = () => {
    setButtonText("Save");
    setButtonDsiabled(false);
  };

  const handleSubmit = () => {
    setButtonText("Saving...");
    setButtonDsiabled(true);
    if (
      !state.name ||
      !state.imageUrl ||
      !state.website ||
      !state.phoneNumber ||
      !state.email ||
      !state.city ||
      !state.country ||
      ((state.paymentType === "stripe" || state.paymentType === "manual") &&
        !state.amount)
    ) {
      setError("Please fill all the required fields");
      defaultStates();
    } else {
      setError("");
      let data = new FormData();
      data.append("name", state.name);
      data.append("website", state.website);
      data.append("deptHead", state.deptHead);
      data.append("linkedIn", state.linkedIn);
      data.append("phoneNumber", state.phoneNumber);
      data.append("email", state.email);
      data.append("location", `${state.city}, ${state.country}`);
      data.append("city", state.city);
      data.append("country", state.country);
      data.append("noOfStudents", state.noOfStudents);
      data.append("subsriptionPeriod", state.subPeriod);
      data.append("amount", state.amount);
      data.append("paymentType", state.paymentType);
      data.append("image", file);
      dispatch(
        SchoolAction.addSchool(data, () => {
          defaultStates();
          navigate("/schools");
        })
      );
    }
  };

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Add New School"
      />

      <div style={styles.flexCenter}>
        <div
          style={{
            position: "relative",
          }}
        >
          <img src={fileDataURL} alt="school" style={styles.addSchooolImage} />
          <div
            style={styles.cameraIconContainer}
            onClick={() => profileImgRef.current.click()}
          >
            <AiFillCamera color={Colors.Primary} fontSize={20} />
          </div>
        </div>
        <input
          type={"file"}
          ref={profileImgRef}
          accept=".png, .jpg, .jpeg"
          onChange={changeHandler}
          style={{
            display: "none",
          }}
        />
      </div>

      <Layout.Spacer height={20} />
      <div style={styles.inputFieldsContainer}>
        <div>
          <TextField.CustomInput
            placeholder="Greenwich Academy"
            label="School Name*"
            name="name"
            value={state.name}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div>
          <TextField.CustomInput
            placeholder="www.greenwich.org"
            label="Website*"
            name="website"
            value={state.website}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div>
          <label style={styles.labelStyle}>Phone number*</label>
          <PhoneInput
            inputProps={{
              name: "phone",
              required: true,
            }}
            country={"my"}
            placeholder="Phone Number*"
            excludeCountries={[]}
            value={state.phoneNumber}
            onChange={(phone) =>
              setState({
                ...state,
                phoneNumber: phone,
              })
            }
            containerClass="phone-container"
            inputClass="phone-input"
            countryCodeEditable={false}
          />
        </div>
        <div>
          <TextField.CustomInput
            placeholder="contact@greenwich.edu"
            label="Email*"
            name="email"
            value={state.email}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>

        <div>
          <TextField.CustomInput
            placeholder="Name of the Head of Department"
            label="Head of Department"
            name="deptHead"
            value={state.deptHead}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div>
          <TextField.CustomInput
            placeholder="School LinkedIn Link"
            label="LinkedIn"
            name="linkedIn"
            value={state.linkedIn}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div>
          <TextField.CustomInput
            placeholder="Greenwich"
            label="City*"
            name="city"
            value={state.city}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div>
          <TextField.CustomInput
            placeholder="United States"
            label="Country*"
            name="country"
            value={state.country}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div>
          <TextField.CustomInput
            placeholder="200"
            label="No. of Students"
            name="noOfStudents"
            value={state.noOfStudents}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div>
          <div style={{ color: "black" }}>Payment Method</div>
          <select
            value={state.paymentType}
            onChange={handleChange}
            name="paymentType"
            style={{
              ...styles.textfieldStyle,
              padding: "0px 5px",
              outline: "none",
            }}
          >
            <option value="trial">Trial</option>
            <option value="manual">Manual</option>
            <option value="stripe">Stripe</option>
          </select>
        </div>

        {state.paymentType === "stripe" && (
          <div>
            <div style={{ color: "black" }}>Subscription Period</div>
            <select
              value={state.subPeriod}
              onChange={handleChange}
              name="subPeriod"
              style={{
                ...styles.textfieldStyle,
                padding: "0px 5px",
                outline: "none",
              }}
            >
              <option value="1">Monthly</option>
              <option value="12">Yearly</option>
            </select>
          </div>
        )}
        {(state.paymentType === "stripe" || state.paymentType === "manual") && (
          <div>
            <TextField.CustomInput
              placeholder="200"
              label="Amount (USD)*"
              name="amount"
              value={state.amount}
              onChange={handleChange}
              style={styles.textfieldStyle}
            />
          </div>
        )}
      </div>
      <Layout.Spacer height={20} />
      {error && (
        <div style={styles.flexCenter}>
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"500"}
            color={Colors.Danger}
            text={error}
          />
        </div>
      )}
      <Layout.Spacer height={30} />
      <div style={styles.flexCenter}>
        <Button.Primary
          style={styles.addSchoolButton}
          onClick={handleSubmit}
          disabled={buttonDsiabled}
        >
          {buttonText}
        </Button.Primary>
      </div>
    </div>
  );
}

export default AddSchool;
