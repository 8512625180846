import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, message } from "antd";

import ReactCountryFlag from "react-country-flag";

import { FaSearch } from "react-icons/fa";
import { FiUpload } from "react-icons/fi";

import {
  SchoolAction,
  UniversityAction,
  PopularUniversityAction,
  ProfileAction,
  StudentAction,
} from "../../store/actions";

import { Colors, MockData, Images } from "../../config";
import {
  Button,
  Layout,
  Loader,
  Table,
  Text,
  TextField,
  Modal,
} from "../../components";

import styles from "./styles";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const csvSelect = useRef();

  const loggedInUser = useSelector((state) => state.auth.user.user);
  const school = useSelector((state) => state?.school);
  const university = useSelector((state) => state?.university);
  const { popularUniversities, popularCountries, isLoading } = useSelector(
    (state) => state?.popularUniversity
  );
  const onboardedStudents = useSelector(
    (state) => state?.student?.onBoardedStudents
  );

  const profile = useSelector((state) => state.profile);

  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isFileSelected, setFileSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("qsWorldRanking");
  const [filterRegions, setFilterRegions] = useState([
    {
      name: "All",
      selected: true,
    },
    {
      name: "Asia",
      value: "asia",
      selected: false,
    },
    {
      name: "North America",
      value: "north america",
      selected: false,
    },
    {
      name: "Australia",
      value: "australia",
      selected: false,
    },
    {
      name: "Europe",
      value: "europe",
      selected: false,
    },
    {
      name: "Africa",
      value: "africa",
      selected: false,
    },

    {
      name: "South America",
      value: "south america",
      selected: false,
    },
  ]);

  useEffect(() => {
    const schoolPayload = {
      limit: "",
      offset: "",
      search: "",
    };
    dispatch(SchoolAction.getSchools(schoolPayload));
    dispatch(PopularUniversityAction.getPopularUniversities());
    dispatch(ProfileAction.getUserProfile());
    dispatch(StudentAction.getOnboardedStudents());
  }, []);

  useEffect(() => {
    const allRegions = filterRegions.filter((item) => item.selected === true);
    const payload = {
      search,
      region: allRegions[0].name === "All" ? "" : allRegions[0].value,
      limit,
      offset: 0,
      filter: selectedValue,
    };
    dispatch(UniversityAction.getUniversities(payload));

    setPage(1);
  }, [filterRegions, selectedValue, search]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterStatus = (status) => {
    const newStatusFilters = filterRegions?.map((filter) => {
      if (filter.name === status.name) {
        return {
          ...filter,
          selected: !filter.selected,
        };
      } else {
        return {
          ...filter,
          selected: false,
        };
      }
    });
    setFilterRegions(newStatusFilters);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handlePaginationChange = (pageNumber) => {
    const allRegions = filterRegions.filter((item) => item.selected === true);
    let offset = (pageNumber - 1) * limit;

    const payload = {
      search: search,
      region: allRegions[0].name === "All" ? "" : allRegions[0].value,
      limit: limit,
      offset: offset,
      filter: selectedValue,
    };
    dispatch(UniversityAction.getUniversities(payload));

    setOffset(offset);
    setPage(pageNumber);
  };

  const onChange = async (formData) => {
    dispatch(
      UniversityAction.addUniversitySheet(formData, (res) => {
        setShowModal(false);
        setTimeout(() => {
          setButtonDisabled(false);
        }, 5000);
        if (res.status === 500) {
          message.error(res.data.message);
        } else {
          message.success("Universities are being added");
        }
      })
    );
  };

  const fileSelected = (event) => {
    const formData = new FormData();
    Array.from(event.target.files).forEach((file) => {
      formData.append("file", file);
    });
    setFileSelected(true);
    onChange(formData);
    setTimeout(() => {
      setFileSelected(false);
    }, 1000);
  };

  const analytics = [
    {
      id: 1,
      title: "Registered Schools",
      image: Images.RegisteredSchools,
      value: school.count || "0",
    },
    {
      id: 2,
      title: "Students On-Boarded",
      image: Images.ActiveStudents,
      value: onboardedStudents || "0",
    },
    {
      id: 3,
      title: "Total Universities",
      image: Images.TotalUniversities,
      value: university?.totalUniversities || "0",
    },
  ];

  return (
    <div>
      <div style={styles.flexColumn}>
        <Text.Heading
          style={styles.welcomeText}
          text={
            profile?.user?.fullName
              ? `Welcome ${profile?.user?.fullName},`
              : "Welcome,"
          }
        />
        <Layout.Spacer height={5} />
        <Text.Heading
          style={styles.welcomeSubText}
          text="Analytics - All Student Data"
        />
      </div>
      <Layout.Spacer height={20} />
      <div style={styles.flexColumn}>
        <div style={styles.flexRowJustifyBetween}>
          {analytics?.map((item) => (
            <div
              style={{
                ...styles.analyticsBox,
                marginRight: item.id === 3 ? "0px" : "20px",
              }}
              key={item.id}
            >
              <div>
                <div style={styles.analyticsTitle}>{item.title}</div>
                <div style={styles.analyticsValue}>{item.value}</div>
              </div>
              <div>
                <img src={item.image} height={80} width={80} alt="" />
              </div>
            </div>
          ))}
        </div>

        <div style={styles.popularContainer}>
          <div style={styles.popularLeftSide}>
            <div style={styles.popularTitle}>Most Popular Universities</div>
            {isLoading ? (
              <Loader.Circular />
            ) : (
              <div style={styles.popularValuesContainer}>
                {popularUniversities?.map((university, index) => {
                  return (
                    <div style={styles.popularValues} key={index}>
                      <img
                        src={university.profilePic || Images.TotalUniversities}
                        style={styles.popularUniImage}
                        alt="uniImg"
                      />
                      <span style={styles.popularUniName}>
                        {university.instituteName.length > 35
                          ? `${university.instituteName.slice(0, 35)}...`
                          : university.instituteName}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div style={styles.popularRightSide}>
            <div style={styles.popularTitle}>Most Popular Countries</div>
            {isLoading ? (
              <Loader.Circular />
            ) : (
              <div style={styles.popularValuesContainer}>
                {popularCountries?.map((country, index) => {
                  let found = MockData?.CountryListAllIsoData?.find(
                    (countryCode) =>
                      countryCode.name.toLowerCase() ===
                      country.name.toLowerCase()
                  );

                  let code = found ? found.code : "n/a";

                  return (
                    <div style={styles.popularValues} key={index}>
                      <ReactCountryFlag
                        countryCode={code}
                        svg
                        style={styles.countryFlag}
                        title={code}
                      />
                      <span style={styles.popularUniName}>
                        {country.name.length > 15
                          ? `${country.name.slice(0, 15)}...`
                          : country.name}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <Layout.Spacer height={20} />
      <div style={{ ...styles.flexRow, width: "100%" }}>
        <div
          style={{
            width: "100%",
            marginRight: "10px",
          }}
        >
          <TextField.CustomInput
            placeholder="Search Universities"
            suffix={<FaSearch color={Colors.Primary} />}
            style={styles.customTextfieldStyle}
            onChange={handleChange}
          />
        </div>
        <div
          style={{
            marginLeft: "10px",
            position: "relative",
          }}
        >
          <div style={styles.filterButton} onClick={toggleDropdown}>
            <img src={Images.Sort} style={{ width: "100%" }} />
          </div>
          {isOpen && (
            <div style={styles.filterDropdown}>
              <div
                style={{
                  ...styles.filterDropdownValue,
                  fontWeight:
                    selectedValue === "qsWorldRanking" ? "700" : "400",
                }}
                onClick={() => {
                  setSelectedValue("qsWorldRanking");
                  toggleDropdown();
                }}
              >
                QS World Ranking
              </div>

              <div
                style={{
                  cursor: "pointer",
                  fontWeight: selectedValue === "name" ? "700" : "400",
                }}
                onClick={() => {
                  setSelectedValue("name");
                  toggleDropdown();
                }}
              >
                Alphabetical
              </div>
            </div>
          )}
        </div>
        {loggedInUser?.role === "admin" && (
          <div
            style={{
              width: "20%",
              marginLeft: "20px",
            }}
          >
            <Button.Primary
              style={styles.addUniversityButton}
              onClick={() => setShowModal(true)}
              disabled={isButtonDisabled}
            >
              Add New University
            </Button.Primary>
          </div>
        )}
      </div>
      <Layout.Spacer height={30} />
      <div style={styles.flexRow}>
        <Text.Heading style={styles.filterRegionText} text="Filter by Region" />
        <div style={styles.flexRow}>
          {filterRegions?.map((region) => (
            <div
              style={{
                ...styles.filterRegion,
                backgroundColor: region?.selected
                  ? Colors.Primary
                  : Colors.White,
                color: region?.selected ? Colors.White : Colors.BlackText2,
                border: region?.selected ? "" : "1px solid #387f768c",
              }}
              onClick={() => handleFilterStatus(region)}
              key={region?.name}
            >
              {region?.name}
            </div>
          ))}
        </div>
      </div>
      <Layout.Spacer height={30} />
      {university.isLoading ? (
        <Loader.Circular />
      ) : university?.universities?.length > 0 ? (
        <>
          {university?.universities?.map((university) => (
            <Table.CustomRowTable
              key={university._id}
              university={university}
              handleClick={() =>
                navigate(`/universities/${university._id}/general`)
              }
            />
          ))}
          <div style={styles.flexCenter}>
            <Pagination
              responsive="true"
              showSizeChanger={false}
              hideOnSinglePage={true}
              current={page}
              pageSize={10}
              total={university.count}
              onChange={handlePaginationChange}
            />
          </div>
        </>
      ) : (
        <div style={{ ...styles.flexCenter, color: "black" }}>
          No University Found
        </div>
      )}
      <Layout.Spacer height={20} />
      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => {
            setShowModal(false);
            setButtonDisabled(false);
          }}
          closeIcon={true}
          className="ant-modal"
        >
          <Layout.Spacer height={20} />
          <div style={styles.flexColumnCenter}>
            <div style={styles.uploadUniTitle}>Upload University File</div>
            <Layout.Spacer height={20} />
            <input
              style={{ display: "none" }}
              type="file"
              name="csv"
              id="getFile"
              ref={csvSelect}
              onChange={fileSelected}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <Button.Primary
              style={{ ...styles.addUniversityButton, width: "80%" }}
              onClick={() => {
                setButtonDisabled(true);
                document.getElementById("getFile").click();
              }}
            >
              <span style={{ marginRight: "10px" }}>
                <FiUpload style={{ height: "20px", width: "20px" }} />
              </span>
              <span>Upload Excel Sheet</span>
            </Button.Primary>
          </div>
          <Layout.Spacer height={20} />
        </Modal.Basic>
      )}
    </div>
  );
};

export default Dashboard;
