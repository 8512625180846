import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import Lottie from "react-lottie-player";

import { ImBin2 } from "react-icons/im";

import { UniversityAction } from "../../store/actions";

import { Button, Text, TextField, Modal, Layout } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

import Done from "../../assets/images/57767-done.json";

function Summary() {
  const location = useLocation();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.university.university);
  const id = location.pathname.split("/")[2];

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const [state, setState] = useState({
    courses: "",
    courses_duration: "",
    primary_campus: "",
    fee_structure: "",
    scholarship_availability: "available",
    qsWorldRanking: "",
    status: "data.summary?.status",
    research_output: "",
  });

  useEffect(() => {
    dispatch(UniversityAction.getUniversityDetails(id));
  }, []);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleClear = () => {
    setState({
      courses: "",
      courses_duration: "",
      primary_campus: "",
      fee_structure: "",
      scholarship_availability: "available",
    });
  };

  const handleNext = () => {
    setSaveButtonDisabled(true);

    if (
      !state.courses_duration ||
      !state.fee_structure ||
      !state.scholarship_availability ||
      !state.primary_campus
    ) {
      setError("Please fill all required fields");

      setSaveButtonDisabled(false);
    } else {
      setError("");

      const body = {
        courses: !state.courses ? "NA" : state.courses,
        courses_duration: state.courses_duration
          ? state.courses_duration
          : "NA",
        primary_campus: !state.primary_campus ? "NA" : state.primary_campus,
        fee_structure: !state.fee_structure ? "NA" : state.fee_structure,
        scholarship_availability: state.scholarship_availability
          ? state.scholarship_availability
          : "NA",
        qsWorldRanking: state.qsWorldRanking ? state.qsWorldRanking : "NA",
        research_output: state.research_output ? state.research_output : "NA",
        status: state.status ? state.status : "NA",
      };

      let dataToUpload = new FormData();
      dataToUpload.append("summary", JSON.stringify(body));
      dataToUpload.append("tab", "summary");

      dispatch(
        UniversityAction.editUniversity(id, dataToUpload, (res) => {
          setSaveButtonDisabled(false);
          if (res.status === 200) {
            setShowModal(true);
          }
        })
      );
    }
  };

  useEffect(() => {
    setState({
      qsWorldRanking: data.summary?.qsWorldRanking
        ? data.summary?.qsWorldRanking
        : "NA",
      courses: data.summary?.courses ? data.summary?.courses : "NA",
      courses_duration: data.summary?.courses_duration
        ? data.summary?.courses_duration
        : "NA",
      primary_campus: data.summary?.primary_campus
        ? data.summary?.primary_campus
        : "NA",
      fee_structure: data.summary?.fee_structure
        ? data.summary?.fee_structure
        : "NA",
      scholarship_availability: data.summary?.scholarship_availability
        ? data.summary?.scholarship_availability
        : "NA",
      status: data.summary?.status ? data.summary?.status : "NA",
      research_output: data.summary?.research_output
        ? data.summary?.research_output
        : "NA",
    });
  }, []);

  return (
    <div style={styles.inputContainer}>
      <div style={styles.justifyBetween}>
        <Text.Heading
          fontSize={"16px"}
          fontWeight={"700"}
          color={Colors.Primary}
          text="Summary"
        />
        <div onClick={handleClear}>
          <Text.Heading
            fontSize={"10px"}
            fontWeight={"400"}
            color={Colors.Gray}
            text="Clear Fields"
          />
          <ImBin2 color={Colors.Primary} fontSize={14} />
        </div>
      </div>

      <div style={styles.costTitleContainer}>
        <div>
          <div style={styles.inputLabel}>Courses Offered</div>
          <TextField.CustomInput
            placeholder="www.harvard.edu/courses"
            name="courses"
            value={state.courses}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Courses Duration *</div>
          <TextField.CustomInput
            placeholder="Variable (1,2,4 Years)"
            name="courses_duration"
            value={state.courses_duration}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Primary Campus *</div>
          <TextField.CustomInput
            placeholder="Cambridge"
            name="primary_campus"
            optional={true}
            value={state.primary_campus}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Fee Structure *</div>
          <TextField.CustomInput
            placeholder="www.harvard.edu/fee_structure"
            name="fee_structure"
            optional={true}
            value={state.fee_structure}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div>
          <div
            className="university-form-1"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={styles.inputLabel}>Scholarship Availability *</div>
            <Select
              defaultValue="available"
              value={state.scholarship_availability}
              onChange={(e) => {
                setState({
                  ...state,
                  scholarship_availability: e,
                });
              }}
            >
              <Select.Option value="available">Available</Select.Option>
              <Select.Option value="notAvailable">Not available</Select.Option>
            </Select>
          </div>
        </div>
      </div>
      {error && (
        <div style={styles.flexColumnCenter}>
          <Layout.Spacer height={20} />
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"500"}
            color={Colors.Danger}
            text={error}
          />
        </div>
      )}

      <Layout.Spacer height={20} />
      <div style={styles.flexColumnCenter}>
        <Button.Primary
          style={styles.buttonStyle}
          onClick={handleNext}
          disabled={saveButtonDisabled}
        >
          Save/Continue
        </Button.Primary>
      </div>
      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => setShowModal(false)}
          closeIcon={true}
          className="ant-modal"
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={styles.modalIcon}
            ></Lottie>

            <div style={styles.modalText}>Successfully Submitted!</div>
            <div style={styles.labelStyle}>
              Your Data Has Been Submitted Successfully
            </div>
            <Layout.Spacer height={20} />
            <Button.Primary
              style={styles.modalButtonStyle}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Done
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
    </div>
  );
}
export default Summary;
