import { put } from "redux-saga/effects";

import { TrackerAction } from "../actions";
import { ApiCaller } from "../../config";

export default class TrackerMiddleware {
  static *addTracker({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/applicationStatusTracker/addTrackerData`,
        payload
      );

      if (res.status === 200) {
        yield put(TrackerAction.addTrackerSuccess(res.data.data));
      } else {
        yield put(TrackerAction.addTrackerFailure());
      }
      if (cb) {
        cb(res.status);
      }
    } catch (err) {
      yield put(TrackerAction.addTrackerFailure());
    }
  }

  static *getTracker({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/applicationStatusTracker/getSpecificTrackerData`,
        payload
      );

      if (res.status === 200) {
        yield put(TrackerAction.getTrackersSuccess(res.data.data));
      } else {
        yield put(TrackerAction.getTrackerFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(TrackerAction.getTrackerFailure());
    }
  }

  static *getAllTracker({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/applicationStatusTracker/getTrackerData?searchInput=${payload.search}`,
        payload
      );

      if (res.status === 200) {
        yield put(TrackerAction.getAllTrackersSuccess(res.data.data));
      } else {
        yield put(TrackerAction.getAllTrackerFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(TrackerAction.getAllTrackerFailure());
    }
  }
  static *updateTracker({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/applicationStatusTracker/updateTrackerData`,
        payload
      );
      if (res.status === 200) {
        yield put(TrackerAction.updateTrackerSuccess(res.data.data));
      } else {
        yield put(TrackerAction.updateTrackerFailure());
      }
      if (cb) {
        cb(res.status);
      }
    } catch (err) {
      yield put(TrackerAction.updateTrackerFailure());
    }
  }
  static *deleteTracker({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/applicationStatusTracker/deleteTrackerData`,
        payload
      );
      if (res.status === 200) {
        yield put(TrackerAction.deleteTrackerSuccess(res.data.data));
      } else {
        yield put(TrackerAction.deleteTrackerFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(TrackerAction.deleteTrackerFailure());
    }
  }
}
