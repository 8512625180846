import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { ContactAction } from "../actions";

export default class ContactMiddleware {
  static *addContact({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/miscellaneous/add-contact`, payload);
      if (res.status === 200) {
        yield put(ContactAction.addContactSuccess(res.data.data));
        Utils.showSnackBar({
          message: "Your message has been submitted",
          severity: "success",
        });
      } else {
        yield put(ContactAction.addContactFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(ContactAction.addContactFailure());
    }
  }
}
