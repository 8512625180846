import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";

import Lottie from "react-lottie-player";
import PhoneInput from "react-phone-input-2";

import { UniversityAction } from "../../store/actions";

import { Button, Text, TextField, Modal } from "../../components";
import { Colors, Images } from "../../config";

import { AiFillCamera } from "react-icons/ai";
import { ImBin2 } from "react-icons/im";

import Done from "../../assets/images/57767-done.json";

import "react-phone-input-2/lib/style.css";
import styles from "./styles";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

function General() {
  const dispatch = useDispatch();
  const location = useLocation();
  const profileImgRef = useRef(null);

  const data = useSelector((state) => state.university.university);

  const locationData = data.location.split(",");
  const id = location.pathname.split("/")[2];

  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [fileDataURL, setFileDataURL] = useState(
    data.profilePic ? data?.profilePic : Images.UniversityDefault
  );
  const [generalState, setGeneralState] = useState({
    profilePic: data.profilePic ? data.profilePic : "NA",
    name: data.name ? data.name : "NA",
    websiteLink: data.websiteLink ? data.websiteLink : "NA",
    phoneNumber: data.phoneNumber ? data.phoneNumber : "NA",
    email: data.email ? data.email : "NA",
    city: locationData ? locationData[0] : "NA",
    state: locationData.length === 3 ? locationData[1] : "",
    country: locationData.length === 3 ? locationData[2] : locationData[1],
    continent: data.region ? data.region : "NA",
    locationType: data.locationType ? data.locationType : "NA",
    applicationLink: data.applicationLink ? data.applicationLink : "NA",
    details: data.details ? data.details : "NA",
    address: data.address ? data.address : "NA",
    status: data.summary.status ? data.summary.status : "NA",
    researchOutput: data.summary.research_output
      ? data.summary.research_output
      : "NA",
    totalUGStudents: data.totalUGStudents ? data.totalUGStudents : "NA",
    averageClassSize: data.averageClassSize ? data.averageClassSize : "NA",
    acceptanceRate: data.acceptanceRate ? data.acceptanceRate : "NA",
    facultyToStudentRatio: data.facultyToStudentRatio
      ? data.facultyToStudentRatio
      : "NA",
    maleToFemaleRatio: data.maleToFemaleRatio ? data.maleToFemaleRatio : "NA",
    CWURRanking: data.CWURRanking ? data.CWURRanking : "NA",
    qsWorldRanking: data.summary.qsWorldRanking
      ? data.summary.qsWorldRanking
      : "NA",
    timesRanking: data.timesRanking ? data.timesRanking : "NA",
    gapYear: data.gapYear ? data.gapYear : "NA",
    employedOrInGraduateSchool: data.employedOrInGraduateSchool
      ? data.employedOrInGraduateSchool
      : "NA",
    SATMath:
      !data.SATMath.twentyFivePercentile && !data.SATMath.seventyFivePercentile
        ? {
            twentyFivePercentile: "NA",
            seventyFivePercentile: "NA",
          }
        : {
            twentyFivePercentile: data.SATMath.twentyFivePercentile,
            seventyFivePercentile: data.SATMath.seventyFivePercentile,
          },
    SATEnglish:
      !data.SATEnglish.twentyFivePercentile &&
      !data.SATEnglish.seventyFivePercentile
        ? {
            twentyFivePercentile: "NA",
            seventyFivePercentile: "NA",
          }
        : {
            twentyFivePercentile: data.SATEnglish.twentyFivePercentile,
            seventyFivePercentile: data.SATEnglish.seventyFivePercentile,
          },
    ACTMath:
      !data.ACTMath.twentyFivePercentile && !data.ACTMath.seventyFivePercentile
        ? {
            twentyFivePercentile: "NA",
            seventyFivePercentile: "NA",
          }
        : {
            twentyFivePercentile: data.ACTMath.twentyFivePercentile,
            seventyFivePercentile: data.ACTMath.seventyFivePercentile,
          },
    ACTComposite:
      !data.ACTComposite.twentyFivePercentile &&
      !data.ACTComposite.seventyFivePercentile
        ? {
            twentyFivePercentile: "NA",
            seventyFivePercentile: "NA",
          }
        : {
            twentyFivePercentile: data.ACTComposite.twentyFivePercentile,
            seventyFivePercentile: data.ACTComposite.seventyFivePercentile,
          },
    ACTEnglish:
      !data.ACTEnglish.twentyFivePercentile &&
      !data.ACTEnglish.seventyFivePercentile
        ? {
            twentyFivePercentile: "NA",
            seventyFivePercentile: "NA",
          }
        : {
            twentyFivePercentile: data.ACTEnglish.twentyFivePercentile,
            seventyFivePercentile: data.ACTEnglish.seventyFivePercentile,
          },
    requirements: {
      SAT: data.requirements.SAT ? data.requirements.SAT.toLowerCase() : "NA",
      TOEFL: data.requirements.TOEFL
        ? data.requirements.TOEFL.toLowerCase()
        : "NA",
      IELTS: data.requirements.IELTS
        ? data.requirements.IELTS.toLowerCase()
        : "NA",
    },
    SATMath25Percentile: data.SATMath.twentyFivePercentile
      ? data.SATMath.twentyFivePercentile
      : "NA",
    SATMath75Percentile: data.SATMath.seventyFivePercentile
      ? data.SATMath.seventyFivePercentile
      : "NA",
    SATEnglish25Percentile: data.SATEnglish.twentyFivePercentile
      ? data.SATEnglish.twentyFivePercentile
      : "NA",
    SATEnglish75Percentile: data.SATEnglish.seventyFivePercentile
      ? data.SATEnglish.seventyFivePercentile
      : "NA",
    ACTMath25Percentile: data.ACTMath.twentyFivePercentile
      ? data.ACTMath.twentyFivePercentile
      : "NA",
    ACTMath75Percentile: data.ACTMath.seventyFivePercentile
      ? data.ACTMath.seventyFivePercentile
      : "NA",
    ACTComposite25Percentile: data.ACTComposite.twentyFivePercentile
      ? data.ACTComposite.twentyFivePercentile
      : "NA",
    ACTComposite75Percentile: data.ACTComposite.seventyFivePercentile
      ? data.ACTComposite.seventyFivePercentile
      : "NA",
    ACTEnglish25Percentile: data.ACTEnglish.twentyFivePercentile
      ? data.ACTEnglish.twentyFivePercentile
      : "NA",
    ACTEnglish75Percentile: data.ACTEnglish.seventyFivePercentile
      ? data.ACTEnglish.seventyFivePercentile
      : "NA",
    SAT: data.requirements.SAT ? data.requirements.SAT.toLowerCase() : "NA",
    TOEFL: data.requirements.TOEFL
      ? data.requirements.TOEFL.toLowerCase()
      : "NA",
    IELTS: data.requirements.IELTS
      ? data.requirements.IELTS.toLowerCase()
      : "NA",
  });

  useEffect(() => {
    dispatch(UniversityAction.getUniversityDetails(id));
  }, []);

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
          setGeneralState({
            ...generalState,
            profilePic: result,
          });
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  const changeHandler = (e) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid");
      return;
    }
    setFile(file);
  };

  const handleChange = (e) => {
    setGeneralState({
      ...generalState,
      [e.target.name]: e.target.value,
    });
  };

  const handleClear = () => {
    setFileDataURL(Images.UniversityDefault);
    setGeneralState({
      profilePic: "",
      name: "",
      websiteLink: "",
      phoneNumber: "",
      email: "",
      city: "",
      state: "",
      country: "",
      continent: "",
      locationType: "",
      location: "",
      applicationLink: "",
      details: "",
      address: "",
      totalUGStudents: "",
      averageClassSize: "",
      acceptanceRate: "",
      facultyToStudentRatio: "",
      CWURRanking: "",
      timesRanking: "",
      qsWorldRanking: "",
      status: "",
      gapYear: "",
      researchOutput: "",
      employedOrInGraduateSchool: "",
      SATMath: {},
      SATEnglish: {},
      ACTMath: {},
      ACTComposite: {},
      ACTEnglish: {},
      requirements: {},
      SATMath25Percentile: "",
      SATMath75Percentile: "",
      SATEnglish25Percentile: "",
      SATEnglish75Percentile: "",
      ACTMath25Percentile: "",
      ACTMath75Percentile: "",
      ACTComposite25Percentile: "",
      ACTComposite75Percentile: "",
      ACTEnglish25Percentile: "",
      ACTEnglish75Percentile: "",
      SAT: "required",
      TOEFL: "required",
      IELTS: "required",
    });
  };

  const handleNext = () => {
    setSaveButtonDisabled(true);
    if (
      !generalState.profilePic ||
      !generalState.name ||
      !generalState.websiteLink ||
      !generalState.city ||
      !generalState.country ||
      !generalState.continent ||
      !generalState.locationType ||
      !generalState.details ||
      !generalState.address
    ) {
      setError("Please fill all the required fields");
      setSaveButtonDisabled(false);
    } else {
      setError("");

      let dataToUpload = new FormData();

      const body = {
        profilePic: generalState.profilePic,
        name: generalState.name,
        websiteLink: generalState.websiteLink.toLowerCase(),
        phoneNumber:
          generalState.phoneNumber === "" ? "NA" : generalState.phoneNumber,
        email:
          generalState.email === "" ? "NA" : generalState.email.toLowerCase(),
        totalUGStudents:
          generalState.totalUGStudents === ""
            ? "NA"
            : generalState.totalUGStudents,
        averageClassSize:
          generalState.averageClassSize === ""
            ? "NA"
            : generalState.averageClassSize,
        acceptanceRate:
          generalState.acceptanceRate === ""
            ? "NA"
            : generalState.acceptanceRate,
        facultyToStudentRatio:
          generalState.facultyToStudentRatio === ""
            ? "NA"
            : generalState.facultyToStudentRatio,
        CWURRanking:
          generalState.CWURRanking === "" ? "NA" : generalState.CWURRanking,
        timesRanking:
          generalState.timesRanking === "" ? "NA" : generalState.timesRanking,
        qsWorldRanking:
          generalState.qsWorldRanking === ""
            ? "NA"
            : generalState.qsWorldRanking,
        maleToFemaleRatio: generalState.maleToFemaleRatio
          ? generalState.maleToFemaleRatio
          : "NA",
        gapYear: generalState.gapYear === "" ? "NA" : generalState.gapYear,
        status: generalState.status,
        researchOutput: generalState.researchOutput,
        applicationLink:
          generalState.applicationLink === ""
            ? "NA"
            : generalState.applicationLink,
        details: generalState.details,
        address: generalState.address,
        employedOrInGraduateSchool:
          generalState.employedOrInGraduateSchool === ""
            ? "NA"
            : generalState.employedOrInGraduateSchool,
        location: generalState.state
          ? `${generalState.city}, ${generalState.state}, ${generalState.country}`
          : `${generalState.city}, ${generalState.country}`,
        region: generalState.continent,
        locationType: generalState.locationType,
        SATMath:
          !generalState.SATMath25Percentile && !generalState.SATMath75Percentile
            ? {
                twentyFivePercentile: "NA",
                seventyFivePercentile: "NA",
              }
            : {
                twentyFivePercentile: generalState.SATMath25Percentile,
                seventyFivePercentile: generalState.SATMath75Percentile,
              },
        SATEnglish:
          !generalState.SATEnglish25Percentile &&
          !generalState.SATEnglish75Percentile
            ? {
                twentyFivePercentile: "NA",
                seventyFivePercentile: "NA",
              }
            : {
                twentyFivePercentile: generalState.SATEnglish25Percentile,
                seventyFivePercentile: generalState.SATEnglish75Percentile,
              },
        ACTMath:
          !generalState.ACTMath25Percentile && !generalState.ACTMath75Percentile
            ? {
                twentyFivePercentile: "NA",
                seventyFivePercentile: "NA",
              }
            : {
                twentyFivePercentile: generalState.ACTMath25Percentile,
                seventyFivePercentile: generalState.ACTMath75Percentile,
              },
        ACTComposite:
          !generalState.ACTComposite25Percentile &&
          !generalState.ACTComposite75Percentile
            ? {
                twentyFivePercentile: "NA",
                seventyFivePercentile: "NA",
              }
            : {
                twentyFivePercentile: generalState.ACTComposite25Percentile,
                seventyFivePercentile: generalState.ACTComposite75Percentile,
              },
        ACTEnglish:
          !generalState.ACTEnglish25Percentile &&
          !generalState.ACTEnglish75Percentile
            ? {
                twentyFivePercentile: "NA",
                seventyFivePercentile: "NA",
              }
            : {
                twentyFivePercentile: generalState.ACTEnglish25Percentile,
                seventyFivePercentile: generalState.ACTEnglish75Percentile,
              },
        requirements: {
          SAT: generalState.SAT,
          TOEFL: generalState.TOEFL,
          IELTS: generalState.IELTS,
        },
      };

      // console.log(body);
      dataToUpload.append("tab", "general");
      dataToUpload.append("edit", file ? true : false);
      dataToUpload.append("image", file);
      dataToUpload.append("general", JSON.stringify(body));

      dispatch(
        UniversityAction.editUniversity(id, dataToUpload, (res) => {
          setSaveButtonDisabled(false);
          if (res.status === 200) {
            setShowModal(true);
          }
        })
      );
    }
  };

  return (
    <div style={styles.inputContainer}>
      <div style={styles.flexColumnCenter}>
        <div
          style={{
            position: "relative",
          }}
        >
          <img src={fileDataURL} alt="img" style={styles.imageStyle} />
          <div
            style={styles.cameraIcon}
            onClick={() => profileImgRef.current.click()}
          >
            <AiFillCamera color={Colors.Primary} fontSize={20} />
          </div>
        </div>
        <input
          type={"file"}
          ref={profileImgRef}
          accept=".png, .jpg, .jpeg"
          onChange={changeHandler}
          style={{
            display: "none",
          }}
        />
      </div>

      <div style={styles.clearFieldContainer}>
        <div onClick={handleClear}>
          <Text.Heading
            fontSize={"10px"}
            fontWeight={"400"}
            color={Colors.Gray}
            text={"Clear Fields"}
          />
          <ImBin2 color={Colors.Primary} fontSize={14} />
        </div>
      </div>

      <div style={styles.inputFieldsContainer}>
        <div>
          <div style={styles.inputLabel}>University Name *</div>
          <TextField.CustomInput
            placeholder="Harward University"
            optional={true}
            name="name"
            onChange={handleChange}
            value={generalState.name}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>City *</div>
          <TextField.CustomInput
            placeholder="Cambridge, United States"
            optional={true}
            name="city"
            value={generalState.city}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>State</div>
          <TextField.CustomInput
            placeholder="Urban"
            name="state"
            value={generalState.state}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Continent *</div>
          <div className="university-form-1" style={styles.flexColumn}>
            <Select
              defaultValue={generalState.continent}
              value={generalState.continent}
              onChange={(e) => {
                setGeneralState({
                  ...generalState,
                  continent: e,
                });
              }}
              style={{ outline: "none" }}
            >
              <Select.Option value="asia">Asia</Select.Option>
              <Select.Option value="australia">Australia</Select.Option>
              <Select.Option value="europe">Europe</Select.Option>
              <Select.Option value="africa">Africa</Select.Option>
              <Select.Option value="north america">North America</Select.Option>
              <Select.Option value="south america">South America</Select.Option>
              <Select.Option value="antarctica">Antarctica</Select.Option>
            </Select>
          </div>
        </div>
        <div>
          <div style={styles.inputLabel}>Country *</div>
          <TextField.CustomInput
            placeholder="Cambridge, United States"
            optional={true}
            name="country"
            value={generalState.country}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>About Us</div>
          <TextField.CustomTextArea
            rows={1}
            name="details"
            value={generalState.details}
            onChange={(e) => {
              setGeneralState({
                ...generalState,
                details: e.target.value,
              });
            }}
            placeholder={
              "Established in 1636, Harvard is the oldest higher education institution in the United States, and is widely regarded in terms of its influence, reputation, and academic pedigree as a leading university in not just the US but also the world. "
            }
            style={{
              padding: "5px",
              fontSize: "14px",
            }}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Website *</div>
          <TextField.CustomInput
            placeholder="www.harvard.edu"
            optional={true}
            name="websiteLink"
            onChange={handleChange}
            value={generalState.websiteLink}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Phone Number</div>
          <PhoneInput
            inputStyle={styles.phoneInput}
            inputProps={{
              name: "phoneNumber",
              required: true,
            }}
            country={"my"}
            name="phoneNumber"
            placeholder="Phone Number"
            excludeCountries={[]}
            value={generalState.phoneNumber}
            onChange={(phone) =>
              setGeneralState({
                ...generalState,
                phoneNumber: phone,
              })
            }
            containerClass="phone-container"
            inputClass="phone-input"
            countryCodeEditable={false}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Email</div>
          <TextField.CustomInput
            placeholder="contact@harvard.edu"
            name="email"
            onChange={handleChange}
            value={generalState.email}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>QS World Ranking</div>
          <TextField.CustomInput
            placeholder="1"
            name="qsWorldRanking"
            onChange={handleChange}
            value={generalState.qsWorldRanking}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Application Link</div>
          <TextField.CustomInput
            placeholder="Example"
            name="applicationLink"
            value={generalState.applicationLink}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Total UG Students</div>
          <TextField.CustomInput
            placeholder="27,000"
            name="totalUGStudents"
            onChange={handleChange}
            value={generalState.totalUGStudents}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Average Class Size</div>
          <TextField.CustomInput
            placeholder="50"
            name="averageClassSize"
            value={generalState.averageClassSize}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Faculty To Student Ratio</div>
          <TextField.CustomInput
            placeholder="1:7"
            name="facultyToStudentRatio"
            value={generalState.facultyToStudentRatio}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Male to Female Ratio</div>
          <TextField.CustomInput
            placeholder="7:1"
            name="maleToFemaleRatio"
            value={generalState.maleToFemaleRatio}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Acceptance Rate (%)</div>
          <TextField.CustomInput
            placeholder="63"
            value={generalState.acceptanceRate}
            name="acceptanceRate"
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Location Type *</div>
          <div
            className="university-form-1"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Select
              defaultValue="urban"
              value={generalState.locationType}
              onChange={(e) => {
                setGeneralState({
                  ...generalState,
                  locationType: e,
                });
              }}
              style={{
                borderRadius: "5px",
                outline: "none",
              }}
            >
              <Select.Option value="urban">Urban</Select.Option>
              <Select.Option value="rural">Rural</Select.Option>
            </Select>
          </div>
        </div>
        <div>
          <div style={styles.inputLabel}>Status</div>
          <TextField.CustomInput
            placeholder="public"
            value={generalState.status}
            name="status"
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Research Output</div>
          <TextField.CustomInput
            placeholder="High"
            value={generalState.researchOutput}
            name="researchOutput"
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>CWUR Ranking</div>
          <TextField.CustomInput
            placeholder="1"
            name="CWURRanking"
            value={generalState.CWURRanking}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Times Ranking</div>
          <TextField.CustomInput
            placeholder="1"
            name="timesRanking"
            value={generalState.timesRanking}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Employed or in Graduate School</div>
          <TextField.CustomInput
            placeholder="90%"
            name="employedOrInGraduateSchool"
            value={generalState.employedOrInGraduateSchool}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Gap Year or Seeking Employment</div>
          <TextField.CustomInput
            placeholder="20"
            name="gapYear"
            value={generalState.gapYear}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>SAT Math 25th Percentile</div>
          <TextField.CustomInput
            placeholder="750"
            name="SATMath25Percentile"
            value={generalState.SATMath25Percentile}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>SAT Math 75th Percentile</div>
          <TextField.CustomInput
            placeholder="800"
            name="SATMath75Percentile"
            value={generalState.SATMath75Percentile}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.satStyle}>
            SAT Evidence Base Reading and Writing 25th Percentile
          </div>
          <TextField.CustomInput
            placeholder="730"
            name="SATEnglish25Percentile"
            value={generalState.SATEnglish25Percentile}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.satStyle}>
            SAT Evidence Base Reading and Writing 75th Percentile
          </div>
          <TextField.CustomInput
            placeholder="780"
            name="SATEnglish75Percentile"
            value={generalState.SATEnglish75Percentile}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>ACT Math 25th Percentile</div>
          <TextField.CustomInput
            placeholder="32"
            name="ACTMath25Percentile"
            value={generalState.ACTMath25Percentile}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>ACT Math 75th Percentile</div>
          <TextField.CustomInput
            placeholder="35"
            name="ACTMath75Percentile"
            value={generalState.ACTMath75Percentile}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>ACT English 25th Percentile</div>
          <TextField.CustomInput
            placeholder="35"
            name="ACTEnglish25Percentile"
            value={generalState.ACTEnglish25Percentile}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>ACT English 75th Percentile</div>
          <TextField.CustomInput
            placeholder="36"
            name="ACTEnglish75Percentile"
            value={generalState.ACTEnglish75Percentile}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>ACT Composite 25th Percentile</div>
          <TextField.CustomInput
            placeholder="33"
            name="ACTComposite25Percentile"
            value={generalState.ACTComposite25Percentile}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>ACT Composite 75th Percentile</div>
          <TextField.CustomInput
            placeholder="36"
            name="ACTComposite75Percentile"
            value={generalState.ACTComposite75Percentile}
            onChange={handleChange}
            style={styles.inputStyle}
          />
        </div>
        <div style={styles.flexColumn} className="university-form-1">
          <div style={styles.inputLabel}>SAT/ACT</div>
          <Select
            defaultValue="required"
            value={generalState.SAT}
            onChange={(e) => {
              setGeneralState({
                ...generalState,
                SAT: e,
              });
            }}
          >
            <Select.Option value="required">Required</Select.Option>
            <Select.Option value="maybe required">Maybe Required</Select.Option>
            <Select.Option value="not required">Not Required</Select.Option>
          </Select>
        </div>
        <div style={styles.flexColumn} className="university-form-1">
          <div style={styles.inputLabel}>TOEFL</div>
          <Select
            defaultValue="required"
            value={generalState.TOEFL}
            onChange={(e) => {
              setGeneralState({
                ...generalState,
                TOEFL: e,
              });
            }}
          >
            <Select.Option value="required">Required</Select.Option>
            <Select.Option value="maybe required">Maybe Required</Select.Option>
            <Select.Option value="not required">Not Required</Select.Option>
          </Select>
        </div>
        <div style={styles.flexColumn} className="university-form-1">
          <div style={styles.inputLabel}>IELTS</div>
          <Select
            defaultValue="required"
            value={generalState.IELTS}
            onChange={(e) => {
              setGeneralState({
                ...generalState,
                IELTS: e,
              });
            }}
          >
            <Select.Option value="required">Required</Select.Option>
            <Select.Option value="maybe required">Maybe Required</Select.Option>
            <Select.Option value="not required">Not Required</Select.Option>
          </Select>
        </div>
      </div>

      <div style={{ marginTop: "20px" }}>
        <Text.Heading
          fontSize={"16px"}
          fontWeight={"500"}
          color={Colors.Danger}
          text={error}
        />
      </div>
      <div style={styles.flexColumnCenter}>
        <Button.Primary
          style={styles.buttonStyle}
          onClick={handleNext}
          disabled={saveButtonDisabled}
        >
          Save/Continue
        </Button.Primary>
      </div>
      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => setShowModal(false)}
          closeIcon={true}
          className="ant-modal"
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={styles.modalIcon}
            ></Lottie>
            <div style={styles.modalText}>Successful!</div>
            <Button.Primary
              style={styles.modalButtonStyle}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Done
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
    </div>
  );
}
export default General;
