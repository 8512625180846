import { Colors } from "../../config";

const styles = {
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  justifyRightAlignCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
  },

  textfieldStyle: {
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    padding: "7px",
    width: "100%",
    fontSize: "16px",
    fontWeight: "400",
    color: Colors.BlackText,
  },
  labelStyle: {
    textAlign: "left",
    color: Colors.BlackText,
    fontWeight: "400",
    fontSize: "16px",
  },
  textareaStyle: {
    borderRadius: "6px",
    padding: "7px",
    width: "100%",
    fontSize: "16px",
    fontWeight: "400",
    color: Colors.BlackText,
  },

  addButton: {
    backgroundColor: Colors.Primary,
    fontWeight: "400",
    fontSize: "14px",
    width: "250px",
    borderRadius: "10px",
  },
  selectField: {
    color: Colors.Primary,
    width: "100%",
    borderColor: Colors.Primary,
    borderRadius: "10px",
    textTransform: "capitalize",
  },
  datepicker: {
    width: "100%",
    fontSize: "16px",
    fontWeight: "400",
    color: Colors.BlackText,
    height: "45px",
    borderColor: Colors.Primary,
    borderRadius: "7px",
    padding: "10px",
  },
  submitButton: {
    width: "200px",
    backgroundColor: Colors.Primary,
    fontWeight: "500",
    fontSize: "15px",
  },
};

export default styles;
