import {
  CHANGE_APPLICATION_STATUS,
  CHANGE_APPLICATION_STATUS_FAILURE,
  CHANGE_APPLICATION_STATUS_SUCCESS,
  GET_APPLICATIONS,
  GET_APPLICATIONS_FAILURE,
  GET_APPLICATIONS_SUCCESS,
} from '../constants';

export default class ApplicationAction {
  static getApplications(payload, cb) {
    return {
      type: GET_APPLICATIONS,
      payload,
      cb,
    };
  }
  static getApplicationsSuccess(payload) {
    return {
      type: GET_APPLICATIONS_SUCCESS,
      payload,
    };
  }
  static getApplicationsFailure(payload) {
    return {
      type: GET_APPLICATIONS_FAILURE,
      payload,
    };
  }
  static changeApplicationStatus(id, status, cb) {
    return {
      type: CHANGE_APPLICATION_STATUS,
      id,
      status,
      cb,
    };
  }
  static changeApplicationStatusSuccess(payload) {
    return {
      type: CHANGE_APPLICATION_STATUS_SUCCESS,
      payload,
    };
  }
  static changeApplicationStatusFailure(payload) {
    return {
      type: CHANGE_APPLICATION_STATUS_FAILURE,
      payload,
    };
  }
}
