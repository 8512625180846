import {
  EDIT_BILLING,
  EDIT_BILLING_SUCCESS,
  EDIT_BILLING_FAILURE,
  GET_BILLING,
  GET_BILLING_FAILURE,
  GET_BILLING_SUCCESS,
  GET_ALL_BILLING,
  GET_ALL_BILLING_FAILURE,
  GET_ALL_BILLING_SUCCESS,
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  UPDATE_BILLING,
  UPDATE_BILLING_FAILURE,
  UPDATE_BILLING_SUCCESS,
  UPLOAD_INVOICE,
  UPLOAD_INVOICE_SUCCESS,
  UPLOAD_INVOICE_FAILURE,
} from "../constants";

export default class BillingAction {
  static editBilling(payload, cb) {
    return {
      type: EDIT_BILLING,
      payload,
      cb,
    };
  }
  static editBillingSuccess(payload) {
    return {
      type: EDIT_BILLING_SUCCESS,
      payload,
    };
  }
  static editBillingFailure(payload) {
    return {
      type: EDIT_BILLING_FAILURE,
      payload,
    };
  }
  static getBilling(payload, cb) {
    return {
      type: GET_BILLING,
      payload,
      cb,
    };
  }
  static getBillingsSuccess(payload) {
    return {
      type: GET_BILLING_SUCCESS,
      payload,
    };
  }
  static getBillingFailure(payload) {
    return {
      type: GET_BILLING_FAILURE,
      payload,
    };
  }
  static getInvoices(payload, cb) {
    return {
      type: GET_INVOICES,
      payload,
      cb,
    };
  }
  static getInvoicesSuccess(payload) {
    return {
      type: GET_INVOICES_SUCCESS,
      payload,
    };
  }
  static getInvoicesFailure(payload) {
    return {
      type: GET_INVOICES_FAILURE,
      payload,
    };
  }
  static getAllBilling(payload, cb) {
    return {
      type: GET_ALL_BILLING,
      payload,
      cb,
    };
  }
  static getAllBillingsSuccess(payload) {
    return {
      type: GET_ALL_BILLING_SUCCESS,
      payload,
    };
  }
  static getAllBillingFailure(payload) {
    return {
      type: GET_ALL_BILLING_FAILURE,
      payload,
    };
  }
  static updateBilling(payload, cb) {
    return {
      type: UPDATE_BILLING,
      payload,
      cb,
    };
  }
  static updateBillingSuccess(payload) {
    return {
      type: UPDATE_BILLING_SUCCESS,
      payload,
    };
  }
  static updateBillingFailure(payload) {
    return {
      type: UPDATE_BILLING_FAILURE,
      payload,
    };
  }
  static uploadInvoice(payload, cb) {
    return {
      type: UPLOAD_INVOICE,
      payload,
      cb,
    };
  }
  static uploadInvoiceSuccess(payload) {
    return {
      type: UPLOAD_INVOICE_SUCCESS,
      payload,
    };
  }
  static uploadInvoiceFailure(payload) {
    return {
      type: UPLOAD_INVOICE_FAILURE,
      payload,
    };
  }
}
