import {
  ADD_ANNOUNCEMENT,
  ADD_ANNOUNCEMENT_FAILURE,
  ADD_ANNOUNCEMENT_SUCCESS,
  CHANGE_ANNOUNCEMENT_STATUS,
  CHANGE_ANNOUNCEMENT_STATUS_FAILURE,
  CHANGE_ANNOUNCEMENT_STATUS_SUCCESS,
  GET_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS_FAILURE,
  GET_ANNOUNCEMENTS_SUCCESS,
} from '../constants';

export default class AnnouncementAction {
  static addAnnouncement(payload, cb) {
    return {
      type: ADD_ANNOUNCEMENT,
      payload,
      cb,
    };
  }
  static addAnnouncementSuccess(payload) {
    return {
      type: ADD_ANNOUNCEMENT_SUCCESS,
      payload,
    };
  }
  static addAnnouncementFailure(payload) {
    return {
      type: ADD_ANNOUNCEMENT_FAILURE,
      payload,
    };
  }
  static getAnnouncements(payload, cb) {
    return {
      type: GET_ANNOUNCEMENTS,
      payload,
      cb,
    };
  }
  static getAnnouncementsSuccess(payload) {
    return {
      type: GET_ANNOUNCEMENTS_SUCCESS,
      payload,
    };
  }
  static getAnnouncementsFailure(payload) {
    return {
      type: GET_ANNOUNCEMENTS_FAILURE,
      payload,
    };
  }
  static changeAnnouncementStatus(payload, cb) {
    return {
      type: CHANGE_ANNOUNCEMENT_STATUS,
      payload,
      cb,
    };
  }
  static changeAnnouncementStatusSuccess(payload) {
    return {
      type: CHANGE_ANNOUNCEMENT_STATUS_SUCCESS,
      payload,
    };
  }
  static changeAnnouncementStatusFailure(payload) {
    return {
      type: CHANGE_ANNOUNCEMENT_STATUS_FAILURE,
      payload,
    };
  }
}
