import { put } from "redux-saga/effects";

import { BillingAction } from "../actions";
import { ApiCaller } from "../../config";

export default class BillingMiddleware {
  static *editBilling({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/billing/editBillingInfo`, payload);
      if (res.status === 200) {
        yield put(BillingAction.editBillingSuccess(res.data.data));
      } else {
        yield put(BillingAction.editBillingFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(BillingAction.editBillingFailure());
    }
  }
  static *getBilling({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/billing/getSpecificBilling`, payload);
      if (res.status === 200) {
        yield put(BillingAction.getBillingsSuccess(res.data.data));
      } else {
        yield put(BillingAction.getBillingFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(BillingAction.getBillingFailure());
    }
  }
  static *getInvoices({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/billing/getInvoices`, payload);

      if (res.status === 200) {
        yield put(BillingAction.getInvoicesSuccess(res.data.data));
      } else {
        yield put(BillingAction.getInvoicesFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(BillingAction.getInvoicesFailure());
    }
  }
  static *getAllBilling({ cb }) {
    try {
      const res = yield ApiCaller.Post(`/billing/getBilling`);

      if (res.status === 200) {
        yield put(BillingAction.getAllBillingsSuccess(res.data.data));
      } else {
        yield put(BillingAction.getAllBillingFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(BillingAction.getAllBillingFailure());
    }
  }
  static *updateBilling({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/billing/updateBilling`, payload);
      if (res.status === 200) {
        yield put(BillingAction.updateBillingSuccess(res.data.data));
      } else {
        yield put(BillingAction.updateBillingFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(BillingAction.updateBillingFailure());
    }
  }
  static *uploadInvoice({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(`/billing/uploadInvoice`, payload);
      if (res.status === 200) {
        yield put(BillingAction.uploadInvoiceSuccess(res.data.data));
      } else {
        yield put(BillingAction.uploadInvoiceFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(BillingAction.uploadInvoiceFailure());
    }
  }
}
