import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";

import { AiOutlineSearch } from "react-icons/ai";

import { TrackerAction } from "../../store/actions";

import {
  Button,
  Layout,
  Text,
  Loader,
  Table,
  TextField,
} from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

function Tracker() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tracker = useSelector((state) => state.tracker);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const payload = {
      limit,
      offset,
      search,
    };
    dispatch(TrackerAction.getAllTracker(payload));
  }, [search]);

  const handleChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * limit;

    const payload = {
      limit,
      offset,
      search,
    };
    dispatch(TrackerAction.getAllTracker(payload));
    setOffset(offset);
    setPage(pageNumber);
  };

  return (
    <div>
      <div style={{ width: "100%" }}>
        <TextField.CustomInput
          placeholder="Search Tracker"
          suffix={<AiOutlineSearch style={styles.searchIcon} />}
          value={search}
          onChange={handleChange}
          style={styles.searchInput}
        />
      </div>
      <Layout.Spacer height={20} />
      <div style={styles.flexRowSpace}>
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="Application Status Tracker"
        />

        <Button.Primary
          style={styles.buttonStyle}
          onClick={() => navigate("/tracker/addTracker")}
        >
          Add
        </Button.Primary>
      </div>
      <Layout.Spacer height={20} />
      {tracker?.isLoading ? (
        <Loader.Circular />
      ) : tracker?.trackers?.length > 0 ? (
        <>
          {tracker?.trackers?.map((tracker) => (
            <Table.CustomRowTable
              key={tracker._id}
              tracker={tracker}
              handleClick={() => navigate(`/tracker/${tracker._id}`)}
            />
          ))}
          <div style={styles.flexRowCenter}>
            <Pagination
              responsive="true"
              showSizeChanger={false}
              hideOnSinglePage={true}
              current={page}
              pageSize={10}
              total={tracker?.trackerCount}
              onChange={handlePaginationChange}
            />
          </div>
        </>
      ) : (
        <div style={{ ...styles.flexCenter, color: "black" }}>
          No Tracker Data Found
        </div>
      )}
    </div>
  );
}

export default Tracker;
