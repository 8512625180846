const Currencies = [
  { currency: "USD", amount: 1 },
  { currency: "EUR", amount: 1.1 },
  { currency: "GBP", amount: 1.27 },
  { currency: "AUD", amount: 0.66 },
  { currency: "PKR", amount: 0.0036 },
  { currency: "SGD", amount: 0.75 },
  { currency: "CAD", amount: 0.75 },
  { currency: "CHF", amount: 1.15 },
  { currency: "HKD", amount: 0.13 },
  { currency: "MYR", amount: 0.22 },
  { currency: "SEK", amount: 0.094 },
  { currency: "DKK", amount: 0.15 },
  { currency: "ANG", amount: 0.56 },
  { currency: "AED", amount: 0.27 },
  { currency: "RUB", amount: 0.01 },
  { currency: "QAR", amount: 0.27 },
  { currency: "LBP", amount: 0.000067 },
  { currency: "ILS", amount: 0.27 },
  { currency: "SAR", amount: 0.27 },
  { currency: "CZK", amount: 0.045 },
  { currency: "TRY", amount: 0.037 },
  { currency: "BHD", amount: 2.68 },
  { currency: "KWD", amount: 3.28 },
  { currency: "PLN", amount: 0.25 },
  { currency: "JOD", amount: 1.41 },
  { currency: "AZN", amount: 0.59 },
  { currency: "RON", amount: 0.22 },
  { currency: "RM", amount: 0.22 },
  { currency: "ARS", amount: 0.0029 },
  { currency: "RMB", amount: 0.14 },
  { currency: "CNY", amount: 0.14 },
  { currency: "JPY", amount: 0.0068 },
  { currency: "KRW", amount: 0.00075 },
  { currency: "ITL", amount: 0.00055 },
  { currency: "DEM", amount: 0.547099 },
  { currency: "ARS", amount: 0.0029 },
  { currency: "AMD", amount: 0.0026 },
  { currency: "BDT", amount: 0.0091 },
  { currency: "NZD", amount: 0.59 },
  { currency: "BRL", amount: 0.21 },
  { currency: "IRR", amount: 0.000024 },
  { currency: "IQD", amount: 0.00077 },
  { currency: "IDR", amount: 0.000065 },
  { currency: "TWD", amount: 0.031 },
  { currency: "NTD", amount: 0.031 },
  { currency: "MOP", amount: 0.12 },
  { currency: "LE", amount: 0.032 },
  { currency: "EGP", amount: 0.032 },
  { currency: "MXN", amount: 0.059 },
  { currency: "BND", amount: 0.75 },
  { currency: "BRL", amount: 0.2 },
  { currency: "CLP", amount: 0.001 },
  { currency: "COP", amount: 0.00026 },
  { currency: "THB", amount: 0.028 },
  { currency: "CRC", amount: 0.002 },
  { currency: "CUC", amount: 1 },
  { currency: "RD", amount: 0.017 },
  { currency: "DOP", amount: 0.017 },
  { currency: "EEK", amount: 0.069 },
  { currency: "INR", amount: 0.012 },
  { currency: "PHP", amount: 0.018 },
  { currency: "PEN", amount: 0.27 },
  { currency: "OMR", amount: 2.61 },
  { currency: "DHS", amount: 0.27 },
  { currency: "ZAR", amount: 0.054 },
  { currency: "LKR", amount: 0.0033 },
  { currency: "SDG", amount: 0.0017 },
  { currency: "KZT", amount: 0.0022 },
  { currency: "UGX", amount: 0.00026 },
  { currency: "VEF", amount: 0.00000027 },
  { currency: "VES", amount: 0.02753 },
  { currency: "VND", amount: 0.00004 },
  { currency: "KES", amount: 0.0076 },
];

export default Currencies;
