import { useSelector } from "react-redux";

import { IoCheckmarkCircleSharp } from "react-icons/io5";

import { Images, Colors } from "../../config";
import { Layout, convertStringToUSD } from "../../components";

import styles from "./styles";

function StudentDetails({ data }) {
  const currency = useSelector((state) => state.converter.usd);

  const defaultImage = Images.DefaultRestaurant;

  const costsTitles = [
    { id: 1, image: Images.Accommodation, title: "Acommodation" },
    { id: 2, image: Images.Food, title: "Food" },
    { id: 3, image: Images.Others, title: "Others" },
  ];

  const costValuesOncampus = [
    {
      id: 1,
      value: data?.onCampus?.averageMonthlyAccommodationCost
        ? data?.onCampus?.averageMonthlyAccommodationCost.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "NA",
      valueUSD: convertStringToUSD(
        data?.onCampus?.averageMonthlyAccommodationCost
      ),
    },
    {
      id: 2,
      value: data?.onCampus?.averageMonthlyFoodCost
        ? data?.onCampus?.averageMonthlyFoodCost.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "NA",
      valueUSD: convertStringToUSD(data?.onCampus?.averageMonthlyFoodCost),
    },
    {
      id: 3,
      value: data?.onCampus?.averageMonthlyOthersCost
        ? data?.onCampus?.averageMonthlyOthersCost.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "NA",
      valueUSD: convertStringToUSD(data?.onCampus?.averageMonthlyOthersCost),
    },
  ];
  const costValuesOffcampus = [
    {
      id: 1,
      value: data?.offCampus?.averageMonthlyAccommodationCost
        ? data?.offCampus?.averageMonthlyAccommodationCost.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "NA",
      valueUSD: convertStringToUSD(
        data?.offCampus?.averageMonthlyAccommodationCost
      ),
    },
    {
      id: 2,
      value: data?.offCampus?.averageMonthlyFoodCost
        ? data?.offCampus?.averageMonthlyFoodCost.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "NA",
      valueUSD: convertStringToUSD(data?.offCampus?.averageMonthlyFoodCost),
    },
    {
      id: 3,
      value: data?.offCampus?.averageMonthlyOthersCost
        ? data?.offCampus?.averageMonthlyOthersCost.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "NA",
      valueUSD: convertStringToUSD(data?.offCampus?.averageMonthlyOthersCost),
    },
  ];
  const restaurants = [
    {
      id: 1,
      image:
        data.restaurants &&
        data.restaurants.restaurantOneImage &&
        data.restaurants.restaurantOneImage !== "NA"
          ? data.restaurants.restaurantOneImage
          : defaultImage,
      name:
        data.restaurants && data.restaurants.restaurantOne
          ? data.restaurants.restaurantOne.toUpperCase()
          : "-",
      distance:
        data.restaurants && data.restaurants.restaurantOneLocation
          ? data.restaurants.restaurantOneLocation.includes("km")
            ? `${data.restaurants.restaurantOneLocation} away`
            : `${data.restaurants.restaurantOneLocation} km away`
          : "-",
    },
    {
      id: 2,
      image:
        data.restaurants &&
        data.restaurants.restaurantTwoImage &&
        data.restaurants.restaurantTwoImage !== "NA"
          ? data.restaurants.restaurantTwoImage
          : defaultImage,
      name:
        data.restaurants && data.restaurants.restaurantTwo
          ? data.restaurants.restaurantTwo.toUpperCase()
          : "-",
      distance:
        data.restaurants && data.restaurants.restaurantTwoLocation
          ? data.restaurants.restaurantTwoLocation.includes("km")
            ? `${data.restaurants.restaurantTwoLocation} away`
            : `${data.restaurants.restaurantTwoLocation} km away`
          : "-",
    },
    {
      id: 3,
      image:
        data.restaurants &&
        data.restaurants.restaurantThreeImage &&
        data.restaurants.restaurantThreeImage !== "NA"
          ? data.restaurants.restaurantThreeImage
          : defaultImage,
      name:
        data.restaurants && data.restaurants.restaurantThree
          ? data.restaurants.restaurantThree.toUpperCase()
          : "-",
      distance:
        data.restaurants && data.restaurants.restaurantThreeLocation
          ? data.restaurants.restaurantThreeLocation.includes("km")
            ? `${data.restaurants.restaurantThreeLocation} away`
            : `${data.restaurants.restaurantThreeLocation} km away`
          : "-",
    },
    {
      id: 4,
      image:
        data.restaurants &&
        data.restaurants.restaurantFourImage &&
        data.restaurants.restaurantFourImage !== "NA"
          ? data.restaurants.restaurantFourImage
          : defaultImage,
      name:
        data.restaurants && data.restaurants.restaurantFour
          ? data.restaurants.restaurantFour.toUpperCase()
          : "-",
      distance:
        data.restaurants && data.restaurants.restaurantFourLocation
          ? data.restaurants.restaurantFourLocation.includes("km")
            ? `${data.restaurants.restaurantFourLocation} away`
            : `${data.restaurants.restaurantFourLocation} km away`
          : "-",
    },
    {
      id: 5,
      image:
        data.restaurants &&
        data.restaurants.restaurantFiveImage &&
        data.restaurants.restaurantFiveImage !== "NA"
          ? data.restaurants.restaurantFiveImage
          : defaultImage,
      name:
        data.restaurants && data.restaurants.restaurantFive
          ? data.restaurants.restaurantFive.toUpperCase()
          : "-",
      distance:
        data.restaurants && data.restaurants.restaurantFiveLocation
          ? data.restaurants.restaurantFiveLocation.includes("km")
            ? `${data.restaurants.restaurantFiveLocation} away`
            : `${data.restaurants.restaurantFiveLocation} km away`
          : "-",
    },
  ];

  const transport = [
    {
      id: 1,
      image: Images.Metro,
      name: "Metro",
      distance: data.trainDistance ? `${data.trainDistance} away` : "NA",
    },
    {
      id: 2,
      image: Images.Bus2,
      name: "Bus",
      distance: data.busDistance ? `${data.busDistance} away` : "NA",
    },
  ];
  return (
    <div>
      <div>
        <p style={styles.uniDetailsHeader}>Average Monthly Cost</p>
        <div style={styles.avgMonthlyCost}>
          <div
            style={{
              width: "100%",
              paddingLeft: "30px",
              borderRight: "1px solid #98989880",
            }}
          >
            <p
              style={{
                ...styles.uniDetailsValue,
                fontSize: "18px",
                paddingLeft: "20px",
              }}
            >
              On Campus
            </p>
            <div style={{ display: "flex" }}>
              <div style={styles.costsTitleContainer}>
                {costsTitles?.map((item) => (
                  <div style={{ marginBottom: "12px" }} key={item.id}>
                    <span>
                      <img
                        src={item.image}
                        style={{
                          ...styles.uniDetailsIcon,
                          height: "20px",
                          width: "20px",
                          marginBottom: "10px",
                        }}
                        alt=""
                      />
                    </span>
                    <span style={styles.costsTitle}>{item.title}</span>
                  </div>
                ))}
              </div>

              <div style={styles.costValuesContainer}>
                {costValuesOncampus?.map((item) => (
                  <div style={{ textAlign: "center" }} key={item.id}>
                    <p
                      style={{
                        ...styles.uniDetailsValue,
                        fontSize: "16px",
                      }}
                    >
                      {!currency ? item.value : item.valueUSD}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              paddingLeft: "30px",
            }}
          >
            <p
              style={{
                ...styles.uniDetailsValue,
                fontSize: "18px",
                paddingLeft: "20px",
              }}
            >
              Off Campus
            </p>
            <div style={{ display: "flex" }}>
              <div style={styles.costsTitleContainer}>
                {costsTitles?.map((item) => (
                  <div style={{ marginBottom: "12px" }} key={item.id}>
                    <span>
                      <img
                        src={item.image}
                        style={{
                          ...styles.uniDetailsIcon,
                          height: "20px",
                          width: "20px",
                          marginBottom: "10px",
                        }}
                        alt=""
                      />
                    </span>
                    <span style={styles.costsTitle}>{item.title}</span>
                  </div>
                ))}
              </div>
              <div style={styles.costValuesContainer}>
                {costValuesOffcampus?.map((item) => (
                  <div style={{ textAlign: "center" }} key={item.id}>
                    <p
                      style={{
                        ...styles.uniDetailsValue,
                        fontSize: "16px",
                      }}
                    >
                      {!currency ? item.value : item.valueUSD}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Layout.Spacer height={30} />
      <p style={styles.uniDetailsHeader}>Facilities Available</p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={styles.facilitiesAvailable}>
          <div>
            <img src={Images.Facilities} height={50} width={50} alt="" />
          </div>
          <div style={styles.facilitiesAvailableTextContainer}>
            <p
              style={{
                ...styles.uniDetailsValue,
                fontSize: "16px",
                marginBottom: "5px",
              }}
            >
              On Campus Facilities
            </p>
            <p style={{ fontSize: "12px", fontWeight: "500" }}>
              Click on the link below to explore the{" "}
              <span style={{ color: Colors.Primary }}>
                On Campus Facilities!
              </span>
            </p>
            <a
              href={data.facilitiesAvailable ? data.facilitiesAvailable : "#"}
              style={styles.moreDetailsButton}
              target="_blank"
            >
              More Details
            </a>
          </div>
        </div>
        <div style={styles.restaurantSection}>
          <div style={styles.requirementsContainer}>
            <p
              style={{
                ...styles.uniDetailsValue,
                fontSize: "16px",
              }}
            >
              Nearby Food Availibility
            </p>
            <p
              style={{
                background:
                  data.halalFood === "Easy" || data.halalFood === "easy"
                    ? "#56F06F"
                    : data.halalFood === "Medium" || data.halalFood === "medium"
                    ? "#FFD12C"
                    : data.halalFood === "Hard" || data.halalFood === "hard"
                    ? "#FA2C44"
                    : "white",
                color: data.halalFood === "Hard" ? "white" : "Black",
                ...styles.halalFood,
              }}
            >
              Halal Food Availibility: {data.halalFood ? data.halalFood : "NA"}
            </p>
          </div>
          {data.restaurants ? (
            <div style={styles.flexSpaceBetween}>
              {restaurants.map((restaurant) => (
                <div
                  style={styles.restaurantDetailsContainer}
                  key={restaurant.id}
                >
                  <img
                    src={restaurant.image}
                    style={styles.restaurantImage}
                    alt=""
                  />
                  <p style={styles.restaurantName}>{restaurant.name}</p>
                  <p style={styles.restaurantDistance}>{restaurant.distance}</p>
                </div>
              ))}
            </div>
          ) : (
            <div style={styles.noRestaurantData}>
              <img src={Images.NoData} height={68} width={68} alt="" />
              <div style={{ fontSize: "24px", fontWeight: "700" }}>No Data</div>
            </div>
          )}
        </div>
      </div>
      <Layout.Spacer height={30} />

      <div style={styles.flexSpaceBetween}>
        <div
          style={{
            flexDirection: "column",
            width: "100%",
            marginRight: "25px",
          }}
        >
          <p style={styles.uniDetailsHeader}>Public Transport Option</p>
          <div style={styles.transportContainer}>
            {transport.map((item) => (
              <div style={{ textAlign: "center" }} key={item.id}>
                <div>
                  <img src={item.image} height={50} width={50} alt="" />
                </div>
                <div
                  style={{
                    color: Colors.Primary,
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  {item.name}
                </div>
                <p style={{ fontSize: "14px", fontWeight: "500" }}>
                  {item.distance}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div style={{ flexDirection: "column", width: "100%" }}>
          <p style={styles.uniDetailsHeader}>Study Aborad Program</p>
          <div style={styles.studyAbroadContainer}>
            <div style={styles.studyAbroadDetails}>
              <div>
                <img src={Images.Exchange1} height={50} width={50} alt="" />
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                <p
                  style={{
                    ...styles.uniDetailsValue,
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  <IoCheckmarkCircleSharp style={styles.greenTick} />
                  Exchange Universities
                </p>
                <p style={{ fontSize: "12px", fontWeight: "500" }}>
                  {`Click on the link below to check out all available `}
                  <span style={{ color: Colors.Primary }}>
                    Study Abroad Partners!
                  </span>
                </p>
                <a
                  href={
                    data.exchangeUniversities ? data.exchangeUniversities : "#"
                  }
                  style={styles.moreDetailsButton}
                  target="_blank"
                >
                  More Details
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentDetails;
