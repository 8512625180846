import { Outlet } from "react-router-dom";
import { Layout } from "antd";
const { Header, Sider } = Layout;

import NavHeader from "./NavHeader.jsx";
import SideBar from "./Sidebar.jsx";

import styles from "./styles.js";
import "./dashboardLayout.css";

const DashboardLayout = ({ children }) => {
  return (
    <Layout className="dashboard-layout">
      <Sider trigger={null} className="dashboard-sidebar" width={265}>
        <SideBar className="siderbar" />
      </Sider>
      <Layout className="dashboard-main">
        <Header className="dashboard-header">
          <NavHeader />
        </Header>
        <div className="dashboard-main-content">
          <Outlet />
          {children}
        </div>
        <div style={styles.bottomTextContainer}>
          <div>
            *All facts and figures are based on market research from the most
            updated data sources available
          </div>
        </div>
      </Layout>
    </Layout>
  );
};
export default DashboardLayout;
