import { Colors } from "../../config";

const styles = {
  // common
  customTextfieldStyle: {
    borderRadius: "10px",
    border: `1px solid ${Colors.Primary}`,
    padding: "10px",
    width: "100%",
    fontSize: "16px",
    fontWeight: "500",
    color: "#081C15",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  justifyBetweenAlignCenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexColumnCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  mr5: {
    marginRight: "5px",
  },

  // university page
  filterButton: {
    width: "45px",
    height: "45px",
    padding: "6px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.Primary,
  },
  filterDropdown: {
    position: "absolute",
    zIndex: "100",
    backgroundColor: Colors.Primary,
    color: "white",
    fontSize: "12px",
    height: "80px",
    width: "150px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    marginTop: "5px",
    left: "-70px",
    animation: "slide-down 0.3s ease",
  },
  filterDropdownValue: {
    cursor: "pointer",
    borderBottom: "1px solid white",
    marginBottom: "5px",
    paddingBottom: "5px",
  },
  addUniversityButton: {
    width: "100%",
    borderRadius: "10px",
    fontSize: "14px",
    color: Colors.White,
  },
  filterRegion: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "110px",
    height: "30px",
    margin: "0px 8px",
    fontSize: "11px",
    fontWeight: "500",
    borderRadius: "5px",
  },
  filterRegionText: {
    marginRight: "30px",
    letterSpacing: "1px",
    fontSize: "16px",
    fontWeight: "500",
    color: Colors.Primary,
  },
  universityTableText: {
    fontSize: "12px",
    fontWeight: "500",
    color: Colors.BlackText,
  },
  universityActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusTitle: { marginRight: "80px", fontWeight: "500", fontSize: "12px" },
  editIcon: {
    cursor: "pointer",
    marginRight: "5px",
    marginTop: "1px",
    height: "26px",
    width: "26px",
  },

  // upload uni modal
  uploadUniTitle: {
    fontSize: "26px",
    color: Colors.Primary,
    fontWeight: "600",
  },
};

export default styles;
