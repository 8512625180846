import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { ApplicationAction } from "../actions";

export default class ApplicationMiddleware {
  static *getApplications({ payload, cb }) {
    try {
      const { limit, offset } = payload;

      const res = yield ApiCaller.Get(
        `/school/fetch-applications-individual?limit=${limit}&offset=${offset}`
      );

      if (res.status === 200) {
        yield put(ApplicationAction.getApplicationsSuccess(res.data.data));
      } else {
        yield put(ApplicationAction.getApplicationsSuccess([]));
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(ApplicationAction.getApplicationsFailure());
    }
  }

  static *changeApplicationStatus({ id, status, cb }) {
    try {
      const res = yield ApiCaller.Get(
        `/school/change-application-status/${id}?status=${status}`
      );
      if (res.status === 200) {
        yield put(
          ApplicationAction.changeApplicationStatusSuccess(res.data.data)
        );
      } else {
        yield put(ApplicationAction.changeApplicationStatusFailure());
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(ApplicationAction.changeApplicationStatusFailure());
    }
  }
}
