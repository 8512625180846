import {
  GET_ALL_TRACKER,
  GET_ALL_TRACKER_SUCCESS,
  GET_ALL_TRACKER_FAILURE,
  GET_TRACKER,
  GET_TRACKER_SUCCESS,
  GET_TRACKER_FAILURE,
  ADD_TRACKER,
  ADD_TRACKER_SUCCESS,
  ADD_TRACKER_FAILURE,
  UPDATE_TRACKER,
  UPDATE_TRACKER_SUCCESS,
  UPDATE_TRACKER_FAILURE,
  DELETE_TRACKER,
  DELETE_TRACKER_SUCCESS,
  DELETE_TRACKER_FAILURE,
} from "../constants";

export default class TrackerAction {
  static addTracker(payload, cb) {
    return {
      type: ADD_TRACKER,
      payload,
      cb,
    };
  }
  static addTrackerSuccess(payload) {
    return {
      type: ADD_TRACKER_SUCCESS,
      payload,
    };
  }
  static addTrackerFailure(payload) {
    return {
      type: ADD_TRACKER_FAILURE,
      payload,
    };
  }
  static getTracker(payload, cb) {
    return {
      type: GET_TRACKER,
      payload,
      cb,
    };
  }
  static getTrackersSuccess(payload) {
    return {
      type: GET_TRACKER_SUCCESS,
      payload,
    };
  }
  static getTrackerFailure(payload) {
    return {
      type: GET_TRACKER_FAILURE,
      payload,
    };
  }
  static getAllTracker(payload, cb) {
    return {
      type: GET_ALL_TRACKER,
      payload,
      cb,
    };
  }
  static getAllTrackersSuccess(payload) {
    return {
      type: GET_ALL_TRACKER_SUCCESS,
      payload,
    };
  }
  static getAllTrackerFailure(payload) {
    return {
      type: GET_ALL_TRACKER_FAILURE,
      payload,
    };
  }
  static updateTracker(payload, cb) {
    return {
      type: UPDATE_TRACKER,
      payload,
      cb,
    };
  }
  static updateTrackerSuccess(payload) {
    return {
      type: UPDATE_TRACKER_SUCCESS,
      payload,
    };
  }
  static updateTrackerFailure(payload) {
    return {
      type: UPDATE_TRACKER_FAILURE,
      payload,
    };
  }
  static deleteTracker(payload, cb) {
    return {
      type: DELETE_TRACKER,
      payload,
      cb,
    };
  }
  static deleteTrackerSuccess(payload) {
    return {
      type: DELETE_TRACKER_SUCCESS,
      payload,
    };
  }
  static deleteTrackerFailure(payload) {
    return {
      type: DELETE_TRACKER_FAILURE,
      payload,
    };
  }
}
