import { Colors } from "../../config";

const styles = {
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },

  grid: { display: "grid", gridTemplateColumns: "1fr 1fr" },

  flexRowStart: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },

  iconRowDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
  },

  applicationDivContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridColumnGap: "30px",
  },
  applicationDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "130px",
    width: "100%",
    background: Colors.LightGray,
    borderRadius: "30px",
    boxShadow: "0px 6px 18px -4px rgba(0,0,0,0.4)",
    paddingTop: "10px",
    cursor: "pointer",
  },

  tableText: {
    fontSize: "12px",
    fontWeight: "600",
    color: Colors.BlackText,
    textTransform: "capitalize",
  },
  modalButton: { width: "100%", fontWeight: "500", fontSize: "15px" },

  modalCard: {
    border: `1px dashed ${Colors.Primary}`,
    padding: "25px 10px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default styles;
