import Currency from "./currencies";
import CountryListAllIsoData from "./countryList";
import Currencies from "./converter";

const MockData = {
  Currencies,
  CountryListAllIsoData,
  Currency,
};

export default MockData;
