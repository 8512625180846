import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { SchoolAction } from "../actions";

export default class SchoolMiddleware {
  static *getSchools({ payload, cb }) {
    try {
      const { search, limit, offset } = payload;
      const res = yield ApiCaller.Get(
        `/school/search-school?searchInput=${search}&limit=${limit}&offset=${offset}`
      );
      if (res.status === 200) {
        yield put(SchoolAction.getSchoolsSuccess(res.data.data));
      } else {
        yield put(SchoolAction.getSchoolsFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(SchoolAction.getSchoolsFailure());
    }
  }

  static *getSchoolDetails({ id, cb }) {
    try {
      const res = yield ApiCaller.Get(`/school/get-schoolDetails/${id}`);
      if (res.status === 200) {
        yield put(SchoolAction.getSchoolDetailsSuccess(res.data.data));
      } else {
        yield put(SchoolAction.getSchoolDetailsFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(SchoolAction.getSchoolDetailsFailure());
    }
  }

  static *addSchool({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/school/add-school", payload);
      if (res.status === 201) {
        yield put(SchoolAction.addSchoolSuccess(res.data.data));
        Utils.showSnackBar({ message: res.data.message });
      } else {
        yield put(SchoolAction.addSchoolFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(SchoolAction.addSchoolFailure());
    }
  }

  static *changeSchoolStatus({ payload, cb }) {
    try {
      const { id, status } = payload;
      const res = yield ApiCaller.Get(
        `/school/change-school-status/${id}?status=${status}`
      );
      if (res.status === 200) {
        yield put(SchoolAction.changeSchoolStatusSuccess(res.data.data));
      } else {
        yield put(SchoolAction.changeSchoolStatusFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(SchoolAction.changeSchoolStatusFailure());
    }
  }
}
