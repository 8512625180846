import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";

import { AiFillRightCircle, AiOutlineSearch } from "react-icons/ai";
import { MdLocationPin } from "react-icons/md";

import { SchoolAction } from "../../store/actions";

import { Button, Layout, Table, Text, TextField } from "../../components";
import { Colors, Images } from "../../config";

import styles from "./styles";

function Schools() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const school = useSelector((state) => state.school);
  const role = useSelector((state) => state.auth.user.user.role);

  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const payload = {
      limit,
      offset: 0,
      search,
    };
    dispatch(SchoolAction.getSchools(payload));
  }, [search]);

  const handleChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * limit;
    const payload = {
      limit,
      offset,
      search,
    };
    dispatch(SchoolAction.getSchools(payload));
    setOffset(offset);
    setPage(pageNumber);
  };

  const changeSchoolStatus = (id, value) => {
    const body = {
      id: id,
      status: value,
    };
    dispatch(
      SchoolAction.changeSchoolStatus(body, () => {
        const payload = {
          limit,
          offset,
          search,
        };
        dispatch(SchoolAction.getSchools(payload));
      })
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 480,
      onCell: (record) => ({
        onClick: () =>
          record?.status.toLowerCase() != "pending" &&
          navigate(`/schools/${record?._id}`),
      }),
      render: (text, record) => (
        <div style={styles.alignCenter}>
          <img
            src={record?.imageUrl ? record?.imageUrl : Images.Uni1}
            alt="school-img"
            style={styles.imageStyle}
          />
          <div>
            <Text.Heading style={styles.schoolName} text={record?.name} />
            <div>
              <MdLocationPin style={styles.locationIcon} />
              <Text.Heading style={styles.location} text={record.location} />
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "No. of Students",
      dataIndex: "enlistedStudents",
      align: "center",
      onCell: (record) => ({
        onClick: () =>
          record?.status.toLowerCase() != "pending" &&
          navigate(`/schools/${record?._id}`),
      }),
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Registered Students",
      dataIndex: "registeredStudents",
      align: "center",
      onCell: (record) => ({
        onClick: () =>
          record?.status.toLowerCase() != "pending" &&
          navigate(`/schools/${record?._id}`),
      }),
      render: (text, record) => <div>{record?.registeredStudents || "0"}</div>,
    },
  ];

  const superAdminTableData = [
    ...columns,
    {
      title: "Status",
      dataIndex: "action",
      align: "center",
      width: 220,
      render: (text, record) => (
        <div
          className={
            record?.status === "active"
              ? "student-action-activate"
              : "student-action-deactivate"
          }
          style={styles.alignCenterJustifyBetween}
        >
          {record?.status === "pending" ? (
            <div>Pending</div>
          ) : (
            <TextField.SelectField
              placeholder="Select Role"
              value={record?.status || "activate"}
              onChange={(value) => changeSchoolStatus(record?._id, value)}
              data={[
                {
                  label: "Activated",
                  value: "active",
                },
                {
                  label: "Deactivated",
                  value: "banned",
                },
              ]}
            />
          )}
          {record?.status !== "pending" && (
            <AiFillRightCircle
              style={styles.rightIcon}
              onClick={() => navigate(`/schools/${record?._id}`)}
            />
          )}
        </div>
      ),
    },
  ];

  const adminTableData = [
    ...columns,
    {
      title: " ",
      dataIndex: "action",
      align: "center",
      width: 120,
      render: (text, record) => (
        <div>
          {record?.status === "pending" ? (
            <div>Pending</div>
          ) : (
            <div style={styles.flexRow}>
              <img
                src={Images.Edit}
                alt="Edit"
                style={styles.editIcon}
                onClick={() => navigate(`/edit-school/${record._id}`)}
              />
              <AiFillRightCircle
                style={styles.rightIcon}
                onClick={() => navigate(`/schools/${record?._id}`)}
              />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div style={styles.justifyBetween}>
        <div style={{ width: "100%" }}>
          <TextField.CustomInput
            placeholder="Search School"
            suffix={<AiOutlineSearch style={styles.searchIcon} />}
            value={search}
            onChange={handleChange}
            style={styles.searchInput}
          />
        </div>
        {role === "admin" && (
          <Button.Primary
            style={styles.addSchoolButton}
            onClick={() => navigate("/schools/add-school")}
          >
            Add New School
          </Button.Primary>
        )}
      </div>
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Schools"
      />
      <Layout.Spacer height={20} />
      <Table.AntDesign
        rowKey={(record) => record._id}
        columns={role === "admin" ? adminTableData : superAdminTableData}
        data={school.schools}
        loading={school.isLoading}
        pagination={false}
      />
      <Layout.Spacer height={20} />
      <div style={styles.flexCenter}>
        <Pagination
          responsive="true"
          showSizeChanger={false}
          hideOnSinglePage={false}
          current={page}
          pageSize={10}
          total={school.count}
          onChange={handlePaginationChange}
        />
      </div>
    </>
  );
}

export default Schools;
