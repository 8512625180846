import {
  GET_POPULAR_UNIVERSITIES,
  GET_POPULAR_UNIVERSITIES_FAILURE,
  GET_POPULAR_UNIVERSITIES_SUCCESS,
} from "../constants";

export default class PopularUniversityAction {
  static getPopularUniversities(payload, cb) {
    return {
      type: GET_POPULAR_UNIVERSITIES,
      payload,
      cb,
    };
  }
  static getPopularUniversitiesSuccess(payload) {
    return {
      type: GET_POPULAR_UNIVERSITIES_SUCCESS,
      payload,
    };
  }
  static getPopularUniversitiesFailure(payload) {
    return {
      type: GET_POPULAR_UNIVERSITIES_FAILURE,
      payload,
    };
  }
}
