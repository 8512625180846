import { Colors } from "../../config";

const styles = {
  alignCenter: { display: "flex", alignItems: "center" },
  justifyBetween: { display: "flex", justifyContent: "space-between" },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alignCenterJustifyBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  alignCenterJustifyAround: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  buttonStyle: {
    width: "100%",
    height: "50px",
    borderRadius: "6px",
    fontSize: "16px",
    fontWeight: "400",
    color: Colors.WhiteText,
    backgroundColor: Colors.Primary,
  },

  textfieldStyle: {
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    padding: "10px",
    width: "100%",
    fontSize: "16px",
    fontWeight: "400",
    height: "35px",
    color: Colors.BlackText2,
  },
  labelStyle: {
    textAlign: "left",
    color: "black",
    fontSize: "16px",
  },

  searchIcon: {
    fontSize: "24px",
    color: Colors.Primary,
    cursor: "pointer",
  },
  searchInput: {
    borderRadius: "10px",
    border: `1px solid ${Colors.Primary}`,
    padding: "10px",
    fontSize: "14px",
  },
  imageStyle: {
    marginRight: "30px",
    height: "40px",
    width: "40px",
    borderRadius: "100%",
    objectFit: "contain",
    background: "white",
  },
  studentName: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#081C15",
    textTransform: "capitalize",
  },
  subtitle: {
    fontSize: "10px",
    fontWeight: "400",
    color: Colors.GrayText,
    textTransform: "capitalize",
  },

  filterText: {
    marginRight: "30px",
    letterSpacing: "1px",
    fontSize: "16px",
    fontWeight: "500",
    color: Colors.Primary,
  },
  filter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "110px",
    height: "30px",
    margin: "0px 8px",
    fontSize: "11px",
    fontWeight: "500",
    borderRadius: "5px",
  },
  addressInfo: {
    padding: "5px",
    borderRadius: "5px",
    background: Colors.Primary,
    color: "white",
    marginRight: "5px",
    cursor: "pointer",
  },
};

export default styles;
