import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { MdLocationPin } from "react-icons/md";

import { StudentAction } from "../../store/actions";

import { Colors, Images } from "../../config";
import { Layout, Text, Loader } from "../../components";

import Application from "./applications";
import General from "./general";
import Deadlines from "./deadlines";

import styles from "./styles";

function StudentDetails() {
  const location = useLocation();
  const dispatch = useDispatch();

  const id = location.pathname.split("/")[3];

  const studentState = useSelector((state) => state.student);
  const { wishlists, preferredCountries, preferredProgram, studentDetails } =
    useSelector((state) => state.student.studentDetails);

  const [active, setActive] = useState("general");

  useEffect(() => {
    dispatch(StudentAction.getStudentDetails(id));
  }, []);

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Student Information"
      />
      <Layout.Spacer height={40} />
      {studentState.isLoading ? (
        <Loader.Circular />
      ) : (
        <div>
          <div style={styles.flexRowStart}>
            <div
              style={{
                ...styles.flexRowCenter,
                ...styles.profileImageContainer,
              }}
            >
              <img
                src={studentDetails?.profileImage || Images.DummyImage}
                style={styles.studentProfileImage}
                alt="school"
              />
            </div>
            <div style={styles.flexColumn}>
              <Text.Heading
                style={styles.studentName}
                text={studentDetails?.name}
              />
              <Layout.Spacer height={5} />
              <Text.Heading
                style={styles.studentInfo}
                text={`${studentDetails?.gender} | ${studentDetails?.age} Years | Grade ${studentDetails?.grade}`}
              />
              <Layout.Spacer height={5} />
              <div>
                <MdLocationPin
                  color={Colors.GrayText}
                  style={{ marginRight: "5px" }}
                />
                <Text.Heading
                  textTransform="capitalize"
                  fontSize={"16px"}
                  fontWeight={"400"}
                  color={Colors.BlackText2}
                  text={studentDetails?.location}
                />
              </div>
            </div>
          </div>

          <Layout.Spacer height={40} />
          <div style={styles.tabsContainer}>
            <div
              onClick={() => setActive("general")}
              style={{
                ...styles.tabName,
                opacity: active === "general" ? "1" : "0.6",
              }}
            >
              General
            </div>
            <div
              onClick={() => setActive("applications")}
              style={{
                ...styles.tabName,
                opacity: active === "applications" ? "1" : "0.6",
              }}
            >
              Applications
            </div>
            <div
              onClick={() => setActive("deadlines")}
              style={{
                ...styles.tabName,
                opacity: active === "deadlines" ? "1" : "0.6",
              }}
            >
              Deadlines
            </div>
          </div>
          <div>
            {active === "general" ? (
              <General
                data={studentDetails?.applications}
                preferredProgram={preferredProgram}
              />
            ) : active === "applications" ? (
              <Application
                data={studentDetails?.applications}
                wishlistCount={preferredCountries}
                wishlist={wishlists}
              />
            ) : (
              <Deadlines
                data={studentDetails?.applications}
                wishlistCount={preferredCountries}
                wishlist={wishlists}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default StudentDetails;
