import {
  GET_SCHOOLS,
  GET_SCHOOLS_FAILURE,
  GET_SCHOOLS_SUCCESS,
  GET_SCHOOL_DETAILS,
  GET_SCHOOL_DETAILS_FAILURE,
  GET_SCHOOL_DETAILS_SUCCESS,
  ADD_SCHOOL,
  ADD_SCHOOL_FAILURE,
  ADD_SCHOOL_SUCCESS,
  CHANGE_SCHOOL_STATUS,
  CHANGE_SCHOOL_STATUS_FAILURE,
  CHANGE_SCHOOL_STATUS_SUCCESS,
} from "../constants";

const initialState = {
  count: 0,
  schools: [],
  school: {},
  isLoading: false,
  error: null,
};

export default function SchoolReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SCHOOLS:
    case ADD_SCHOOL:
    case GET_SCHOOL_DETAILS:
    case CHANGE_SCHOOL_STATUS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_SCHOOLS_SUCCESS:
      state = {
        ...state,
        count: action.payload.count,
        schools: action.payload.data,
        isLoading: false,
      };
      break;
    case ADD_SCHOOL_SUCCESS:
    case CHANGE_SCHOOL_STATUS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_SCHOOL_DETAILS_SUCCESS:
      state = {
        ...state,
        school: action.payload,
        isLoading: false,
      };
      break;
    case GET_SCHOOLS_FAILURE:
    case ADD_SCHOOL_FAILURE:
    case GET_SCHOOL_DETAILS_FAILURE:
    case CHANGE_SCHOOL_STATUS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
