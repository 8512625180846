import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { PopularUniversityAction } from "../actions";

export default class PopularUniversityMiddleware {
  static *getPopularUniversities({ cb }) {
    try {
      const res = yield ApiCaller.Get("/university/popular-university");
      if (res.status === 200) {
        yield put(
          PopularUniversityAction.getPopularUniversitiesSuccess(res.data.data)
        );
      } else {
        yield put(PopularUniversityAction.getPopularUniversitiesFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(PopularUniversityAction.getPopularUniversitiesFailure());
    }
  }
}
