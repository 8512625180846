import { Route, Routes } from 'react-router-dom';

import { PUBLIC_ROUTES } from './siteMap';

const AuthRoutes = () => {
  return (
    <Routes>
      {PUBLIC_ROUTES.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={route.component}
          exact={route.exact}
        />
      ))}
    </Routes>
  );
};
export default AuthRoutes;
