import { Colors } from "../../config";

const styles = {
  alignCenter: { display: "flex", alignItems: "center" },
  justifyBetween: { display: "flex", justifyContent: "space-between" },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexColumnCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  alignCenterJustifyBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  alignCenterJustifyAround: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },

  buttonStyle: {
    width: "100%",
    height: "50px",
    borderRadius: "6px",
    fontSize: "16px",
    fontWeight: "400",
    color: Colors.WhiteText,
    backgroundColor: Colors.Primary,
  },

  textfieldStyle: {
    borderRadius: "6px",
    border: `1px solid ${Colors.Primary}`,
    padding: "10px",
    width: "100%",
    fontSize: "16px",
    fontWeight: "400",
    height: "35px",
    color: Colors.BlackText2,
  },
  labelStyle: {
    textAlign: "left",
    color: "black",
    fontSize: "16px",
  },

  // school
  searchIcon: {
    fontSize: "24px",
    color: Colors.Primary,
    cursor: "pointer",
  },
  searchInput: {
    borderRadius: "10px",
    border: `1px solid ${Colors.Primary}`,
    padding: "10px",
    fontSize: "14px",
  },
  addSchoolButton: {
    width: "20%",
    borderRadius: "10px",
    fontSize: "14px",
    marginLeft: "10px",
  },
  imageStyle: {
    marginRight: "30px",
    height: "40px",
    width: "40px",
    borderRadius: "100%",
    objectFit: "contain",
    background: "white",
  },
  schoolName: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#081C15",
  },
  locationIcon: {
    marginRight: "5px",
    fontSize: "12px",
    color: Colors.GrayText,
  },
  location: {
    fontSize: "10px",
    fontWeight: "400",
    color: Colors.GrayText,
    textTransform: "capitalize",
  },
  rightIcon: {
    fontSize: "30px",
    color: Colors.Primary,
    cursor: "pointer",
  },
  editIcon: {
    cursor: "pointer",
    marginRight: "5px",
    marginTop: "1px",
    height: "26px",
    width: "26px",
  },

  // add school
  addSchooolImage: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "50px",
    border: `1px solid ${Colors.Primary}`,
    padding: "10px",
  },
  cameraIconContainer: {
    position: "absolute",
    bottom: "0",
    right: "0",
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "100%",
    padding: "2.5px 5px",
    cursor: "pointer",
  },

  inputFieldsContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridRowGap: "20px",
    gridColumnGap: "60px",
    width: "80%",
    margin: "auto",
  },

  // school details
  schoolImageContainer: {
    width: "120px",
    height: "120px",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.45)",
    backgroundColor: "#fafafa",
    borderRadius: "50%",
    padding: "10px",
    marginLeft: "20px",
  },
  schoolImage: {
    width: "100px",
    height: "100px",
    borderRadius: "100%",
  },
  schoolDetailsName: {
    textTransform: "capitalize",
    color: Colors.Primary,
    fontSize: "20px",
    fontWeight: "600",
    marginTop: "10px",
  },
  schoolDetailsLocation: {
    textTransform: "capitalize",
    color: Colors.GrayText,
    fontSize: "14px",
    fontWeight: "400",
  },
  schoolInfoContainer: { display: "flex", marginTop: "50px" },
  schoolInfoLeftBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: Colors.LightGray,
    borderRadius: "10px",
    width: "280px",
    padding: "0px 20px",
    marginRight: "20px",
  },
  leftBoxIcons: { fontSize: "20px", color: Colors.Primary },
  leftBoxText: {
    fontSize: "14px",
    color: Colors.GrayText,
    marginLeft: "30px",
  },
  schoolInfoRightBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.LightGray,
    borderRadius: "10px",
    width: "200px",
    padding: "20px",
  },
  rightBoxText: {
    fontSize: "40px",
    fontWeight: "500",
    color: Colors.Primary,
  },
  rightBoxTitle: {
    fontSize: "14px",
    fontWeight: "500",
    color: Colors.GrayText,
    marginBottom: "10px",
  },
  studentTableImage: {
    height: "50px",
    width: "50px",
    borderRadius: "100%",
    objectFit: "contain",
    background: "white",
  },
  studentTableName: { marginLeft: "10px", textTransform: "capitalize" },

  // edit school
  pointerNone: { opacity: "0.7", pointerEvents: "none" },
  uploadInvoiceButton: {
    background: Colors.Primary,
    padding: "10px",
    borderRadius: "10px",
    cursor: "pointer",
    color: "white",
  },
  uploadInvoiceTitle: {
    fontSize: "26px",
    color: Colors.Primary,
    fontWeight: "600",
  },
  invoiceFileInput: {
    width: "100%",
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "6px",
    padding: "6px",
    color: "black",
    fontSize: "14px",
    display: "flex",
    justifyContent: "space-between",
  },
  boxHeader: {
    fontSize: "14px",
    color: "black",
    fontWeight: "400",
  },
  meetingDatePicker: {
    color: Colors.Primary,
    height: "35px",
    width: "100%",
    borderColor: Colors.Primary,
    borderRadius: "7px",
  },
};

export default styles;
