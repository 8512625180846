import ActiveStudents from "../../assets/images/activeStudents.png";
import Accommodation from "../../assets/images/accommodation.svg";
import AnnouncementSidebarIcon from "../../assets/images/announcementIcon.svg";
import AnnouncementIcon from "../../assets/images/announcementLogo.svg";
import Approval from "../../assets/images/approval.svg";
import ApplicationSidebarIcon from "../../assets/images/applicationIcon.svg";
import BackgroundSource from "../../assets/videos/background video.mp4";
import Book from "../../assets/images/book.svg";
import Bus2 from "../../assets/images/bus.svg";
import Campus from "../../assets/images/university-campus.svg";
import Calculator from "../../assets/images/calculator.svg";
import ClassSize from "../../assets/images/presentation.svg";
import Clipboard from "../../assets/images/clipboard.svg";
import DefaultRestaurant from "../../assets/images/defaultRestaurant.png";
import DummyImage from "../../assets/images/dummyUser.jpg";
import Duration from "../../assets/images/homework.svg";
import Education from "../../assets/images/education.svg";
import Edit from "../../assets/images/edit.png";
import Exchange1 from "../../assets/images/world.svg";
import Exam from "../../assets/images/exam.svg";
import Facilities from "../../assets/images/school.svg";
import FacultyStudentRatio from "../../assets/images/facultyStudentRatio.svg";
import Food from "../../assets/images/fast-food.svg";
import FullLogo from "../../assets/images/univisionLoginText.png";
import Graduation from "../../assets/images/graduation-hat.svg";
import HousingCost from "../../assets/images/housingCost.png";
import Interview from "../../assets/images/interview.svg";
import Journey1 from "../../assets/images/journey1.svg";
import Journey2 from "../../assets/images/journey2.svg";
import Journey3 from "../../assets/images/journey3.svg";
import Journey4 from "../../assets/images/journey4.svg";
import LeftArrowCircle from "../../assets/images/arrowLeftCircle.svg";
import Logo from "../../assets/images/Logo.svg";
import Loan from "../../assets/images/loan.svg";
import Location from "../../assets/images/location.svg";
import Map from "../../assets/images/map.png";
import MaleToFemaleRatio from "../../assets/images/graduates.svg";
import Metro from "../../assets/images/train.svg";
import Medical from "../../assets/images/cardiogram.svg";
import Misc from "../../assets/images/bill.svg";
import MoneyBag from "../../assets/images/budget1.svg";
import NoData from "../../assets/images/no-data.svg";
import Others from "../../assets/images/pending.svg";
import Placement from "../../assets/images/graduate.svg";
import PostGradSalary from "../../assets/images/postgradSalary.png";
import Processing from "../../assets/images/work-process.svg";
import Promotion from "../../assets/images/promotion.png";
import Ranking from "../../assets/images/ranking.svg";
import RegisteredSchools from "../../assets/images/activeSchools.png";
import Research from "../../assets/images/research.svg";
import SchoolProfile from "../../assets/images/schoolProfile.png";
import Sort from "../../assets/images/sort.png";
import Time from "../../assets/images/sand-clock.svg";
import TotalUniversities from "../../assets/images/totalUni.png";
import TotalApplication from "../../assets/images/totalApplication.svg";
import Thumb from "../../assets/images/thumbs-up.svg";
import Uni1 from "../../assets/images/uni-1.png";
import Visa from "../../assets/images/success.svg";

const Images = {
  ActiveStudents,
  Accommodation,
  AnnouncementIcon,
  AnnouncementSidebarIcon,
  Approval,
  ApplicationSidebarIcon,
  BackgroundSource,
  Book,
  Bus2,
  Campus,
  Calculator,
  ClassSize,
  Clipboard,
  DefaultRestaurant,
  DummyImage,
  Duration,
  Education,
  Edit,
  Exchange1,
  Exam,
  Facilities,
  FacultyStudentRatio,
  Food,
  FullLogo,
  Graduation,
  HousingCost,
  Interview,
  Journey1,
  Journey2,
  Journey3,
  Journey4,
  LeftArrowCircle,
  Logo,
  Loan,
  Location,
  Map,
  MaleToFemaleRatio,
  Metro,
  Medical,
  Misc,
  MoneyBag,
  NoData,
  Others,
  Placement,
  PostGradSalary,
  Processing,
  Promotion,
  Ranking,
  RegisteredSchools,
  Research,
  SchoolProfile,
  Sort,
  Time,
  TotalUniversities,
  TotalApplication,
  Thumb,
  Uni1,
  Visa,
};

export default Images;
