import InputWrapper from "./CustomText";
import CustomTextArea from "./CustomTextArea";
import SelectField from "./SelectField";

const TextField = {
  SelectField,
  CustomInput: InputWrapper,
  CustomTextArea,
};

export default TextField;
