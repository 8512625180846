import { all, takeLatest } from "redux-saga/effects";

import {
  ADD_ANNOUNCEMENT,
  ADD_SCHOOL,
  ADD_UNIVERSITY_SHEET,
  CHANGE_ANNOUNCEMENT_STATUS,
  CHANGE_APPLICATION_STATUS,
  CHANGE_REQUEST_STATUS,
  CHANGE_SCHOOL_STATUS,
  DELETE_UNIVERSITY,
  EDIT_UNIVERSITY,
  EDIT_UNIVERSITY_SHEET,
  EDIT_BILLING,
  FORGOT_PASSWORD,
  GET_ANNOUNCEMENTS,
  GET_APPLICATIONS,
  GET_REQUESTS,
  GET_SCHOOLS,
  GET_SCHOOL_DETAILS,
  GET_STUDENTS,
  GET_ONBOARDED_STUDENTS,
  GET_STUDENT_DETAILS,
  GET_TOTAL_UNIVERSITIES,
  GET_UNIVERSITIES,
  GET_ALL_UNIVERSITIES,
  GET_POPULAR_UNIVERSITIES,
  GET_UNIVERSITY_DETAILS,
  LOGOUT,
  RESET_PASSWORD,
  SIGNIN,
  VERIFY_OTP,
  GET_PROFILE,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  ADD_CONTACT,
  GET_ALL_BILLING,
  GET_BILLING,
  GET_INVOICES,
  UPDATE_BILLING,
  GET_ALL_TRACKER,
  GET_TRACKER,
  ADD_TRACKER,
  UPDATE_TRACKER,
  DELETE_TRACKER,
  UPDATE_CONVERTER,
  GET_INDIVIDUAL_STUDENTS,
  UPDATE_INDIVIDUAL_STUDENT,
  UPDATE_INDIVIDUAL_STUDENT_PAYMENT,
  UPLOAD_INVOICE,
} from "../constants";
import {
  AuthMiddleware,
  AnnouncementMiddleware,
  ApplicationMiddleware,
  BillingMiddleware,
  ConverterMiddleware,
  ContactMiddleware,
  IndividualStudentMiddleware,
  ProfileMiddleware,
  PopularUniversityMiddleware,
  RequestMiddleware,
  SchoolMiddleware,
  StudentMiddleware,
  TrackerMiddleware,
  UniversityMiddleware,
} from "../middlewares";

export function* Sagas() {
  yield all([
    // auth
    yield takeLatest(SIGNIN, AuthMiddleware.SignIn),
    yield takeLatest(LOGOUT, AuthMiddleware.Logout),
    yield takeLatest(FORGOT_PASSWORD, AuthMiddleware.ForgotPassword),
    yield takeLatest(VERIFY_OTP, AuthMiddleware.VerifyOtp),
    yield takeLatest(RESET_PASSWORD, AuthMiddleware.ResetPassword),

    //announcements
    yield takeLatest(ADD_ANNOUNCEMENT, AnnouncementMiddleware.addAnnouncement),
    yield takeLatest(
      GET_ANNOUNCEMENTS,
      AnnouncementMiddleware.getAnnouncements
    ),
    yield takeLatest(
      CHANGE_ANNOUNCEMENT_STATUS,
      AnnouncementMiddleware.changeAnnouncementStatus
    ),

    //applications
    yield takeLatest(GET_APPLICATIONS, ApplicationMiddleware.getApplications),
    yield takeLatest(
      CHANGE_APPLICATION_STATUS,
      ApplicationMiddleware.changeApplicationStatus
    ),

    // universities
    yield takeLatest(
      ADD_UNIVERSITY_SHEET,
      UniversityMiddleware.addUniversitySheet
    ),
    yield takeLatest(
      EDIT_UNIVERSITY_SHEET,
      UniversityMiddleware.editUniversitySheet
    ),
    yield takeLatest(GET_UNIVERSITIES, UniversityMiddleware.getUniversities),
    yield takeLatest(
      GET_ALL_UNIVERSITIES,
      UniversityMiddleware.getAllUniversities
    ),
    yield takeLatest(
      GET_POPULAR_UNIVERSITIES,
      PopularUniversityMiddleware.getPopularUniversities
    ),
    yield takeLatest(
      GET_TOTAL_UNIVERSITIES,
      UniversityMiddleware.getTotalUniversities
    ),
    yield takeLatest(
      GET_UNIVERSITY_DETAILS,
      UniversityMiddleware.getUniversityDetails
    ),
    yield takeLatest(DELETE_UNIVERSITY, UniversityMiddleware.deleteUniversity),
    yield takeLatest(EDIT_UNIVERSITY, UniversityMiddleware.editUniversity),

    // school
    yield takeLatest(GET_SCHOOLS, SchoolMiddleware.getSchools),
    yield takeLatest(GET_SCHOOL_DETAILS, SchoolMiddleware.getSchoolDetails),
    yield takeLatest(ADD_SCHOOL, SchoolMiddleware.addSchool),
    yield takeLatest(CHANGE_SCHOOL_STATUS, SchoolMiddleware.changeSchoolStatus),

    // student
    yield takeLatest(GET_STUDENTS, StudentMiddleware.getStudents),
    yield takeLatest(GET_STUDENT_DETAILS, StudentMiddleware.getStudentDetails),
    yield takeLatest(
      GET_ONBOARDED_STUDENTS,
      StudentMiddleware.getOnboardedStudents
    ),

    // requests
    yield takeLatest(GET_REQUESTS, RequestMiddleware.getRequests),
    yield takeLatest(
      CHANGE_REQUEST_STATUS,
      RequestMiddleware.changeRequestStatus
    ),

    //user profile
    yield takeLatest(GET_PROFILE, ProfileMiddleware.getUserProfile),
    yield takeLatest(EDIT_PROFILE, ProfileMiddleware.editUserProfile),
    yield takeLatest(CHANGE_PASSWORD, ProfileMiddleware.changePassword),

    //miscellaneous
    yield takeLatest(ADD_CONTACT, ContactMiddleware.addContact),

    //billing
    yield takeLatest(EDIT_BILLING, BillingMiddleware.editBilling),
    yield takeLatest(GET_ALL_BILLING, BillingMiddleware.getAllBilling),
    yield takeLatest(GET_BILLING, BillingMiddleware.getBilling),
    yield takeLatest(GET_INVOICES, BillingMiddleware.getInvoices),
    yield takeLatest(UPDATE_BILLING, BillingMiddleware.updateBilling),
    yield takeLatest(UPLOAD_INVOICE, BillingMiddleware.uploadInvoice),

    //tracker
    yield takeLatest(GET_ALL_TRACKER, TrackerMiddleware.getAllTracker),
    yield takeLatest(GET_TRACKER, TrackerMiddleware.getTracker),
    yield takeLatest(ADD_TRACKER, TrackerMiddleware.addTracker),
    yield takeLatest(UPDATE_TRACKER, TrackerMiddleware.updateTracker),
    yield takeLatest(DELETE_TRACKER, TrackerMiddleware.deleteTracker),

    //converter
    yield takeLatest(UPDATE_CONVERTER, ConverterMiddleware.updateConverter),

    //individual students
    yield takeLatest(
      UPDATE_INDIVIDUAL_STUDENT,
      IndividualStudentMiddleware.updateIndividualStudent
    ),
    yield takeLatest(
      UPDATE_INDIVIDUAL_STUDENT_PAYMENT,
      IndividualStudentMiddleware.updateIndividualStudentPayment
    ),
    yield takeLatest(
      GET_INDIVIDUAL_STUDENTS,
      IndividualStudentMiddleware.getIndividualStudents
    ),
  ]);
}
