import {
  GET_INDIVIDUAL_STUDENTS,
  GET_INDIVIDUAL_STUDENTS_SUCCESS,
  GET_INDIVIDUAL_STUDENTS_FAILURE,
  UPDATE_INDIVIDUAL_STUDENT,
  UPDATE_INDIVIDUAL_STUDENT_SUCCESS,
  UPDATE_INDIVIDUAL_STUDENT_FAILURE,
  UPDATE_INDIVIDUAL_STUDENT_PAYMENT,
  UPDATE_INDIVIDUAL_STUDENT_PAYMENT_SUCCESS,
  UPDATE_INDIVIDUAL_STUDENT_PAYMENT_FAILURE,
} from "../constants";

const initialState = {
  individualStudents: [],
  individualStudent: {},
  isLoading: false,
  error: null,
};

export default function IndividualStudentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INDIVIDUAL_STUDENTS:
    case UPDATE_INDIVIDUAL_STUDENT:
    case UPDATE_INDIVIDUAL_STUDENT_PAYMENT:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;

    case GET_INDIVIDUAL_STUDENTS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        individualStudents: action.payload,
      };
      break;
    case UPDATE_INDIVIDUAL_STUDENT_SUCCESS:
    case UPDATE_INDIVIDUAL_STUDENT_PAYMENT_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case GET_INDIVIDUAL_STUDENTS_FAILURE:
    case UPDATE_INDIVIDUAL_STUDENT_FAILURE:
    case UPDATE_INDIVIDUAL_STUDENT_PAYMENT_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
