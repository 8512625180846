import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "antd";

import { AiFillHome } from "react-icons/ai";
import { BsFillBookmarkStarFill } from "react-icons/bs";
import { MdSchool, MdOutlineChecklistRtl, MdGroups } from "react-icons/md";
import { TbLogout } from "react-icons/tb";
import { SiPivotaltracker } from "react-icons/si";
import { RiExchangeDollarFill } from "react-icons/ri";

import { Images } from "../../config";
import { AuthAction } from "../../store/actions";

import styles from "./styles";
import "./sidebar.css";

const SideBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const title = ((location || {}).pathname || {}).split("/")[1];

  const role = useSelector((state) => state.auth.user?.user?.role);

  useEffect(() => {
    const menu = document.querySelector(".ant-menu").children;
    for (let i = 0; i < menu.length; i++) {
      if (menu[i].innerText.toLowerCase() === title) {
        menu[i].classList.add("ant-menu-item-selected");
      } else {
        menu[i].classList.remove("ant-menu-item-selected");
      }
    }
  }, [title]);

  const handleLogout = () => {
    dispatch(AuthAction.Logout());
  };

  const highlighter = (value) => {
    return title === value ? "menu-item ant-menu-item-selected" : "menu-item";
  };

  const renderMainNavOptions = () => {
    return (
      <>
        <Menu.Item
          className={highlighter("home")}
          key="1"
          id="home"
          icon={<AiFillHome style={styles.sidebarIcon} />}
        >
          <Link to="/home">Home</Link>
        </Menu.Item>
        <Menu.Item
          className={highlighter("universities")}
          id="universities"
          key="2"
          icon={<MdSchool style={styles.sidebarIcon} />}
        >
          <Link to="/universities">Universities</Link>
        </Menu.Item>
        {role && role === "super-admin" && (
          <Menu.Item
            className={highlighter("requests")}
            key="5"
            icon={<MdOutlineChecklistRtl style={styles.sidebarIcon} />}
          >
            <Link to="/requests">Requests</Link>
          </Menu.Item>
        )}
        <Menu.Item
          className={highlighter("schools")}
          key="3"
          icon={<BsFillBookmarkStarFill style={styles.sidebarIcon} />}
        >
          <Link to="/schools">Schools</Link>
        </Menu.Item>
        {role && role === "admin" && (
          <Menu.Item
            className={highlighter("tracker")}
            key="7"
            icon={<SiPivotaltracker style={styles.sidebarIcon} />}
          >
            <Link to="/tracker">Application Status Tracker</Link>
          </Menu.Item>
        )}
        {role && role === "admin" && (
          <Menu.Item
            className={highlighter("applications")}
            key="10"
            icon={
              <img
                src={Images.ApplicationSidebarIcon}
                alt="application"
                style={{ marginRight: "18px" }}
              />
            }
          >
            <Link to="/applications">Applications</Link>
          </Menu.Item>
        )}
        {role && role === "admin" && (
          <Menu.Item
            className={highlighter("announcements")}
            key="11"
            icon={
              <img
                src={Images.AnnouncementSidebarIcon}
                alt="announcement"
                style={{ marginRight: "12px" }}
              />
            }
          >
            <Link to="/announcements">Announcements</Link>
          </Menu.Item>
        )}
        {role && role === "admin" && (
          <Menu.Item
            className={highlighter("individualStudents")}
            key="4"
            icon={<MdGroups style={styles.sidebarIcon} />}
          >
            <Link to="/individualStudents">Individual Students</Link>
          </Menu.Item>
        )}
        {role && role === "super-admin" && (
          <Menu.Item
            className={highlighter("payments")}
            key="6"
            icon={<RiExchangeDollarFill style={styles.sidebarIcon} />}
          >
            <Link to="/payments">Payments</Link>
          </Menu.Item>
        )}
      </>
    );
  };

  return (
    <div className="sidebar">
      <div style={styles.sidebarLogoContainer}>
        <img src={Images.Logo} style={styles.sidebarImage} />
      </div>
      <Menu theme="dark" mode="inline" className="sidebar-menu">
        {renderMainNavOptions()}
        <Menu.Item
          className="menu-item sidebar-logout"
          key="8"
          icon={<TbLogout style={styles.sidebarIcon} />}
        >
          <Link to="/login" onClick={handleLogout}>
            Log out
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};
export default SideBar;
