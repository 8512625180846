import { ProfileDropDown } from "./ProfileDropDown";
import LoginLayout, { HeaderSection } from "./LoginLayout";
import Spacer from "./Spacer";
import NavHeader from "./NavHeader";
import StepsSection from "./Steps";
import DashboardLayout from "./DashboardLayout";

const Layout = {
  DashboardLayout,
  ProfileDropDown,
  NavHeader,
  Spacer,
  LoginLayout,
  HeaderSection,
  StepsSection,
};

export default Layout;
