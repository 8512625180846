import { Colors } from "../../config";

const styles = {
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexRowJustifyBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexColumnCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  welcomeText: {
    fontSize: "26px",
    fontWeight: "700",
    color: Colors.Primary,
    textTransform: "capitalize",
    textAlign: "left",
  },
  welcomeSubText: {
    fontSize: "14px",
    textAlign: "center",
    padding: "7px",
    width: "240px",
    borderRadius: "20px",
    color: "white",
    backgroundColor: Colors.Primary,
    textAlign: "center",
  },

  analyticsBox: {
    width: "100%",
    height: "150px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "15px",
    boxShadow: "2px 2px 4px 1px rgba(0, 0, 0, 0.25)",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "20px",
  },
  analyticsTitle: {
    color: Colors.Primary,
    fontSize: "20px",
    fontWeight: "600",
  },
  analyticsValue: {
    color: "black",
    fontSize: "36px",
    fontWeight: "700",
  },

  popularContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "15px",
    boxShadow: "2px 2px 4px 1px rgba(0, 0, 0, 0.25)",
    justifyContent: "space-around",
    padding: "20px",
    marginTop: "20px",
  },

  popularLeftSide: {
    width: "100%",
    borderRight: "1px solid #6a6a6a3c",
  },
  popularRightSide: {
    width: "60%",
    marginLeft: "20px",
  },
  popularTitle: {
    color: Colors.Primary,
    fontSize: "16px",
    fontWeight: "700",
    marginBottom: "10px",
  },
  popularValuesContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  popularValues: { color: "black", margin: "5px 10px" },
  popularUniImage: {
    borderRadius: "100%",
    marginRight: "10px",
    backgroundColor: "white",
    border: "0.5px solid #a0a0a080",
    objectFit: "contain",
    height: "30px",
    width: "30px",
  },
  popularUniName: {
    fontSize: "12px",
    fontWeight: "700",
    margin: "10px 10px",
    textTransform: "capitalize",
  },
  countryFlag: {
    width: "auto",
    height: "26px",
    borderRadius: "5px",
    margin: "2px",
  },

  customTextfieldStyle: {
    borderRadius: "10px",
    border: `1px solid ${Colors.Primary}`,
    padding: "10px",
    width: "100%",
    fontSize: "16px",
    fontWeight: "500",
    color: "#081C15",
  },
  filterButton: {
    width: "45px",
    height: "45px",
    padding: "6px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.Primary,
  },
  filterDropdown: {
    position: "absolute",
    zIndex: "100",
    backgroundColor: Colors.Primary,
    color: "white",
    fontSize: "12px",
    height: "80px",
    width: "150px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    marginTop: "5px",
    left: "-70px",
    animation: "slide-down 0.3s ease",
  },
  filterDropdownValue: {
    cursor: "pointer",
    borderBottom: "1px solid white",
    marginBottom: "5px",
    paddingBottom: "5px",
  },
  addUniversityButton: {
    width: "100%",
    borderRadius: "10px",
    fontSize: "14px",
    color: Colors.White,
  },
  filterButton: {
    width: "45px",
    height: "45px",
    padding: "6px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.Primary,
  },
  filterDropdown: {
    position: "absolute",
    zIndex: "100",
    backgroundColor: Colors.Primary,
    color: "white",
    fontSize: "12px",
    height: "80px",
    width: "150px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    marginTop: "5px",
    left: "-70px",
    animation: "slide-down 0.3s ease",
  },
  filterDropdownValue: {
    cursor: "pointer",
    borderBottom: "1px solid white",
    marginBottom: "5px",
    paddingBottom: "5px",
  },
  addUniversityButton: {
    width: "100%",
    borderRadius: "10px",
    fontSize: "14px",
    color: Colors.White,
  },
  filterRegion: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "110px",
    height: "30px",
    margin: "0px 8px",
    fontSize: "11px",
    fontWeight: "500",
    borderRadius: "5px",
  },
  filterRegionText: {
    marginRight: "30px",
    letterSpacing: "1px",
    fontSize: "16px",
    fontWeight: "500",
    color: Colors.Primary,
  },
  filterRegion: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "110px",
    height: "30px",
    margin: "0px 8px",
    fontSize: "11px",
    fontWeight: "500",
    borderRadius: "5px",
  },
  filterRegionText: {
    marginRight: "30px",
    letterSpacing: "1px",
    fontSize: "16px",
    fontWeight: "500",
    color: Colors.Primary,
  },

  // upload uni modal
  uploadUniTitle: {
    fontSize: "26px",
    color: Colors.Primary,
    fontWeight: "600",
  },
};

export default styles;
