import React from "react";
import { Link } from "react-router-dom";

import Settings from ".";

import styles from "./styles";

function About() {
  return (
    <Settings>
      <div style={styles.aboutHeader}>About</div>
      <ul style={styles.listStyle}>
        <li>
          <Link to="/privacypolicy" style={styles.pointsStyle}>
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link to="/termsandconditions" style={styles.pointsStyle}>
            Terms & Conditions
          </Link>
        </li>
      </ul>
    </Settings>
  );
}

export default About;
