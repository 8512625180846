import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "antd";

import { UniversityAction } from "../../store/actions";

import { Colors } from "../../config";
import { Loader, Text, Layout } from "../../components";

import General from "./general";
import StudentLife from "./studentLife";
import VisaInformation from "./visaInformation";
import Programs from "./programs";
import Finances from "./finances";
import ApplicationJourney from "./applicationJourney";
import PostGraduation from "./postGraduation";
import Summary from "./summary";

function EditUniversity() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const university = useSelector((state) => state.university.university);
  const id = location.pathname.split("/")[2];

  useEffect(() => {
    dispatch(UniversityAction.getUniversityDetails(id));
  }, []);

  const tabs = [
    "general",
    "student-life",
    "visa",
    "programs",
    "finances",
    "post-graduation",
    "application-journey",
    "summary",
  ];
  const tabName = location.pathname.split("/")[3];
  const tabKey = String(tabs.indexOf(tabName) + 1);

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Edit University"
      />
      <Layout.Spacer height={20} />
      {Object.keys(university).length === 0 ? (
        <Loader.Circular />
      ) : (
        <div>
          <Tabs
            type="card"
            className="university-edit-tabs"
            moreIcon={false}
            animated={true}
            key={tabKey}
            defaultActiveKey={tabKey}
            onChange={(value) => {
              switch (value) {
                case "1":
                  navigate(`/edit-university/${id}`);
                  break;
                case "2":
                  navigate(`/edit-university/${id}/student-life`);
                  break;
                case "3":
                  navigate(`/edit-university/${id}/visa`);
                  break;
                case "4":
                  navigate(`/edit-university/${id}/programs`);
                  break;
                case "5":
                  navigate(`/edit-university/${id}/finances`);
                  break;
                case "6":
                  navigate(`/edit-university/${id}/post-graduation`);
                  break;
                case "7":
                  navigate(`/edit-university/${id}/application-journey`);
                  break;
                case "8":
                  navigate(`/edit-university/${id}/summary`);
                  break;
                default:
                  navigate(`/edit-university/${id}`);
              }
            }}
          >
            <Tabs.TabPane tab="General" key="1">
              <General data={university} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Student Life" key="2">
              <StudentLife data={university} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Visa Information" key="3">
              <VisaInformation data={university} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Programs" key="4">
              <Programs data={university} locationData={university?.location} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Finances" key="5">
              <Finances data={university?.finances} university={university} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Post Graduation" key="6">
              <PostGraduation data={university} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Application Journey" key="7">
              <ApplicationJourney data={university} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Summary" key="8">
              <Summary data={university} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      )}
    </div>
  );
}

export default EditUniversity;
