import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select } from "antd";

import Lottie from "react-lottie-player";

import { ImBin2 } from "react-icons/im";

import { UniversityAction } from "../../store/actions";

import { Button, Layout, Text, TextField, Modal } from "../../components";
import { Colors, MockData } from "../../config";

import styles from "./styles";

import Done from "../../assets/images/57767-done.json";

function PostGraduation() {
  const dispatch = useDispatch();
  const location = useLocation();

  const data = useSelector((state) => state.university.university);
  const id = location.pathname.split("/")[2];

  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [state, setState] = useState({
    placementRate: data.postGraduation?.placementRate
      ? data.postGraduation?.placementRate
      : "NA",
    graduateSalary:
      !!data.postGraduation?.graduateSalary ||
      data.postGraduation?.graduateSalary === "NA"
        ? "NA"
        : data.postGraduation?.graduateSalary?.split(" ")[1],
    graduateSalaryCurrency:
      !data.postGraduation?.graduateSalary ||
      data.postGraduation?.graduateSalary === "NA"
        ? "USD"
        : data.postGraduation?.graduateSalary?.split(" ")[0],
    graduation: data.postGraduation?.graduation
      ? data.postGraduation?.graduation
      : "NA",
    work: data.postGraduation?.work ? data.postGraduation?.work : "NA",
  });

  useEffect(() => {
    dispatch(UniversityAction.getUniversityDetails(id));
  }, []);

  useEffect(() => {
    setState({
      placementRate: data.postGraduation?.placementRate,
      graduateSalary:
        !data.postGraduation?.graduateSalary ||
        data.postGraduation?.graduateSalary === "NA"
          ? "NA"
          : data.postGraduation?.graduateSalary?.split(" ")[1],
      graduateSalaryCurrency:
        !data.postGraduation?.graduateSalary ||
        data.postGraduation?.graduateSalary === "NA"
          ? "USD"
          : data.postGraduation?.graduateSalary?.split(" ")[0],
      graduation: data.postGraduation?.graduation
        ? data.postGraduation?.graduation
        : "NA",
      work: data.postGraduation?.work ? data.postGraduation?.work : "NA",
    });
  }, []);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleClear = () => {
    setState({
      placementRate: "",
      graduateSalary: "",
      graduateSalaryCurrency: "USD",
      graduation: "",
      work: "",
    });
  };

  const handleNext = () => {
    setSaveButtonDisabled(true);
    setError("");
    const body = {
      placementRate: !state.placementRate ? "NA" : state.placementRate,
      graduateSalary: !state.graduateSalary
        ? "NA"
        : `${state.graduateSalaryCurrency} ${state.graduateSalary}`,
      graduation: state.graduation,
      work: !state.work ? "NA" : state.work,
    };

    let dataToUpload = new FormData();
    dataToUpload.append("postGraduation", JSON.stringify(body));
    dataToUpload.append("tab", "postGraduation");

    dispatch(
      UniversityAction.editUniversity(id, dataToUpload, (res) => {
        setSaveButtonDisabled(false);
        if (res.status === 200) {
          setShowModal(true);
        }
      })
    );
  };

  return (
    <div style={styles.inputContainer}>
      <div style={styles.justifyBetween}>
        <Text.Heading
          fontSize={"16px"}
          fontWeight={"700"}
          color={Colors.Primary}
          text="Post Graduation"
        />
        <div onClick={handleClear}>
          <Text.Heading
            fontSize={"10px"}
            fontWeight={"400"}
            color={Colors.Gray}
            text="Clear Fields"
          />
          <ImBin2 color={Colors.Primary} fontSize={14} />
        </div>
      </div>
      <Layout.Spacer height={20} />
      <div style={styles.costTitleContainer}>
        <div>
          <div style={styles.inputLabel}>Placement Rate (%)</div>
          <TextField.CustomInput
            placeholder="90"
            name="placementRate"
            value={state.placementRate}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div className="input-group">
          <Text.Heading
            fontSize={"14px"}
            fontWeight={"500"}
            color={Colors.LabelText}
            textAlign="left"
            text="Annual Fresh Graduate Salary"
          />

          <Input.Group compact>
            <Select
              defaultValue="USD"
              name="graduateSalaryCurrency"
              value={state.graduateSalaryCurrency}
              onChange={(value) => {
                setState({
                  ...state,
                  graduateSalaryCurrency: value,
                });
              }}
              style={styles.currencyInput}
              showSearch
            >
              {MockData.Currency.map((item) => (
                <Select.Option value={item.value}>{item.name}</Select.Option>
              ))}
            </Select>
            <Input
              style={styles.currencyValueInput}
              placeholder="Amount"
              name="graduateSalary"
              value={state.graduateSalary}
              onChange={(e) =>
                setState({
                  ...state,
                  graduateSalary: e.target.value,
                })
              }
            />
          </Input.Group>
        </div>
      </div>
      <Layout.Spacer height={30} />
      <div style={styles.programNumber}>Student Pathway after Graduation</div>

      <div style={styles.costTitleContainer}>
        <div>
          <div style={styles.inputLabel}>Education</div>
          <TextField.CustomInput
            placeholder="4"
            name="graduation"
            value={state.graduation}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Work</div>
          <TextField.CustomInput
            placeholder="6"
            name="work"
            value={state.work}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
      </div>

      {error && (
        <div style={styles.flexColumnCenter}>
          <Layout.Spacer height={20} />
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"500"}
            color={Colors.Danger}
            text={error}
          />
        </div>
      )}
      <div style={styles.flexColumnCenter}>
        <Button.Primary
          style={styles.buttonStyle}
          onClick={handleNext}
          disabled={saveButtonDisabled}
        >
          Save/Continue
        </Button.Primary>
      </div>
      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => setShowModal(false)}
          closeIcon={true}
          className="ant-modal"
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={styles.modalIcon}
            ></Lottie>
            <div style={styles.modalText}>Successful!</div>
            <Button.Primary
              style={styles.modalButtonStyle}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Done
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
    </div>
  );
}
export default PostGraduation;
