import { put } from "redux-saga/effects";

import { IndividualStudentAction } from "../actions";
import { ApiCaller } from "../../config";

export default class IndividualStudentMiddleware {
  static *getIndividualStudents({ payload, cb }) {
    try {
      const { filter, search, limit, offset } = payload;
      const res = yield ApiCaller.Get(
        `/individualStudents/getIndividualStudents?filter=${filter}&search=${search}&limit=${limit}&offset=${offset}`
      );

      if (res.status === 200) {
        yield put(
          IndividualStudentAction.getIndividualStudentsSuccess(res.data.data)
        );
      } else {
        yield put(IndividualStudentAction.getIndividualStudentsFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(IndividualStudentAction.updateIndividualStudentFailure());
    }
  }
  static *updateIndividualStudent({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/individualStudents/updateIndividualStudent`,
        payload
      );

      if (res.status === 200) {
        yield put(
          IndividualStudentAction.updateIndividualStudentSuccess(res.data.data)
        );
      } else {
        yield put(IndividualStudentAction.updateIndividualStudentFailure());
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(IndividualStudentAction.updateIndividualStudentFailure());
    }
  }
  static *updateIndividualStudentPayment({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/individualStudents/updatePaymentStatus`,
        payload
      );

      if (res.status === 200) {
        yield put(
          IndividualStudentAction.updateIndividualStudentPaymentSuccess(
            res.data.data
          )
        );
      } else {
        yield put(
          IndividualStudentAction.updateIndividualStudentPaymentFailure()
        );
      }
      if (cb) {
        cb(res.data);
      }
    } catch (err) {
      yield put(
        IndividualStudentAction.updateIndividualStudentPaymentFailure()
      );
    }
  }
}
