import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { RequestAction } from "../actions";

export default class RequestMiddleware {
  static *getRequests({ payload, cb }) {
    try {
      const { limit, offset } = payload;
      const res = yield ApiCaller.Get(
        `/university/fetch-data?limit=${limit}&offset=${offset}`
      );
      if (res.status === 200) {
        yield put(RequestAction.getRequestsSuccess(res.data.data));
      } else {
        yield put(RequestAction.getRequestsFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(RequestAction.getRequestsFailure());
    }
  }

  static *changeRequestStatus({ payload, cb }) {
    try {
      const { id, status } = payload;
      const res = yield ApiCaller.Get(
        `/university/change-status?id=${id}&status=${status}`
      );
      if (res.status === 200) {
        yield put(RequestAction.changeRequestStatusSuccess(res.data.data));
      } else {
        yield put(RequestAction.changeRequestStatusFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(RequestAction.changeRequestStatusFailure());
    }
  }
}
