import { Colors } from "../../config";
import { Layout, Text } from "../../components";

import CardRow from "./cardRow";

import styles from "./styles";

function Deadlines({ data, wishlistCount, wishlist }) {
  const deadlines = [];
  const wishlistUniIds = [];

  wishlist.map((wishlists) => {
    if (!wishlistUniIds.includes(wishlists.universityId._id))
      wishlistUniIds.push(wishlists.universityId._id);
  });

  data.map((application) => {
    if (wishlistUniIds.includes(application.universityId._id)) {
      deadlines.push(application);
    }
  });

  const applicationsData = [
    {
      name: "Total Applications",
      value: data?.length,
    },
    {
      name: "Preferred Countries",
      value: wishlistCount ? wishlistCount : "0",
    },
  ];

  return (
    <>
      <Layout.Spacer height={20} />
      <div style={styles.cardContainerRow}>
        {applicationsData.map((item) => (
          <div style={styles.flexColumnCenter}>
            <Layout.Spacer height={30} />
            <Text.Heading
              fontSize="30px"
              color={Colors.Primary}
              fontWeight="500"
              text={item.value || "0"}
            />
            <Text.Heading
              fontSize={"14px"}
              fontWeight={"500"}
              color={Colors.GrayText}
              text={item.name}
            />
            <Layout.Spacer height={30} />
          </div>
        ))}
      </div>
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize="16px"
        fontWeight="500"
        color={Colors.Primary}
        text="Submitted Applications"
      />
      <Layout.Spacer height={20} />
      {deadlines?.length > 0 ? (
        deadlines?.map((application, index) => (
          <CardRow data={application} key={index} />
        ))
      ) : (
        <div style={styles.flexRowCenter}>
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"400"}
            color={Colors.Gray}
            text="No applications submitted yet"
          />
        </div>
      )}
    </>
  );
}

export default Deadlines;
