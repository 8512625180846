import { put } from "redux-saga/effects";

import { ApiCaller } from "../../config";
import { StudentAction } from "../actions";

export default class StudentMiddleware {
  static *getStudents({ payload, cb }) {
    try {
      const { schoolId, limit, offset } = payload;
      const res = yield ApiCaller.Get(
        `/school/get-students?schoolId=${schoolId}&limit=${limit}&offset=${offset}`
      );
      if (res.status === 200) {
        yield put(StudentAction.getStudentsSuccess(res.data.data));
      } else {
        yield put(StudentAction.getStudentsFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(StudentAction.getStudentsFailure());
    }
  }
  static *getOnboardedStudents({ payload, cb }) {
    try {
      const res = yield ApiCaller.Get(`/school/getOnboardedStudents`);
      if (res.status === 200) {
        yield put(StudentAction.getOnboardedStudentsSuccess(res.data.data));
      } else {
        yield put(StudentAction.getOnboardedStudentsFailure());
      }
      if (cb) {
        cb(res.data.data);
      }
    } catch (err) {
      yield put(StudentAction.getOnboardedStudentsFailure());
    }
  }

  static *getStudentDetails({ id, cb }) {
    try {
      const res = yield ApiCaller.Get(
        `/school/get-student-detail?studentId=${id}`
      );
      if (res.status === 200) {
        yield put(StudentAction.getStudentDetailsSuccess(res.data.data));
      } else {
        yield put(StudentAction.getStudentDetailsFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(StudentAction.getStudentDetailsFailure());
    }
  }
}
