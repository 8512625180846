import { delay, put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { AuthAction } from "../actions";

export default class AuthMiddleware {
  static *SignIn({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/login", payload);
      if (
        res.data.status === 404 &&
        res.data.message === "User does not exist"
      ) {
        yield put(AuthAction.SignInFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      } else if (
        res.data.status === 400 &&
        res.data.message === "Invalid Email or Password."
      ) {
        yield put(AuthAction.SignInFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      } else if (
        res.data.status === 401 &&
        res.data.message === "You are not allowed to access this route"
      ) {
        yield put(AuthAction.SignInFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      } else {
        yield put(AuthAction.SignInSuccess(res.data.data));
        Utils.showSnackBar({ message: res.data.message });
        yield localStorage.setItem("authUser", JSON.stringify(res.data.data));
        if (cb) {
          cb();
        }
      }
    } catch (err) {
      yield put(AuthAction.SignInFailure());
    }
  }

  static *Logout() {
    try {
      yield delay(1000);
      localStorage.removeItem("authUser");
      yield put(AuthAction.LogoutSuccess());
    } catch (err) {
      console.log(err);
      yield put(AuthAction.LogoutFailure());
    }
  }

  static *ForgotPassword({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/forget-password", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.ForgotPasswordSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        delay(2000);
        cb();
      } else {
        yield put(AuthAction.ForgotPasswordFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AuthAction.ForgotPasswordFailure());
    }
  }

  static *VerifyOtp({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/verify-code", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.VerifyOtpSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        yield delay(2000);
        cb();
      } else {
        yield put(AuthAction.VerifyOtpFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AuthAction.VerifyOtpFailure());
    }
  }

  static *ResetPassword({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post("/auth/reset-password", payload);
      if (res.data.status === 200) {
        yield put(AuthAction.ResetPasswordSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        yield delay(2000);
        cb();
      } else {
        yield put(AuthAction.ResetPasswordFailure());
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AuthAction.ResetPasswordFailure());
    }
  }
}
