import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select } from "antd";
import Lottie from "react-lottie-player";

import { ImBin2 } from "react-icons/im";

import { UniversityAction } from "../../store/actions";

import { Button, Text, TextField, Modal, Layout } from "../../components";
import { Colors, MockData } from "../../config";

import Done from "../../assets/images/57767-done.json";

import styles from "./styles";

function StudentLife() {
  const location = useLocation();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.university.university);

  const id = location.pathname.split("/")[2];

  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [state, setState] = useState({
    onCampusAverageMonthlyAccommodationCostCurrency: data.studentLife?.onCampus
      ?.averageMonthlyAccommodationCost
      ? data.studentLife?.onCampus?.averageMonthlyAccommodationCost.split(
          " "
        )[0]
      : "NA",
    onCampusAverageMonthlyAccommodationCost: data.studentLife?.onCampus
      ?.averageMonthlyAccommodationCost
      ? data.studentLife?.onCampus?.averageMonthlyAccommodationCost.split(
          " "
        )[1]
      : "NA",
    onCampusAverageMonthlyFoodCostCurrency: data.studentLife?.onCampus
      ?.averageMonthlyFoodCost
      ? data.studentLife?.onCampus?.averageMonthlyFoodCost.split(" ")[0]
      : "NA",
    onCampusAverageMonthlyFoodCost: data.studentLife?.onCampus
      ?.averageMonthlyFoodCost
      ? data.studentLife?.onCampus?.averageMonthlyFoodCost.split(" ")[1]
      : "NA",
    onCampusAverageMonthlyOthersCostCurrency: data.studentLife?.onCampus
      ?.averageMonthlyOthersCost
      ? data.studentLife?.onCampus?.averageMonthlyOthersCost.split(" ")[0]
      : "NA",
    onCampusAverageMonthlyOthersCost: data.studentLife?.onCampus
      ?.averageMonthlyOthersCost
      ? data.studentLife?.onCampus?.averageMonthlyOthersCost.split(" ")[1]
      : "NA",
    offCampusAverageMonthlyAccommodationCostCurrency: data.studentLife
      ?.offCampus?.averageMonthlyAccommodationCost
      ? data.studentLife?.offCampus?.averageMonthlyAccommodationCost.split(
          " "
        )[0]
      : "NA",
    offCampusAverageMonthlyAccommodationCost: data.studentLife?.offCampus
      ?.averageMonthlyAccommodationCost
      ? data.studentLife?.offCampus?.averageMonthlyAccommodationCost.split(
          " "
        )[1]
      : "NA",
    offCampusAverageMonthlyFoodCostCurrency: data.studentLife?.offCampus
      ?.averageMonthlyFoodCost
      ? data.studentLife?.offCampus?.averageMonthlyFoodCost.split(" ")[0]
      : "NA",
    offCampusAverageMonthlyFoodCost: data.studentLife?.offCampus
      ?.averageMonthlyFoodCost
      ? data.studentLife?.offCampus?.averageMonthlyFoodCost.split(" ")[1]
      : "NA",
    offCampusAverageMonthlyOthersCostCurrency: data.studentLife?.offCampus
      ?.averageMonthlyOthersCost
      ? data.studentLife?.offCampus?.averageMonthlyOthersCost.split(" ")[0]
      : "NA",
    offCampusAverageMonthlyOthersCost: data.studentLife?.offCampus
      ?.averageMonthlyOthersCost
      ? data.studentLife?.offCampus?.averageMonthlyOthersCost.split(" ")[1]
      : "NA",

    facilitiesAvailable: data.studentLife?.facilitiesAvailable
      ? data.studentLife?.facilitiesAvailable
      : "NA",
    transportOptions: "",
    exchange:
      data.studentLife?.exchange === "NA" ? "" : data.studentLife?.exchange,
    exchangeUniversities: data.studentLife?.exchangeUniversities
      ? data.studentLife?.exchangeUniversities
      : "NA",
    busDistance: data.studentLife?.busDistance
      ? data.studentLife?.busDistance
      : "NA",
    trainDistance: data.studentLife?.trainDistance
      ? data.studentLife?.trainDistance
      : "NA",
    halalFood: data.studentLife?.halalFood
      ? `${data.studentLife?.halalFood}`
      : "NA",

    restaurantOne: data.studentLife.restaurants
      ? data.studentLife?.restaurants.restaurantOne
      : "NA",
    restaurantOneLocation: data.studentLife.restaurants
      ? data.studentLife?.restaurants.restaurantOneLocation
      : "NA",
    restaurantOneImage: data.studentLife.restaurants
      ? data.studentLife?.restaurants.restaurantOneImage
      : "NA",
    restaurantTwo: data.studentLife.restaurants
      ? data.studentLife?.restaurants.restaurantTwo
      : "NA",
    restaurantTwoLocation: data.studentLife.restaurants
      ? data.studentLife.restaurants.restaurantTwoLocation
      : "NA",
    restaurantTwoImage: data.studentLife.restaurants
      ? data.studentLife.restaurants.restaurantTwoImage
      : "NA",
    restaurantThree: data.studentLife.restaurants
      ? data.studentLife.restaurants.restaurantThree
      : "NA",
    restaurantThreeLocation: data.studentLife.restaurants
      ? data.studentLife.restaurants.restaurantThreeLocation
      : "NA",
    restaurantThreeImage: data.studentLife.restaurants
      ? data.studentLife.restaurants.restaurantThreeImage
      : "NA",
    restaurantFour: data.studentLife.restaurants
      ? data.studentLife.restaurants.restaurantFour
      : "NA",
    restaurantFourLocation: data.studentLife.restaurants
      ? data.studentLife.restaurants.restaurantFourLocation
      : "NA",
    restaurantFourImage: data.studentLife.restaurants
      ? data.studentLife.restaurants.restaurantFourImag
      : "NA",
    restaurantFive: data.studentLife.restaurants
      ? data.studentLife.restaurants.restaurantFive
      : "NA",
    restaurantFiveLocation: data.studentLife.restaurants
      ? data.studentLife.restaurants.restaurantFiveLocation
      : "NA",
    restaurantFiveImage: data.studentLife.restaurants
      ? data.studentLife.restaurants.restaurantFiveImage
      : "NA",
  });

  useEffect(() => {
    dispatch(UniversityAction.getUniversityDetails(id));
  }, []);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleNext = () => {
    setSaveButtonDisabled(true);
    if (
      !state.onCampusAverageMonthlyAccommodationCost ||
      !state.onCampusAverageMonthlyFoodCost ||
      !state.onCampusAverageMonthlyOthersCost ||
      !state.offCampusAverageMonthlyAccommodationCost ||
      !state.offCampusAverageMonthlyFoodCost ||
      !state.offCampusAverageMonthlyOthersCost
    ) {
      setSaveButtonDisabled(false);
      setError("Please fill required fields");
    } else {
      setError("");
      const body = {
        onCampus: {
          averageMonthlyAccommodationCost: `${state.onCampusAverageMonthlyAccommodationCostCurrency} ${state.onCampusAverageMonthlyAccommodationCost}`,
          averageMonthlyFoodCost: `${state.onCampusAverageMonthlyFoodCostCurrency} ${state.onCampusAverageMonthlyFoodCost}`,
          averageMonthlyOthersCost: `${state.onCampusAverageMonthlyOthersCostCurrency} ${state.onCampusAverageMonthlyOthersCost}`,
        },
        offCampus: {
          averageMonthlyAccommodationCost: `${state.offCampusAverageMonthlyAccommodationCostCurrency} ${state.offCampusAverageMonthlyAccommodationCost}`,
          averageMonthlyFoodCost: `${state.offCampusAverageMonthlyFoodCostCurrency} ${state.offCampusAverageMonthlyFoodCost}`,
          averageMonthlyOthersCost: `${state.offCampusAverageMonthlyOthersCostCurrency} ${state.offCampusAverageMonthlyOthersCost}`,
        },
        facilitiesAvailable: state.facilitiesAvailable,
        exchange: !state.exchange ? "NA" : state.exchange,
        exchangeUniversities: state.exchangeUniversities,
        busDistance: state.busDistance,
        trainDistance: state.trainDistance,
        halalFood: state.halalFood,
        restaurants: {
          restaurantOne: state.restaurantOne,
          restaurantOneLocation: state.restaurantOneLocation,
          restaurantOneImage: state.restaurantOneImage,
          restaurantTwo: state.restaurantTwo,
          restaurantTwoLocation: state.restaurantTwoLocation,
          restaurantTwoImage: state.restaurantTwoImage,
          restaurantThree: state.restaurantThree,
          restaurantThreeLocation: state.restaurantThreeLocation,
          restaurantThreeImage: state.restaurantThreeImage,
          restaurantFour: state.restaurantFour,
          restaurantFourLocation: state.restaurantFourLocation,
          restaurantFourImage: state.restaurantFourImage,
          restaurantFive: state.restaurantFive,
          restaurantFiveLocation: state.restaurantFiveLocation,
          restaurantFiveImage: state.restaurantFiveImage,
        },
      };

      let dataToUpload = new FormData();

      dataToUpload.append("tab", "studentLife");
      dataToUpload.append("studentLife", JSON.stringify(body));

      dispatch(
        UniversityAction.editUniversity(id, dataToUpload, (res) => {
          setSaveButtonDisabled(false);
          if (res.status === 200) {
            setShowModal(true);
          }
        })
      );
    }
  };

  const handleClear = () => {
    setState({
      onCampusAverageMonthlyAccommodationCostCurrency: "USD",
      onCampusAverageMonthlyAccommodationCost: "",
      onCampusAverageMonthlyFoodCostCurrency: "USD",
      onCampusAverageMonthlyFoodCost: "",
      onCampusAverageMonthlyOthersCostCurrency: "USD",
      onCampusAverageMonthlyOthersCost: "",
      offCampusAverageMonthlyAccommodationCostCurrency: "USD",
      offCampusAverageMonthlyAccommodationCost: "",
      offCampusAverageMonthlyFoodCostCurrency: "USD",
      offCampusAverageMonthlyFoodCost: "",
      offCampusAverageMonthlyOthersCostCurrency: "USD",
      offCampusAverageMonthlyOthersCost: "",
      facilitiesAvailable: "",
      exchange: "",
      exchangeUniversities: "",
      busDistance: "",
      trainDistance: "",
      halalFood: "",
      restaurants: {
        restaurantOne: "",
        restaurantOneLocation: "",
        restaurantOneImage: "",
        restaurantTwo: "",
        restaurantTwoLocation: "",
        restaurantTwoImage: "",
        restaurantThree: "",
        restaurantThreeLocation: "",
        restaurantThreeImage: "",
        restaurantFour: "",
        restaurantFourLocation: "",
        restaurantFourImage: "",
        restaurantFive: "",
        restaurantFiveLocation: "",
        restaurantFiveImage: "",
      },
    });
  };

  const titles = [
    { id: 1, title: "On Campus" },
    { id: 2, title: "Off Campus" },
    { id: 3, title: "Average Monthly Cost" },
    { id: 4, title: "Average Monthly Cost" },
  ];

  const costs = [
    {
      id: 1,
      title: "Accommodation *",
      name: "onCampusAverageMonthlyAccommodationCostCurrency",
      value: state.onCampusAverageMonthlyAccommodationCostCurrency,
      onChange: (value) =>
        setState({
          ...state,
          onCampusAverageMonthlyAccommodationCostCurrency: value,
        }),
      inputName: "onCampusAverageMonthlyAccommodationCost",
      inputValue: state.onCampusAverageMonthlyAccommodationCost,
    },
    {
      id: 2,
      title: "Accommodation *",
      name: "offCampusAverageMonthlyAccommodationCostCurrency",
      value: state.offCampusAverageMonthlyAccommodationCostCurrency,
      onChange: (value) =>
        setState({
          ...state,
          offCampusAverageMonthlyAccommodationCostCurrency: value,
        }),
      inputName: "offCampusAverageMonthlyAccommodationCost",
      inputValue: state.offCampusAverageMonthlyAccommodationCost,
    },
    {
      id: 3,
      title: "Food *",
      name: "onCampusAverageMonthlyFoodCostCurrency",
      value: state.onCampusAverageMonthlyFoodCostCurrency,
      onChange: (value) =>
        setState({
          ...state,
          onCampusAverageMonthlyFoodCostCurrency: value,
        }),
      inputName: "onCampusAverageMonthlyFoodCost",
      inputValue: state.onCampusAverageMonthlyFoodCost,
    },
    {
      id: 4,
      title: "Food *",
      name: "offCampusAverageMonthlyFoodCostCurrency",
      value: state.offCampusAverageMonthlyFoodCostCurrency,
      onChange: (value) =>
        setState({
          ...state,
          offCampusAverageMonthlyFoodCostCurrency: value,
        }),
      inputName: "offCampusAverageMonthlyFoodCost",
      inputValue: state.offCampusAverageMonthlyFoodCost,
    },
    {
      id: 5,
      title: "Others *",
      name: "onCampusAverageMonthlyOthersCostCurrency",
      value: state.onCampusAverageMonthlyOthersCostCurrency,
      onChange: (value) =>
        setState({
          ...state,
          onCampusAverageMonthlyOthersCostCurrency: value,
        }),
      inputName: "onCampusAverageMonthlyOthersCost",
      inputValue: state.onCampusAverageMonthlyOthersCost,
    },
    {
      id: 6,
      title: "Others *",
      name: "offCampusAverageMonthlyOthersCostCurrency",
      value: state.offCampusAverageMonthlyOthersCostCurrency,
      onChange: (value) =>
        setState({
          ...state,
          offCampusAverageMonthlyOthersCostCurrency: value,
        }),
      inputName: "offCampusAverageMonthlyOthersCost",
      inputValue: state.offCampusAverageMonthlyOthersCost,
    },
  ];

  const restaurants = [
    {
      id: 1,
      title: "Restaurant 1",
      name: "restaurantOne",
      value: state.restaurantOne,
    },
    {
      id: 2,
      title: "Restaurant 1 Distance",
      name: "restaurantOneLocation",
      value: state.restaurantOneLocation,
    },
    {
      id: 3,
      title: "Restaurant 1 Image (Link)",
      name: "restaurantOneImage",
      value: state.restaurantOneImage,
    },
    {
      id: 4,
      title: "Restaurant 2",
      name: "restaurantTwo",
      value: state.restaurantTwo,
    },
    {
      id: 5,
      title: "Restaurant 2 Distance",
      name: "restaurantTwoLocation",
      value: state.restaurantTwoLocation,
    },
    {
      id: 6,
      title: "Restaurant 2 Image (Link)",
      name: "restaurantTwoImage",
      value: state.restaurantTwoImage,
    },
    {
      id: 7,
      title: "Restaurant 3",
      name: "restaurantThree",
      value: state.restaurantThree,
    },
    {
      id: 8,
      title: "Restaurant 3 Distance",
      name: "restaurantThreeLocation",
      value: state.restaurantThreeLocation,
    },
    {
      id: 9,
      title: "Restaurant 3 Image (Link)",
      name: "restaurantThreeImage",
      value: state.restaurantThreeImage,
    },
    {
      id: 10,
      title: "Restaurant 4",
      name: "restaurantFour",
      value: state.restaurantFour,
    },
    {
      id: 11,
      title: "Restaurant 4 Distance",
      name: "restaurantFourLocation",
      value: state.restaurantFourLocation,
    },
    {
      id: 12,
      title: "Restaurant 4 Image (Link)",
      name: "restaurantFourImage",
      value: state.restaurantFourImage,
    },
    {
      id: 13,
      title: "Restaurant 5",
      name: "restaurantFive",
      value: state.restaurantFive,
    },
    {
      id: 14,
      title: "Restaurant 5 Distance",
      name: "restaurantFiveLocation",
      value: state.restaurantFiveLocation,
    },
    {
      id: 15,
      title: "Restaurant 5 Image (Link)",
      name: "restaurantFiveImage",
      value: state.restaurantFiveImage,
    },
  ];

  const restOption = [
    {
      id: 1,
      title: "Train Distance",
      placeholder: "2km",
      name: "trainDistance",
      value: state.trainDistance,
    },
    {
      id: 2,
      title: "Bus Distance",
      placeholder: "2km",
      name: "busDistance",
      value: state.busDistance,
    },
    {
      id: 3,
      title: "Exchange Availibility (Yes/No)",
      placeholder: "Yes",
      name: "exchange",
      value: state.exchange,
    },
    {
      id: 4,
      title: "Exchange Universities (Link)",
      placeholder: "https://link.com",
      name: "exchangeUniversities",
      value: state.exchangeUniversities,
    },
  ];

  return (
    <div style={styles.inputContainer}>
      <div style={styles.justifyBetween}>
        <Text.Heading
          fontSize={"16px"}
          fontWeight={"700"}
          color={Colors.Primary}
          text="Housing Options"
        />
        <div onClick={handleClear}>
          <Text.Heading
            fontSize={"10px"}
            fontWeight={"400"}
            color={Colors.Gray}
            text="Clear Fields"
          />
          <ImBin2 color={Colors.Primary} fontSize={14} />
        </div>
      </div>
      <div style={styles.costTitleContainer}>
        {titles.map((item) => (
          <div
            style={{
              color: item.id === 1 || item.id === 2 ? Colors.Primary : "black",
              fontSize: "14px",
              fontWeight: "700",
            }}
            key={item.id}
          >
            {item.title}
          </div>
        ))}

        {costs?.map((item) => (
          <div className="input-group" key={item.id}>
            <div>{item.title}</div>
            <Input.Group compact>
              <Select
                defaultValue="USD"
                name={item.name}
                value={item.value}
                onChange={item.onChange}
                style={styles.currencyInput}
                showSearch
              >
                {MockData.Currency.map((item) => (
                  <Select.Option value={item.value}>{item.name}</Select.Option>
                ))}
              </Select>
              <Input
                style={styles.currencyValueInput}
                placeholder="Amount"
                name={item.inputName}
                value={item.inputValue}
                onChange={handleChange}
              />
            </Input.Group>
          </div>
        ))}
      </div>
      <Layout.Spacer height={20} />
      <div style={styles.inputLabel}>On Campus Facilities (Link)</div>
      <TextField.CustomInput
        placeholder="Link"
        name="facilitiesAvailable"
        value={state.facilitiesAvailable}
        onChange={handleChange}
        style={styles.inputStyle}
      />

      <div style={styles.restaurantContainer}>
        {restaurants?.map((item) => (
          <div key={item.id}>
            <div style={styles.inputLabel}>{item.title}</div>
            <TextField.CustomInput
              placeholder="KFC"
              name={item.name}
              value={item.value}
              onChange={handleChange}
              style={styles.inputStyle}
            />
          </div>
        ))}
      </div>
      <div style={styles.costTitleContainer}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          className="university-form-1"
        >
          <div style={styles.inputLabel}>Select Halal Food Option</div>
          <Select
            defaultValue="easy"
            value={state.halalFood}
            onChange={(e) => {
              setState({
                ...state,
                halalFood: e,
              });
            }}
          >
            <Select.Option value="easy">Halal Easy</Select.Option>
            <Select.Option value="medium">Halal Medium</Select.Option>
            <Select.Option value="hard">Halal Hard</Select.Option>
          </Select>
        </div>
        {restOption?.map((item) => (
          <div>
            <div style={styles.inputLabel}>{item.title}</div>
            <TextField.CustomInput
              placeholder={item.placeholder}
              name={item.name}
              value={item.value}
              onChange={handleChange}
              style={styles.inputStyle}
            />
          </div>
        ))}
      </div>

      {error && (
        <div style={styles.flexColumnCenter}>
          <Layout.Spacer height={20} />
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"500"}
            color={Colors.Danger}
            text={error}
          />
        </div>
      )}
      <div style={styles.flexColumnCenter}>
        <Button.Primary
          style={styles.buttonStyle}
          onClick={handleNext}
          disabled={saveButtonDisabled}
        >
          Save/Continue
        </Button.Primary>
      </div>

      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => setShowModal(false)}
          closeIcon={true}
          className="ant-modal"
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={styles.modalIcon}
            ></Lottie>
            <div style={styles.modalText}>Successful!</div>
            <Button.Primary
              style={styles.modalButtonStyle}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Done
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
    </div>
  );
}
export default StudentLife;
