import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Select, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { AiFillCloseCircle } from "react-icons/ai";

import Lottie from "react-lottie-player";

import { TrackerAction, UniversityAction } from "../../store/actions";
import {
  Button,
  Layout,
  Text,
  Loader,
  TextField,
  Modal,
} from "../../components";
import { Colors } from "../../config";

import styles from "./styles";
import Done from "../../assets/images/57767-done.json";

function AddTracker() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const universityLoading = useSelector((state) => state.university);
  const { allUniversities, allUniversityLoading } = useSelector(
    (state) => state.university
  );

  const [university, setUniversity] = useState("");
  const [resultDate, setResultDate] = useState("");
  const [deadline, setDeadline] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [failText, setFailText] = useState(
    "We have encountered an error. Please check if you have filled all the required fields."
  );
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Add");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [failModal, setFailModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    if (allUniversities?.length === 0) {
      dispatch(UniversityAction.getAllUniversities());
    }
  }, []);

  const defaultStates = () => {
    setButtonText("Add");
    setButtonDisabled(false);
  };

  const handleAddTracker = () => {
    setLoading(true);
    setButtonText("Adding...");
    setButtonDisabled(true);
    if (!university || !deadline) {
      setError("Please fill all the required fields");
      setLoading(false);
      defaultStates();
    } else {
      setError("");
      const payload = {
        university,
        resultDate,
        deadline,
        status,
      };
      dispatch(
        TrackerAction.addTracker(payload, (res) => {
          if (res === 200) {
            setSuccessModal(true);
          } else if (res === 409) {
            setFailModal(true);
            setFailText("Status for this university already exists");
          } else {
            setFailModal(true);
          }
          defaultStates();
        })
      );
    }
  };

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Add Tracker"
      />

      {allUniversityLoading ? (
        <Loader.Circular />
      ) : (
        <>
          <Layout.Spacer height={20} />
          <div style={styles.label}>University Name*</div>
          <div className="dashboard-select">
            {universityLoading?.allUniversityLoading ? (
              <Loader.Circular />
            ) : (
              <Select
                style={styles.addUniNameInput}
                onChange={(universityJSON) => {
                  const university = JSON.parse(universityJSON);
                  setUniversity(university._id);
                }}
                showSearch
                placeholder="Search University "
                optionFilterProp="children"
                suffixIcon={<SearchOutlined />}
              >
                {allUniversities?.map((dropdown) => {
                  return (
                    <Select.Option
                      value={JSON.stringify(dropdown)}
                      style={{ textTransform: "capitalize" }}
                      key={dropdown._id}
                    >
                      {dropdown.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </div>
          <Layout.Spacer height={20} />
          <div style={styles.label}>Deadline*</div>
          <Layout.Spacer height={10} />
          <div>
            <DatePicker
              style={styles.datePicker}
              onChange={(date, dateString) => {
                setDeadline(dateString);
              }}
            />
          </div>
          <Layout.Spacer height={10} />
          <div style={styles.label}>Result Date</div>
          <Layout.Spacer height={10} />
          <div>
            <DatePicker
              style={styles.datePicker}
              onChange={(date, dateString) => {
                setResultDate(dateString);
              }}
            />
          </div>
          <Layout.Spacer height={10} />
          <div style={styles.label}>Status</div>
          <Layout.Spacer height={10} />
          <div>
            <TextField.CustomInput
              placeholder="Processing"
              name="status"
              onChange={(e) => setStatus(e.target.value)}
              value={status}
              style={styles.statusInput}
            />
          </div>
          <Layout.Spacer height={20} />
          {error && (
            <>
              <div style={{ color: "red" }}>{error}</div>
              <Layout.Spacer height={20} />
            </>
          )}

          <Button.Primary
            style={styles.buttonStyle}
            onClick={handleAddTracker}
            disabled={buttonDisabled}
          >
            {buttonText}
          </Button.Primary>
        </>
      )}

      {successModal && (
        <Modal.Basic
          isModalVisible={successModal}
          centered={true}
          handleCancel={() => setSuccessModal(false)}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={{ height: "120px" }}
            />
            <Text.Heading
              fontSize={"24px"}
              fontWeight={"700"}
              color={Colors.Primary}
              text="Successful!"
            />
            <Layout.Spacer height={10} />
            <Text.Heading
              fontSize={"16px"}
              fontWeight={"400"}
              color={Colors.Black}
              text="Application Status Tracker Added Successfully!"
            />
            <Layout.Spacer height={20} />
            <Button.Primary
              style={styles.buttonStyle}
              onClick={() => {
                setSuccessModal(false);
                navigate("/tracker");
              }}
            >
              Ok
            </Button.Primary>
          </div>
          <Layout.Spacer height={10} />
        </Modal.Basic>
      )}
      {failModal && (
        <Modal.Basic
          isModalVisible={failModal}
          centered={true}
          handleCancel={() => setFailModal(false)}
          closable={false}
        >
          <div style={styles.flexColumnCenter}>
            <AiFillCloseCircle
              color="red"
              style={{ width: "70px", height: "70px" }}
            />
            <Layout.Spacer height={10} />
            <Text.Heading
              fontSize={"24px"}
              fontWeight={"700"}
              color={Colors.Black}
              text="Unsuccessful!"
            />
            <Layout.Spacer height={10} />
            <Text.Heading
              fontSize={"16px"}
              fontWeight={"400"}
              color={Colors.Black}
              text={failText}
            />
            <Layout.Spacer height={20} />
            <Button.Primary
              style={styles.buttonStyle}
              onClick={() => {
                setFailModal(false);
              }}
            >
              Ok
            </Button.Primary>
          </div>
          <Layout.Spacer height={10} />
        </Modal.Basic>
      )}
    </div>
  );
}

export default AddTracker;
