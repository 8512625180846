import {
  ADD_UNIVERSITY_SHEET,
  ADD_UNIVERSITY_SHEET_FAILURE,
  ADD_UNIVERSITY_SHEET_SUCCESS,
  DELETE_UNIVERSITY,
  DELETE_UNIVERSITY_FAILURE,
  DELETE_UNIVERSITY_SUCCESS,
  EDIT_UNIVERSITY,
  EDIT_UNIVERSITY_FAILURE,
  EDIT_UNIVERSITY_SUCCESS,
  EDIT_UNIVERSITY_SHEET,
  EDIT_UNIVERSITY_SHEET_SUCCESS,
  EDIT_UNIVERSITY_SHEET_FAILURE,
  GET_TOTAL_UNIVERSITIES,
  GET_TOTAL_UNIVERSITIES_FAILURE,
  GET_TOTAL_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITIES,
  GET_UNIVERSITIES_FAILURE,
  GET_UNIVERSITIES_SUCCESS,
  GET_ALL_UNIVERSITIES,
  GET_ALL_UNIVERSITIES_FAILURE,
  GET_ALL_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITY_DETAILS,
  GET_UNIVERSITY_DETAILS_FAILURE,
  GET_UNIVERSITY_DETAILS_SUCCESS,
} from "../constants";

const initialState = {
  totalUniversities: 0,
  count: 0,
  popular: 0,
  popularUniversities: [],
  universities: [],
  allUniversities: [],
  university: {},
  isLoading: true,
  allUniversityLoading: true,
  error: null,
};

export default function UniversityReducer(state = initialState, action) {
  switch (action.type) {
    case GET_UNIVERSITIES:
    case GET_ALL_UNIVERSITIES:
    case GET_UNIVERSITY_DETAILS:
    case ADD_UNIVERSITY_SHEET:
    case EDIT_UNIVERSITY_SHEET:
    case DELETE_UNIVERSITY:
    case EDIT_UNIVERSITY:
    case GET_TOTAL_UNIVERSITIES:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_UNIVERSITIES_SUCCESS:
      state = {
        ...state,
        universities: action.payload.data,
        totalUniversities: action.payload.total,
        count: action.payload.count,
        popular: action.payload.popular,
        university: {},
        isLoading: false,
      };
      break;
    case GET_ALL_UNIVERSITIES_SUCCESS:
      state = {
        ...state,
        allUniversities: action?.payload,
        isLoading: false,
        allUniversityLoading: false,
      };
      break;
    case GET_TOTAL_UNIVERSITIES_SUCCESS:
      state = {
        ...state,
        totalUniversities: action.payload,
        isLoading: false,
      };
      break;
    case GET_UNIVERSITY_DETAILS_SUCCESS:
      state = {
        ...state,
        university: action.payload,
        isLoading: false,
      };
      break;
    case ADD_UNIVERSITY_SHEET_SUCCESS:
    case EDIT_UNIVERSITY_SHEET_SUCCESS:
    case DELETE_UNIVERSITY_SUCCESS:
    case EDIT_UNIVERSITY_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_UNIVERSITIES_FAILURE:
    case GET_UNIVERSITY_DETAILS_FAILURE:
    case ADD_UNIVERSITY_SHEET_FAILURE:
    case EDIT_UNIVERSITY_SHEET_FAILURE:
    case DELETE_UNIVERSITY_FAILURE:
    case EDIT_UNIVERSITY_FAILURE:
    case GET_TOTAL_UNIVERSITIES_FAILURE:
    case GET_ALL_UNIVERSITIES_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
