import { Colors } from "../../config";

const styles = {
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  // payment details
  invoiceStyle: {
    color: "white",
    backgroundColor: Colors.Primary,
    padding: "5px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  iconStyle: { height: "48px", width: "48px", padding: "10px" },
};

export default styles;
