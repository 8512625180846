import {
  GET_REQUESTS,
  GET_REQUESTS_FAILURE,
  GET_REQUESTS_SUCCESS,
  CHANGE_REQUEST_STATUS,
  CHANGE_REQUEST_STATUS_FAILURE,
  CHANGE_REQUEST_STATUS_SUCCESS,
} from "../constants";

const initialState = {
  count: 0,
  requests: [],
  isLoading: false,
  error: null,
};

export default function RequestReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REQUESTS:
    case CHANGE_REQUEST_STATUS:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case GET_REQUESTS_SUCCESS:
      state = {
        ...state,
        count: action.payload.count,
        requests: action.payload.data,
        isLoading: false,
      };
      break;
    case CHANGE_REQUEST_STATUS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_REQUESTS_FAILURE:
    case CHANGE_REQUEST_STATUS_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }
  return state;
}
