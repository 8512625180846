import { Colors } from "../../config";

const styles = {
  forgotFormContainer: {
    width: "100%",
    margin: "0 auto",
  },
  inputFieldBorder: { borderColor: Colors.Primary },

  buttonStyle: {
    width: "100%",
    backgroundColor: Colors.Primary,
    borderRadius: "15px",
    fontFamily: "Montserrat",
  },
};

export default styles;
