import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { AnnouncementAction } from "../actions";

export default class AnnouncementMiddleware {
  static *addAnnouncement({ payload, cb }) {
    try {
      const res = yield ApiCaller.Post(
        `/school/addIndividualAnnouncements`,
        payload
      );
      if (res.status === 201) {
        yield put(AnnouncementAction.addAnnouncementSuccess(res.data.data));
      } else {
        yield put(AnnouncementAction.addAnnouncementFailure());
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(AnnouncementAction.addAnnouncementFailure());
    }
  }

  static *getAnnouncements({ payload, cb }) {
    try {
      const { limit, offset } = payload;
      const res = yield ApiCaller.Get(
        `/school/fetchIndividualAnnouncements?limit=${limit}&offset=${offset}&requestedBy=admin`
      );

      if (res.status === 200) {
        yield put(AnnouncementAction.getAnnouncementsSuccess(res.data.data));
      } else {
        yield put(AnnouncementAction.getAnnouncementsSuccess([]));
      }
      if (cb) {
        cb(res);
      }
    } catch (err) {
      yield put(AnnouncementAction.getAnnouncementsFailure());
    }
  }

  static *changeAnnouncementStatus({ payload, cb }) {
    try {
      const { id } = payload;
      const res = yield ApiCaller.Get(
        `/school/changeIndividualAnnouncementStatus/${id}`
      );
      if (res.status === 200) {
        yield put(
          AnnouncementAction.changeAnnouncementStatusSuccess(res.data.data)
        );
        Utils.showSnackBar({
          message: res.data.message,
        });
      } else {
        yield put(AnnouncementAction.changeAnnouncementStatusFailure());
      }
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(AnnouncementAction.changeAnnouncementStatusFailure());
    }
  }
}
