import {
  GET_PROFILE,
  GET_PROFILE_FAILURE,
  GET_PROFILE_SUCCESS,
  EDIT_PROFILE,
  EDIT_PROFILE_FAILURE,
  EDIT_PROFILE_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
} from "../constants";

export default class ProfileAction {
  static getUserProfile(cb) {
    return {
      type: GET_PROFILE,
      cb,
    };
  }
  static getUserProfileSuccess(payload) {
    return {
      type: GET_PROFILE_SUCCESS,
      payload,
    };
  }
  static getUserProfileFailure(payload) {
    return {
      type: GET_PROFILE_FAILURE,
      payload,
    };
  }
  static editUserProfile(payload, cb) {
    return {
      type: EDIT_PROFILE,
      payload,
      cb,
    };
  }
  static editUserProfileSuccess(payload) {
    return {
      type: EDIT_PROFILE_SUCCESS,
      payload,
    };
  }
  static editUserProfileFailure(payload) {
    return {
      type: EDIT_PROFILE_FAILURE,
      payload,
    };
  }
  static changePassword(payload, cb) {
    return {
      type: CHANGE_PASSWORD,
      payload,
      cb,
    };
  }
  static changePasswordSuccess(payload) {
    return {
      type: CHANGE_PASSWORD_SUCCESS,
      payload,
    };
  }
  static changePasswordFailure(payload) {
    return {
      type: CHANGE_PASSWORD_FAILURE,
      payload,
    };
  }
}
