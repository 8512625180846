import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router";

import { AiFillRightCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { MdOutlineCancel } from "react-icons/md";

import { BillingAction } from "../../store/actions";

import { Layout, Table, Text } from "../../components";
import { Colors, Utils } from "../../config";

import styles from "./styles";

function Payments() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const billingState = useSelector((state) => state.billings);
  const billingArray = useSelector((state) => state.billings.billings);

  useEffect(() => {
    dispatch(BillingAction.getAllBilling());
  }, []);

  const cancelSubscriptionReject = (schoolId) => {
    const payload = {
      schoolId: schoolId,
      cancelRequest: false,
      accepted: false,
    };
    dispatch(
      BillingAction.updateBilling(payload, () => {
        Utils.showSnackBar({ message: "Data Updated" });
        dispatch(BillingAction.getAllBilling());
      })
    );
  };

  const cancelSubscriptionAccept = (schoolId) => {
    console.log(schoolId);
    const payload = {
      schoolId: schoolId,
      cancelRequest: false,
      accepted: true,
    };
    dispatch(
      BillingAction.updateBilling(payload, () => {
        Utils.showSnackBar({ message: "Data Updated" });
        dispatch(BillingAction.getAllBilling());
      })
    );
  };

  const data = [
    {
      title: "School Name",
      dataIndex: "schoolName",
      width: 350,
      onCell: (record) => ({
        onClick: () => navigate(`/payments/details/${record?.schoolId}`),
      }),
      render: (text, record) => <span>{record?.name}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "center",
      width: 200,
      onCell: (record) => ({
        onClick: () => navigate(`/payments/details/${record.schoolId}`),
      }),
      render: (text, record) => <span>USD {record?.amount}</span>,
    },
    {
      title: "Subscription Status",
      dataIndex: "subscriptionStatus",
      align: "center",
      width: 200,
      onCell: (record) => ({
        onClick: () => navigate(`/payments/details/${record.schoolId}`),
      }),
      render: (text, record) => (
        <span
          style={{
            textTransform: "uppercase",
            fontWeight: "600",
            color:
              record?.billingStatus === "inactive"
                ? Colors.Red
                : Colors.Primary,
          }}
        >
          {record?.billingStatus}
        </span>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      align: "center",
      width: 200,
      onCell: (record) => ({
        onClick: () => navigate(`/payments/details/${record.schoolId}`),
      }),
      render: (text, record) => (
        <span
          style={{
            textTransform: "uppercase",
            fontWeight: "600",
          }}
        >
          {record?.paymentType}
        </span>
      ),
    },
    {
      title: "Cancel Request",
      dataIndex: "cancelReq",
      align: "center",
      width: 200,
      render: (text, record) => {
        return record?.cancelRequest === true ? (
          <div style={styles.flexCenter}>
            <div onClick={() => cancelSubscriptionAccept(record.schoolId)}>
              <AiOutlineCheckCircle
                style={{
                  color: "green",
                  ...styles.iconStyle,
                }}
              />
            </div>
            <div onClick={() => cancelSubscriptionReject(record.schoolId)}>
              <MdOutlineCancel
                style={{
                  color: "red",
                  ...styles.iconStyle,
                }}
              />
            </div>
          </div>
        ) : (
          <span>-</span>
        );
      },
    },
    {
      title: " ",
      dataIndex: "actions",
      align: "center",
      width: 100,
      render: (text, record) => (
        <AiFillRightCircle
          fontSize={"25px"}
          color={Colors.Primary}
          cursor={"pointer"}
          onClick={() => navigate(`/payments/details/${record.schoolId}`)}
        />
      ),
    },
  ];

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Payments"
      />

      <Layout.Spacer height={20} />
      <Table.AntDesign
        rowKey={(record) => record._id}
        columns={data}
        data={billingArray}
        loading={billingState?.isLoading}
        pagination={false}
      />
      <Layout.Spacer height={20} />
    </div>
  );
}

export default Payments;
