import { Link } from "react-router-dom";
import { Menu } from "antd";

import styles from "./styles";

export const ProfileDropDown = () => {
  return (
    <Menu style={styles.dropdownContainer}>
      <Menu.Item key={"1"}>
        <Link to="/profile">Profile</Link>
      </Menu.Item>
      <hr style={styles.dropdownDivider} />
      <Menu.Item key={"2"}>
        <Link to="/support">Support</Link>
      </Menu.Item>
      <hr style={styles.dropdownDivider} />
      <Menu.Item key={"3"}>
        <Link to="/settings">Settings</Link>
      </Menu.Item>
      <hr style={styles.dropdownDivider} />
      <Menu.Item key={"4"}>
        <Link to="/privacypolicy">Privacy Policy</Link>
      </Menu.Item>
      <hr style={styles.dropdownDivider} />
      <Menu.Item key={"5"}>
        <Link to="/termsandconditions">Terms & Conditions</Link>
      </Menu.Item>
    </Menu>
  );
};
