import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";

import { HiOutlineEyeOff, HiOutlineEye } from "react-icons/hi";

import { AuthAction } from "../../store/actions";

import { Button, Layout } from "../../components";
import { Utils } from "../../config";

import styles from "./styles";

const LoginForm = ({ role }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    emailErrMsg: "",
    passErrMsg: "",
    iconClick: false,
    validEmail: true,
    validPass: true,
  });

  const handleLogin = (e) => {
    e.preventDefault();
    if (state.email === "") {
      setState({
        ...state,
        emailErrMsg: "Email can not be left empty.",
        validEmail: false,
      });
    } else if (Utils.emailRegex.test(state.email) === false) {
      setState({
        ...state,
        emailErrMsg: "Please enter a valid email address.",
        validEmail: false,
      });
    } else if (state.password === "") {
      setState({
        ...state,
        emailErrMsg: "",
        validEmail: true,
        passErrMsg: "Password can not be left empty.",
        validPass: false,
      });
    } else if (Utils.passwordRegex.test(state.password) === false) {
      setState({
        ...state,
        emailErrMsg: "",
        validEmail: true,
        passErrMsg: "Please enter a valid password.",
        validPass: false,
      });
    } else {
      setState({
        ...state,
        emailErrMsg: "",
        validEmail: true,
        passErrMsg: "",
        validPass: true,
      });
      const body = {
        email: state.email,
        password: state.password,
        role,
      };
      dispatch(AuthAction.SignIn(body));
    }
  };

  return (
    <form style={styles.loginFormContainer} onSubmit={handleLogin}>
      <Form.Group>
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          type="email"
          placeholder="xyz@emailprovider.com"
          onChange={(e) =>
            setState({
              ...state,
              email: e.target.value,
            })
          }
          style={styles.inputFieldBorder}
        />
        {state.emailErrMsg && (
          <Form.Text className="text-danger">{state.emailErrMsg}</Form.Text>
        )}
      </Form.Group>
      <Layout.Spacer height={20} />
      <Form.Group>
        <Form.Label>Password</Form.Label>
        <div style={styles.passwordContainer}>
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            onChange={(e) => {
              setState({
                ...state,
                password: e.target.value,
              });
            }}
            style={styles.inputFieldBorder}
          />
          {showPassword ? (
            <HiOutlineEye
              style={styles.passwordIcon}
              onClick={() => setShowPassword(false)}
            />
          ) : (
            <HiOutlineEyeOff
              style={styles.passwordIcon}
              onClick={() => setShowPassword(true)}
            />
          )}
        </div>
        {state.passErrMsg && (
          <Form.Text className="text-danger">{state.passErrMsg}</Form.Text>
        )}
      </Form.Group>
      <Layout.Spacer height={10} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Form.Check type="checkbox" label="Remember Me" />

        <Link to="/forgot-password" style={styles.forgotPasswordText}>
          Forgot Password?
        </Link>
      </div>
      <Layout.Spacer height={30} />

      <Button.Primary
        style={styles.buttonStyle}
        onClick={handleLogin}
        type="submit"
      >
        Login
      </Button.Primary>
    </form>
  );
};

export default LoginForm;
