import {
  GET_INDIVIDUAL_STUDENTS,
  GET_INDIVIDUAL_STUDENTS_SUCCESS,
  GET_INDIVIDUAL_STUDENTS_FAILURE,
  UPDATE_INDIVIDUAL_STUDENT,
  UPDATE_INDIVIDUAL_STUDENT_SUCCESS,
  UPDATE_INDIVIDUAL_STUDENT_FAILURE,
  UPDATE_INDIVIDUAL_STUDENT_PAYMENT,
  UPDATE_INDIVIDUAL_STUDENT_PAYMENT_SUCCESS,
  UPDATE_INDIVIDUAL_STUDENT_PAYMENT_FAILURE,
} from "../constants";

export default class IndividualStudentAction {
  static getIndividualStudents(payload, cb) {
    return {
      type: GET_INDIVIDUAL_STUDENTS,
      payload,
      cb,
    };
  }
  static getIndividualStudentsSuccess(payload) {
    return {
      type: GET_INDIVIDUAL_STUDENTS_SUCCESS,
      payload,
    };
  }
  static getIndividualStudentsFailure(payload) {
    return {
      type: GET_INDIVIDUAL_STUDENTS_FAILURE,
      payload,
    };
  }
  static updateIndividualStudent(payload, cb) {
    return {
      type: UPDATE_INDIVIDUAL_STUDENT,
      payload,
      cb,
    };
  }
  static updateIndividualStudentSuccess(payload) {
    return {
      type: UPDATE_INDIVIDUAL_STUDENT_SUCCESS,
      payload,
    };
  }
  static updateIndividualStudentFailure(payload) {
    return {
      type: UPDATE_INDIVIDUAL_STUDENT_FAILURE,
      payload,
    };
  }
  static updateIndividualStudentPayment(payload, cb) {
    return {
      type: UPDATE_INDIVIDUAL_STUDENT_PAYMENT,
      payload,
      cb,
    };
  }
  static updateIndividualStudentPaymentSuccess(payload) {
    return {
      type: UPDATE_INDIVIDUAL_STUDENT_PAYMENT_SUCCESS,
      payload,
    };
  }
  static updateIndividualStudentPaymentFailure(payload) {
    return {
      type: UPDATE_INDIVIDUAL_STUDENT_PAYMENT_FAILURE,
      payload,
    };
  }
}
