import { Colors } from "../../config";

const styles = {
  flexRowSpace: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },

  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexRowStart: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexEnd: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  buttonStyle: {
    width: "200px",
    fontWeight: "500",
    fontSize: "14px",
  },

  searchInput: {
    borderRadius: "10px",
    border: `1px solid ${Colors.Primary}`,
    padding: "10px",
    fontSize: "14px",
  },
  searchIcon: {
    fontSize: "24px",
    color: Colors.Primary,
    cursor: "pointer",
  },
  // sepcific tracker
  label: { color: "black", fontSize: "14px", fontWeight: "500" },
  universityNameInput: {
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "5px",
    padding: "5px",
    height: "45px",
    textTransform: "capitalize",
  },
  datePicker: {
    width: "100%",
    fontSize: "12px",
    fontWeight: "400",
    color: Colors.BlackText,
    borderColor: Colors.Primary,
    borderRadius: "7px",
    padding: "10px",
  },
  statusInput: {
    border: `1px solid ${Colors.Primary}`,
    borderRadius: "5px",
    padding: "5px",
    height: "45px",
    color: "black",
  },

  // add tracker
  addUniNameInput: {
    color: Colors.Primary,
    width: "100%",
    borderColor: Colors.Primary,
    borderRadius: "10px",
    padding: "10px",
    textTransform: "capitalize",
  },
};

export default styles;
