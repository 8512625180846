import {
  GET_STUDENTS,
  GET_STUDENTS_FAILURE,
  GET_STUDENTS_SUCCESS,
  GET_ONBOARDED_STUDENTS,
  GET_ONBOARDED_STUDENTS_SUCCESS,
  GET_ONBOARDED_STUDENTS_FAILURE,
  GET_STUDENT_DETAILS,
  GET_STUDENT_DETAILS_FAILURE,
  GET_STUDENT_DETAILS_SUCCESS,
} from "../constants";

const initialState = {
  count: 0,
  onBoardedStudents: 0,
  students: [],
  studentDetails: {},
  isLoading: false,
  error: null,
};

export default function StudentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENTS:
    case GET_ONBOARDED_STUDENTS:
    case GET_STUDENT_DETAILS:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case GET_STUDENTS_SUCCESS:
      state = {
        ...state,
        count: action.payload.count,
        students: action.payload.data,
        isLoading: false,
      };
      break;
    case GET_ONBOARDED_STUDENTS_SUCCESS:
      state = {
        ...state,
        onBoardedStudents: action.payload.obboardedStudents,
        isLoading: false,
      };
      break;
    case GET_STUDENT_DETAILS_SUCCESS:
      state = {
        ...state,
        studentDetails: action.payload,
        isLoading: false,
      };
      break;
    case GET_STUDENTS_FAILURE:
    case GET_ONBOARDED_STUDENTS_FAILURE:
    case GET_STUDENT_DETAILS_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }
  return state;
}
