import {
  SIGNIN,
  SIGNIN_FAILURE,
  SIGNIN_SUCCESS,
  LOGOUT,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  LOADER_FALSE,
  LOADER_TRUE,
} from "../constants";

export default class AuthAction {
  static SignIn(payload, cb) {
    return {
      type: SIGNIN,
      payload,
      cb,
    };
  }
  static SignInSuccess(payload) {
    return {
      type: SIGNIN_SUCCESS,
      payload,
    };
  }
  static SignInFailure() {
    return {
      type: SIGNIN_FAILURE,
    };
  }

  static Logout() {
    return {
      type: LOGOUT,
    };
  }
  static LogoutSuccess() {
    return {
      type: LOGOUT_SUCCESS,
    };
  }
  static LogoutFailure() {
    return {
      type: LOGOUT_FAILURE,
    };
  }
  static ForgotPassword(payload, cb) {
    return {
      type: FORGOT_PASSWORD,
      payload,
      cb,
    };
  }
  static ForgotPasswordSuccess(payload) {
    return {
      type: FORGOT_PASSWORD_SUCCESS,
      payload,
    };
  }
  static ForgotPasswordFailure() {
    return {
      type: FORGOT_PASSWORD_FAILURE,
    };
  }
  static VerifyOtp(payload, cb) {
    return {
      type: VERIFY_OTP,
      payload,
      cb,
    };
  }
  static VerifyOtpSuccess(payload) {
    return {
      type: VERIFY_OTP_SUCCESS,
      payload,
    };
  }
  static VerifyOtpFailure() {
    return {
      type: VERIFY_OTP_FAILURE,
    };
  }
  static ResetPassword(payload, cb) {
    return {
      type: RESET_PASSWORD,
      payload,
      cb,
    };
  }
  static ResetPasswordSuccess(payload) {
    return {
      type: RESET_PASSWORD_SUCCESS,
      payload,
    };
  }
  static ResetPasswordFailure() {
    return {
      type: RESET_PASSWORD_FAILURE,
    };
  }
  static LoaderTrue() {
    return {
      type: LOADER_TRUE,
    };
  }
  static LoaderFalse() {
    return {
      type: LOADER_FALSE,
    };
  }
}
