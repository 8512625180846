import {
  GET_POPULAR_UNIVERSITIES,
  GET_POPULAR_UNIVERSITIES_FAILURE,
  GET_POPULAR_UNIVERSITIES_SUCCESS,
} from "../constants";

const initialState = {
  popularUniversities: [],
  popularCountries: [],
  isLoading: true,
  error: null,
};

export default function PopularUniversityReducer(state = initialState, action) {
  switch (action.type) {
    case GET_POPULAR_UNIVERSITIES:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;

    case GET_POPULAR_UNIVERSITIES_SUCCESS:
      state = {
        ...state,
        popularUniversities: action.payload.maxOccurrenceSorted,
        popularCountries: action.payload.countryOccurrencesArray,
        isLoading: false,
      };
      break;

    case GET_POPULAR_UNIVERSITIES_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
