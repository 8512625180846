import {
  ADD_UNIVERSITY_SHEET,
  ADD_UNIVERSITY_SHEET_FAILURE,
  ADD_UNIVERSITY_SHEET_SUCCESS,
  DELETE_UNIVERSITY,
  DELETE_UNIVERSITY_FAILURE,
  DELETE_UNIVERSITY_SUCCESS,
  EDIT_UNIVERSITY,
  EDIT_UNIVERSITY_FAILURE,
  EDIT_UNIVERSITY_SUCCESS,
  EDIT_UNIVERSITY_SHEET,
  EDIT_UNIVERSITY_SHEET_SUCCESS,
  EDIT_UNIVERSITY_SHEET_FAILURE,
  GET_TOTAL_UNIVERSITIES,
  GET_TOTAL_UNIVERSITIES_FAILURE,
  GET_TOTAL_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITIES,
  GET_UNIVERSITIES_FAILURE,
  GET_UNIVERSITIES_SUCCESS,
  GET_ALL_UNIVERSITIES,
  GET_ALL_UNIVERSITIES_FAILURE,
  GET_ALL_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITY_DETAILS,
  GET_UNIVERSITY_DETAILS_FAILURE,
  GET_UNIVERSITY_DETAILS_SUCCESS,
} from "../constants";

export default class UniversityAction {
  static addUniversitySheet(payload, cb) {
    return {
      type: ADD_UNIVERSITY_SHEET,
      payload,
      cb,
    };
  }

  static addUniversitySheetSuccess(payload) {
    return {
      type: ADD_UNIVERSITY_SHEET_SUCCESS,
      payload,
    };
  }
  static addUniversitySheetFailure(payload) {
    return {
      type: ADD_UNIVERSITY_SHEET_FAILURE,
      payload,
    };
  }
  static editUniversitySheet(payload, cb) {
    return {
      type: EDIT_UNIVERSITY_SHEET,
      payload,
      cb,
    };
  }

  static editUniversitySheetSuccess(payload) {
    return {
      type: EDIT_UNIVERSITY_SHEET_SUCCESS,
      payload,
    };
  }
  static editUniversitySheetFailure(payload) {
    return {
      type: EDIT_UNIVERSITY_SHEET_FAILURE,
      payload,
    };
  }
  static getUniversities(payload, cb) {
    return {
      type: GET_UNIVERSITIES,
      payload,
      cb,
    };
  }
  static getUniversitiesSuccess(payload) {
    return {
      type: GET_UNIVERSITIES_SUCCESS,
      payload,
    };
  }
  static getUniversitiesFailure(payload) {
    return {
      type: GET_UNIVERSITIES_FAILURE,
      payload,
    };
  }
  static getAllUniversities(payload, cb) {
    return {
      type: GET_ALL_UNIVERSITIES,
      payload,
      cb,
    };
  }
  static getAllUniversitiesSuccess(payload) {
    return {
      type: GET_ALL_UNIVERSITIES_SUCCESS,
      payload,
    };
  }
  static getAllUniversitiesFailure(payload) {
    return {
      type: GET_ALL_UNIVERSITIES_FAILURE,
      payload,
    };
  }
  static getTotalUniversities(cb) {
    return {
      type: GET_TOTAL_UNIVERSITIES,
      cb,
    };
  }
  static getTotalUniversitiesSuccess(payload) {
    return {
      type: GET_TOTAL_UNIVERSITIES_SUCCESS,
      payload,
    };
  }
  static getTotalUniversitiesFailure(payload) {
    return {
      type: GET_TOTAL_UNIVERSITIES_FAILURE,
      payload,
    };
  }
  static getUniversityDetails(id, cb) {
    return {
      type: GET_UNIVERSITY_DETAILS,
      id,
      cb,
    };
  }
  static getUniversityDetailsSuccess(payload) {
    return {
      type: GET_UNIVERSITY_DETAILS_SUCCESS,
      payload,
    };
  }
  static getUniversityDetailsFailure(payload) {
    return {
      type: GET_UNIVERSITY_DETAILS_FAILURE,
      payload,
    };
  }
  static deleteUniversity(id, cb) {
    return {
      type: DELETE_UNIVERSITY,
      id,
      cb,
    };
  }
  static deleteUniversitySuccess(payload) {
    return {
      type: DELETE_UNIVERSITY_SUCCESS,
      payload,
    };
  }
  static deleteUniversityFailure(payload) {
    return {
      type: DELETE_UNIVERSITY_FAILURE,
      payload,
    };
  }
  static editUniversity(id, payload, cb) {
    return {
      type: EDIT_UNIVERSITY,
      id,
      payload,
      cb,
    };
  }
  static editUniversitySuccess(payload) {
    return {
      type: EDIT_UNIVERSITY_SUCCESS,
      payload,
    };
  }
  static editUniversityFailure(payload) {
    return {
      type: EDIT_UNIVERSITY_FAILURE,
      payload,
    };
  }
}
