import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Lottie from "react-lottie-player";

import { ImBin2 } from "react-icons/im";
import { IoMdCloseCircle } from "react-icons/io";

import { UniversityAction } from "../../store/actions";

import { Button, Layout, Text, TextField, Modal } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

import Done from "../../assets/images/57767-done.json";

function ApplicationJourney() {
  const dispatch = useDispatch();
  const location = useLocation();

  const data = useSelector((state) => state.university.university);
  const id = location.pathname.split("/")[2];

  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [state, setState] = useState({
    step1: "",
    step1Details: "",
    step2: "",
    step2Details: "",
    step3: "",
    step3Details: "",
    step4: "",
    step4Details: "",
    checklist: [""],
  });

  useEffect(() => {
    dispatch(UniversityAction.getUniversityDetails(id));
  }, []);

  useEffect(() => {
    const checklists =
      data.applicationJourney.checklist.length === 0
        ? [""]
        : data.applicationJourney.checklist[0].split(",").map((item) => item);

    setState({
      step1: data.applicationJourney?.step1?.title
        ? data.applicationJourney?.step1?.title
        : "NA",
      step1Details: data.applicationJourney?.step1?.details
        ? data.applicationJourney?.step1?.details
        : "NA",
      step2: data.applicationJourney?.step2?.title
        ? data.applicationJourney?.step2?.title
        : "NA",
      step2Details: data.applicationJourney?.step2?.details
        ? data.applicationJourney?.step2?.details
        : "NA",
      step3: data.applicationJourney?.step3?.title
        ? data.applicationJourney?.step3?.title
        : "NA",
      step3Details: data.applicationJourney?.step3?.details
        ? data.applicationJourney?.step3?.details
        : "NA",
      step4: data.applicationJourney?.step4?.title
        ? data.applicationJourney?.step4?.title
        : "NA",
      step4Details: data.applicationJourney?.step4?.details
        ? data.applicationJourney?.step4?.details
        : "NA",
      checklist: checklists,
    });
  }, []);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleClear = () => {
    setState({
      step1: "",
      step1Details: "",
      step2: "",
      step2Details: "",
      step3: "",
      step3Details: "",
      step4: "",
      step4Details: "",
      checklist: [""],
    });
  };

  const handleAddMoreChecklist = () => {
    let checkFieldsValidation = checkFields();
    if (checkFieldsValidation) {
      setError("Checklist cannot be empty");
    } else {
      setError("");
      setState({
        ...state,
        checklist: [...state.checklist, ""],
      });
    }
  };

  const handleRemoveChecklist = (index) => {
    var array = [...state.checklist];
    array.splice(index, 1);
    setState({
      ...state,
      checklist: array,
    });
  };

  const checkFields = () => {
    let flag = false;
    state.checklist.forEach((item) => {
      if (!item) {
        flag = true;
      }
    });
    return flag;
  };

  const handleNext = () => {
    setSaveButtonDisabled(true);
    if (
      !state.step1Details ||
      !state.step2Details ||
      !state.step3Details ||
      !state.step4Details ||
      checkFields()
    ) {
      setError("Please fill required fields");
      setSaveButtonDisabled(false);
    } else {
      setError("");

      const body = {
        step1: {
          title: !state.step1 ? "NA" : state.step1,
          details: state.step1Details ? state.step1Details : "NA",
        },
        step2: {
          title: !state.step2 ? "NA" : state.step2,
          details: state.step2Details ? state.step2Details : "NA",
        },
        step3: {
          title: !state.step3 ? "NA" : state.step3,
          details: state.step3Details ? state.step3Details : "NA",
        },
        step4: {
          title: !state.step4 ? "NA" : state.step4,
          details: state.step4Details ? state.step4Details : "NA",
        },
        checklist: state.checklist.join(","),
      };

      let dataToUpload = new FormData();
      dataToUpload.append("applicationJourney", JSON.stringify(body));
      dataToUpload.append("tab", "applicationJourney");

      dispatch(
        UniversityAction.editUniversity(id, dataToUpload, (res) => {
          setSaveButtonDisabled(false);
          if (res.status === 200) {
            setShowModal(true);
          }
        })
      );
    }
  };

  return (
    <div style={styles.inputContainer}>
      <div style={styles.justifyBetween}>
        <Text.Heading
          fontSize={"16px"}
          fontWeight={"700"}
          color={Colors.Primary}
          text="Application Journey"
        />
        <div onClick={handleClear}>
          <Text.Heading
            fontSize={"10px"}
            fontWeight={"400"}
            color={Colors.Gray}
            text="Clear Fields"
          />
          <ImBin2 color={Colors.Primary} fontSize={14} />
        </div>
      </div>
      <Layout.Spacer height={20} />
      <div style={styles.costTitleContainer}>
        <div>
          <div style={styles.inputLabel}>Step 1 *</div>
          <div style={{ fontSize: "14px" }}>Title</div>
          <TextField.CustomInput
            placeholder="Application Form"
            name="step1"
            value={state.step1}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
          <Layout.Spacer height={10} />
          <div style={{ fontSize: "14px" }}>Description</div>
          <TextField.CustomTextArea
            rows={4}
            name="step1Details"
            value={state.step1Details}
            placeholder="Step details"
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Step 2 *</div>
          <div style={{ fontSize: "14px" }}>Title</div>
          <TextField.CustomInput
            placeholder="SAT/IELTS"
            name="step2"
            value={state.step2}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
          <Layout.Spacer height={10} />

          <div style={{ fontSize: "14px" }}>Description</div>
          <TextField.CustomTextArea
            rows={4}
            name="step2Details"
            value={state.step2Details}
            onChange={handleChange}
            placeholder="Step details"
            style={styles.textfieldStyle}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Step 3 *</div>
          <div style={{ fontSize: "14px" }}>Title</div>
          <TextField.CustomInput
            placeholder="Personal Essay"
            name="step3"
            value={state.step3}
            onChange={handleChange}
            style={{ ...styles.textfieldStyle, fontSize: "14px" }}
          />
          <Layout.Spacer height={10} />
          <div style={{ fontSize: "14px" }}>Description</div>
          <TextField.CustomTextArea
            rows={4}
            name="step3Details"
            value={state.step3Details}
            onChange={handleChange}
            placeholder="Step details"
            style={styles.textBox}
          />
        </div>
        <div>
          <div style={styles.inputLabel}>Step 4 *</div>
          <div style={{ fontSize: "14px" }}>Title</div>
          <TextField.CustomInput
            placeholder="Admission"
            name="step4"
            value={state.step4}
            onChange={handleChange}
            style={{ ...styles.textfieldStyle, fontSize: "14px" }}
          />
          <Layout.Spacer height={10} />
          <div style={{ fontSize: "14px" }}>Description</div>
          <TextField.CustomTextArea
            rows={4}
            name="step4Details"
            value={state.step4Details}
            onChange={handleChange}
            placeholder="Step details"
            style={styles.textBox}
          />
        </div>
      </div>

      <Layout.Spacer height={40} />
      <div style={styles.rowStyle}>
        <Text.Heading
          style={styles.programNumber}
          textAlign="left"
          text="Checklist *"
        />

        <Button.Primary
          style={{ ...styles.buttonStyle, margin: "0px" }}
          onClick={handleAddMoreChecklist}
        >
          Add Checklist
        </Button.Primary>
      </div>
      <div style={styles.costTitleContainer}>
        {state.checklist.map((item, index) => (
          <div style={styles.flexRowMarginBlock} key={index}>
            <Text.Heading
              style={styles.labelStyle}
              textAlign="left"
              text={`${index + 1}: `}
            />
            <TextField.CustomInput
              placeholder="demo"
              label=""
              value={item}
              onChange={(e) => {
                let newValue = [...state.checklist];
                newValue[index] = e.target.value;
                setState({
                  ...state,
                  checklist: newValue,
                });
              }}
              style={{
                ...styles.textfieldStyle,
                ...styles.mx10,
              }}
            />
            {state.checklist.length > 1 && index !== 0 && (
              <IoMdCloseCircle
                color={Colors.Danger}
                fontSize={30}
                cursor={"pointer"}
                onClick={() => handleRemoveChecklist(index)}
              />
            )}
          </div>
        ))}
      </div>
      {error && (
        <div style={styles.flexColumnCenter}>
          <Layout.Spacer height={20} />
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"500"}
            color={Colors.Danger}
            text={error}
          />
        </div>
      )}

      <Layout.Spacer height={20} />
      <div style={styles.flexColumnCenter}>
        <Button.Primary
          style={styles.buttonStyle}
          onClick={handleNext}
          disabled={saveButtonDisabled}
        >
          Save/Continue
        </Button.Primary>
      </div>

      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => setShowModal(false)}
          closeIcon={true}
          className="ant-modal"
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={styles.modalIcon}
            ></Lottie>
            <div style={styles.modalText}>Successful!</div>
            <Button.Primary
              style={styles.modalButtonStyle}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Done
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
    </div>
  );
}
export default ApplicationJourney;
