import { Colors } from "../../config";

const styles = {
  otpFormContainer: {
    width: "100%",
    margin: "0 auto",
  },
  otpCodeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  codeInput: {
    border: `2px solid #ccc`,
    borderRadius: "5px",
    fontSize: "38px",
    color: Colors.Primary,
    fontWeight: "500",
    height: "70px",
    width: "70px",
    margin: "0 10px",
    textAlign: "center",
  },
  otpErrorText: {
    fontSize: "16px",
    color: "red",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "center",
  },

  resendContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "auto",
  },
  resendLabel: { color: Colors.LabelText, fontSize: "18px" },
  counterText: {
    marginLeft: "5px",
    fontSize: "18px",
    color: "red",
  },
  resendText: {
    textDecoration: "none",
    color: Colors.Primary,
    fontSize: "18px",
    marginLeft: "5px",
    cursor: "pointer",
  },
  buttonStyle: {
    width: "100%",
    backgroundColor: Colors.Primary,
    borderRadius: "15px",
    fontFamily: "Montserrat",
  },
};

export default styles;
