import { useSelector } from "react-redux";

import { Images, Colors } from "../../config";
import { Layout, convertStringToUSD } from "../../components";

import styles from "./styles";

function PostGraduation({ data }) {
  const currency = useSelector((state) => state.converter.usd);

  const postGrad = [
    {
      id: 1,
      image: Images.Placement,
      value: data?.placementRate !== "NA" ? `${data?.placementRate}%` : "NA",
      title: "Placement Rate",
    },
    {
      id: 2,
      image: Images.PostGradSalary,
      value: !currency
        ? data?.graduateSalary
          ? data?.graduateSalary
          : "NA"
        : convertStringToUSD(
            data?.graduateSalary ? data?.graduateSalary : "NA"
          ),
      title: "Average Annual Fresh Graduate Salary",
    },
  ];

  const pathwayUpperPart = [
    {
      value: (
        <div style={styles.studentPathwayUpperPart}>
          <p style={styles.studentPathwayUpperPartText}>Education</p>
        </div>
      ),
    },
    {
      value: (
        <div style={styles.visaProcessingValue}>
          <img src={Images.Graduation} height={40} width={40} />
        </div>
      ),
    },
    {
      value: (
        <div style={styles.studentPathwayUpperPart}>
          <p style={styles.studentPathwayUpperPartText}>Work</p>
        </div>
      ),
    },
  ];
  const pathwayLowerPart = [
    {
      value: (
        <div style={styles.visaProcessingValue}>
          {data?.graduation ? `(${data?.graduation})` : "NA"}
        </div>
      ),
    },
    {
      value: <div style={styles.visaProcessingValue}>Graduation</div>,
    },
    {
      value: (
        <div style={styles.visaProcessingValue}>
          {data?.work ? `(${data?.work})` : "NA"}
        </div>
      ),
    },
  ];

  return (
    <div>
      <p style={styles.uniDetailsHeader}>Post Graduation</p>
      <div style={styles.flexSpaceBetween}>
        {postGrad.map((item) => (
          <div
            style={{
              ...styles.postGraduation,
              marginRight: item.id === 2 ? "0px" : "50px",
            }}
            key={item.id}
          >
            <div style={styles.flexSpaceBetween}>
              <div style={styles.visaDurationImageContainer}>
                <img src={item.image} height={65} width={65} />
              </div>
              <div style={styles.visaDurationTextContainer}>
                <p
                  style={{
                    ...styles.uniDetailsValue,
                    marginBottom: "0px",
                    fontWeight: "700",
                  }}
                >
                  {item.value}
                </p>
                <p style={styles.visaDurationText}>{item.title}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Layout.Spacer height={30} />
      <p style={{ ...styles.uniDetailsHeader, width: "400px" }}>
        Student Pathway after Graduation
      </p>
      <div style={styles.studentPathwayContainer}>
        <div style={{ display: "flex" }}>
          <div style={styles.visaProcessingLeftPart}></div>
          <div style={styles.visaProcessingMiddlePart}>
            <div style={styles.visaProcessingMiddleUpperPart}>
              {pathwayUpperPart?.map((item, index) => (
                <div key={index} style={{ width: "100%" }}>
                  {item.value}
                </div>
              ))}
            </div>
            <div style={styles.studentPathwayMiddleLowerPart}>
              {pathwayLowerPart?.map((item, index) => (
                <div key={index} style={{ width: "100%" }}>
                  {item.value}
                </div>
              ))}
            </div>
          </div>
          <div style={styles.visaProcessingRightPart}>
            <img src={Images.Visa} height={60} width={60} />
          </div>
        </div>
        <div
          style={{
            ...styles.flexSpaceBetween,
            marginTop: "20px",
          }}
        >
          <div
            style={{
              ...styles.visaProcessingBottomDivText,
              paddingLeft: "5%",
            }}
          >
            Admission
          </div>
          <div
            style={{
              ...styles.visaProcessingBottomDivText,
              paddingRight: "5%",
              color: Colors.Primary,
            }}
          >
            Citizensip
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostGraduation;
