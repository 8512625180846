import { put } from "redux-saga/effects";

import { ConverterAction } from "../actions";

export default class ConverterMiddleware {
  static *updateConverter({ payload, cb }) {
    try {
      yield put(ConverterAction.updateConverterSuccess(payload.usd));
    } catch (err) {
      yield put(ConverterAction.updateConverterFailure());
    }
  }
}
