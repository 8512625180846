import moment from "moment";

import { AiFillRightCircle, AiOutlineBell } from "react-icons/ai";

import { MdLocationPin } from "react-icons/md";
import Layout from "../Layout";
import { Images, Colors } from "../../config";

import Text from "../Text";

import styled from "styled-components";
import styles from "./styles";

function CustomRowTable({
  data,
  university,
  handleClick,
  tracker,
  announcement,
  handleReadAnnouncement,
  handleShowAnnouncement,
}) {
  if (university) {
    return (
      <RowDiv onClick={handleClick}>
        <span>
          <img
            src={
              university?.profilePic
                ? university?.profilePic
                : Images.UniversityDefault
            }
            alt="uni"
          />
          <span style={{ marginLeft: "15px" }}>
            <Text.Heading
              style={styles.trackerUniName}
              textAlign="left"
              textTransform="capitalize"
              text={university.name}
            />
            <Layout.Spacer height={5} />
            <span>
              <MdLocationPin
                color={Colors.GrayText}
                style={{ marginRight: "5px" }}
              />
              <Text.Heading
                style={styles.trackerDate}
                textAlign="left"
                textTransform="capitalize"
                text={university.location}
              />
            </span>
          </span>
        </span>
        <span>
          <AiFillRightCircle
            fontSize={"26px"}
            color={Colors.Primary}
            onClick={handleClick}
          />
        </span>
      </RowDiv>
    );
  }
  if (tracker) {
    return (
      <div onClick={handleClick} style={styles.trackerRowContainer}>
        <div style={{ display: "flex", width: "100%" }}>
          <div>
            <img
              src={
                tracker?.universityId?.profilePic
                  ? tracker?.universityId?.profilePic
                  : Images.UniversityDefault
              }
              alt="uni"
              style={styles.trackerImage}
            />
          </div>
          <div style={{ marginLeft: "15px" }}>
            <div style={styles.trackerUniName}>
              {tracker?.universityId?.name}
            </div>
            <Layout.Spacer height={5} />
            <div style={styles.trackerDate}>
              {"Last Updated: " +
                moment(tracker?.addedAt).format("DD MMMM YYYY")}
            </div>
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <div style={styles.trackerDeadline}>Deadline</div>
          <Layout.Spacer height={5} />
          <div style={styles.trackerDate}>
            {moment(tracker?.deadline).format("DD MMMM YYYY")}
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <div style={styles.trackerDeadline}>Result Date</div>
          <Layout.Spacer height={5} />
          <div style={styles.trackerDate}>
            {tracker?.resultDate
              ? moment(tracker?.resultDate).format("DD MMMM YYYY")
              : "No Data"}
          </div>
        </div>
        <div style={{ width: "5%" }}>
          <AiFillRightCircle
            fontSize={"26px"}
            color={Colors.Primary}
            onClick={handleClick}
          />
        </div>
      </div>
    );
  }
  if (announcement) {
    return (
      <div onClick={handleClick} style={styles.trackerRowContainer}>
        <div style={{ display: "flex", width: "100%" }}>
          <div>
            <img
              src={
                data?.universityAssociatedWith?.profilePic
                  ? data?.universityAssociatedWith.profilePic
                  : Images.AnnouncementIcon
              }
              alt="uni"
              style={styles.trackerImage}
            />
          </div>
          <div style={{ marginLeft: "15px" }}>
            <div style={styles.trackerUniName}>{data?.subject}</div>
            <Layout.Spacer height={5} />
            <div style={styles.trackerDate}>
              {data?.message?.length > 45
                ? data?.message?.slice(0, 45) + "..."
                : data?.message}
            </div>
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <div style={styles.trackerDeadline}>Announcement Time</div>
          <Layout.Spacer height={5} />
          <div style={styles.trackerDate}>
            {moment(new Date(data?.createdAt)).fromNow()}
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <div style={styles.trackerDeadline}>Expiry Date</div>
          <Layout.Spacer height={5} />
          <div style={styles.trackerDate}>
            {moment(data?.expiryDate).format("DD MMMM YYYY")}
          </div>
        </div>

        <span style={styles.flexRowCenter}>
          {announcement && (
            <span
              style={
                !data?.read ? styles.iconDivSecondary : styles.iconDivPrimary
              }
              onClick={() =>
                announcement && !data?.read
                  ? handleReadAnnouncement(data)
                  : null
              }
            >
              {!data?.read ? (
                <AiOutlineBell color={Colors.Primary} />
              ) : (
                <AiOutlineBell color="white" />
              )}
            </span>
          )}

          <AiFillRightCircle
            fontSize={"30px"}
            color={Colors.Primary}
            onClick={() => handleShowAnnouncement(data)}
          />
        </span>
      </div>
    );
  }
}

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  span:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: contain;
      background-color: white;
    }
    span {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      span:first-of-type {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1px;
        color: #3939398a;
      }
      span:last-of-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        color: #3939398a;
      }
    }
  }
  span:last-of-type {
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }
`;

export default CustomRowTable;
