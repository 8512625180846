import {
  GET_STUDENTS,
  GET_STUDENTS_FAILURE,
  GET_STUDENTS_SUCCESS,
  GET_ONBOARDED_STUDENTS,
  GET_ONBOARDED_STUDENTS_SUCCESS,
  GET_ONBOARDED_STUDENTS_FAILURE,
  GET_STUDENT_DETAILS,
  GET_STUDENT_DETAILS_FAILURE,
  GET_STUDENT_DETAILS_SUCCESS,
} from "../constants";

export default class StudentAction {
  static getStudents(payload, cb) {
    return {
      type: GET_STUDENTS,
      payload,
      cb,
    };
  }
  static getStudentsSuccess(payload) {
    return {
      type: GET_STUDENTS_SUCCESS,
      payload,
    };
  }
  static getStudentsFailure(payload) {
    return {
      type: GET_STUDENTS_FAILURE,
      payload,
    };
  }
  static getOnboardedStudents(payload, cb) {
    return {
      type: GET_ONBOARDED_STUDENTS,
      payload,
      cb,
    };
  }
  static getOnboardedStudentsSuccess(payload) {
    return {
      type: GET_ONBOARDED_STUDENTS_SUCCESS,
      payload,
    };
  }
  static getOnboardedStudentsFailure(payload) {
    return {
      type: GET_ONBOARDED_STUDENTS_FAILURE,
      payload,
    };
  }
  static getStudentDetails(id, cb) {
    return {
      type: GET_STUDENT_DETAILS,
      id,
      cb,
    };
  }
  static getStudentDetailsSuccess(payload) {
    return {
      type: GET_STUDENT_DETAILS_SUCCESS,
      payload,
    };
  }
  static getStudentDetailsFailure(payload) {
    return {
      type: GET_STUDENT_DETAILS_FAILURE,
      payload,
    };
  }
}
