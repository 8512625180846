import { useNavigate } from "react-router";

import styled from "styled-components";
import { Layout, Text } from "..";

import { Colors, Images } from "../../config";

import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import styles from "./styles";

export const HeaderSection = ({ title, subtitle }) => {
  return (
    <TextArea>
      <img
        src={Images.FullLogo}
        alt="Icon"
        style={styles.alignCenter}
        height={50}
      />
      <Layout.Spacer height={40} />
      <Text.Heading
        fontSize={"30px"}
        fontWeight={"500"}
        color={Colors.Primary}
        text={title}
        textAlign="left"
      />
      {subtitle ? (
        <Text.Heading
          fontSize={"16px"}
          fontWeight={"500"}
          color={Colors.BlackText}
          text={subtitle}
          textAlign="left"
        />
      ) : null}
    </TextArea>
  );
};

const LoginLayout = ({ children, userRoles, handleUserRoles, backLink }) => {
  const navigate = useNavigate();

  const backlink = backLink || "/login";
  return (
    <div style={styles.background}>
      <video style={styles.video} autoPlay muted loop>
        <source src={Images.BackgroundSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div style={styles.itemCenterjustifyCenter}>
        <div style={styles.backgroundOpacity}></div>
        <div style={styles.card}>
          {userRoles !== null && (
            <span
              style={styles.backButtonLogin}
              onClick={() => navigate(backlink)}
            >
              <BsFillArrowLeftCircleFill
                style={{
                  fontSize: "30px",
                  cursor: "pointer",
                  color: Colors.Primary,
                }}
                onClick={handleUserRoles}
              />
            </span>
          )}
          <div style={styles.carChildernContainer}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;

const TextArea = styled.div`
  font-size: 2.2rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`;
