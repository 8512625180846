import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Colors } from "../../config";

const Circular = ({
  size,
  color,
  sx,
  loaderStyle,
  containerStyle,
  ...props
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        sx={sx}
        size={size}
        color={color}
        style={{
          color: Colors.Primary,
        }}
        {...props}
      />
    </div>
  );
};

export default Circular;
