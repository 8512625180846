import moment from "moment";
import { AiFillRightCircle } from "react-icons/ai";

import { Text, Layout } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

function CardRow({ data }) {
  return (
    <div style={styles.cardRowContainer}>
      <div style={styles.leftSide}>
        <img
          src={data?.universityId?.profilePic}
          alt="application"
          style={styles.cardImageStyle}
        />
        <div style={styles.applicationNameContainer}>
          <Text.Heading
            style={styles.cardHeading}
            text={`${data?.instituteName} Application`}
          />
          <Text.Heading
            style={styles.cardSubHeading}
            text={data?.instituteName}
          />
        </div>
      </div>
      <div style={styles.flexColumn}>
        <Text.Heading style={styles.cardHeading} text="Date of Submission" />
        <Text.Heading
          style={styles.cardSubHeading}
          text={moment(data?.dateOfApplication).format("DD MMMM YYYY")}
        />
      </div>
      <div>
        <AiFillRightCircle fontSize={"30px"} color={Colors.Primary} />
      </div>
    </div>
  );
}

export default CardRow;
