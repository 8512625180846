import { useSelector } from "react-redux";

import { Layout, convertStringToUSD } from "../../components";
import { Images, Colors } from "../../config";

import "./styleoverwrite.css";
import styles from "./styles";

function VisaInformation({ data }) {
  const currency = useSelector((state) => state.converter.usd);

  const visaReq = [
    {
      id: 1,
      image: Images.MoneyBag,
      title: "Cost of Application",
      value: !currency
        ? data?.costOfApplication
        : convertStringToUSD(data?.costOfApplication),
    },
    {
      id: 2,
      image: Images.Medical,
      title: "Medical Fees",
      value: !currency
        ? data?.medicalFees
        : convertStringToUSD(data?.medicalFees),
    },
    {
      id: 3,
      image: Images.Misc,
      title: "Miscellaneous Fees",
      value: !currency
        ? data?.miscellFees
        : convertStringToUSD(data?.miscellFees),
    },
  ];

  const visaProcessingImage = [
    { id: 1, image: Images.Processing },
    { id: 2, image: Images.Interview },
  ];
  const visaProcessingValues = [
    {
      id: 1,
      value: data?.processing === "NA" ? "case to case" : data?.processing,
    },
    {
      id: 2,
      value: "Processing",
    },
    {
      id: 3,
      value: data?.interview === "NA" ? "case to case" : data?.interview,
    },
    {
      id: 4,
      value: "Interview",
    },
    {
      id: 5,
      value: data?.studentVisa === "NA" ? "case to case" : data?.studentVisa,
    },
  ];
  return (
    <div>
      <p style={styles.uniDetailsHeader}>Visa Requirements</p>
      <div style={styles.flexSpaceBetween}>
        <div style={styles.visaReqConatiner}>
          <div
            style={{
              width: "100%",
              paddingLeft: "30px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  ...styles.visaReqValuesContainer,
                  borderRight: "1px solid #98989880",
                }}
              >
                {visaReq.map((item) => (
                  <div style={{ marginBottom: "10px" }} key={item.id}>
                    <span>
                      <img
                        src={item.image}
                        style={{
                          ...styles.uniDetailsIcon,
                          height: "20px",
                          width: "20px",
                          marginBottom: "5px",
                        }}
                        alt=""
                      />
                    </span>
                    <span
                      style={{
                        ...styles.uniDetailsValue,
                        fontSize: "15px",
                        paddingLeft: "20px",
                        fontWeight: "700",
                      }}
                    >
                      {item.title}
                    </span>
                  </div>
                ))}
              </div>

              <div style={styles.visaReqValuesContainer}>
                {visaReq.map((item) => (
                  <div style={{ textAlign: "center" }} key={item.id}>
                    <p style={styles.visaReqValue}>{item.value}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div style={styles.visaDurationContainer}>
          <div style={styles.flexSpaceBetween}>
            <div style={styles.visaDurationImageContainer}>
              <img src={Images.Time} height={60} width={60} alt="" />
            </div>
            <div style={styles.visaDurationTextContainer}>
              <p
                style={{
                  ...styles.uniDetailsValue,
                  marginBottom: "0px",
                  fontWeight: "500",
                }}
              >
                {data?.durationOfVisa !== "NA"
                  ? `${data?.durationOfVisa}`
                  : "NA"}
              </p>
              <p style={styles.visaDurationText}>Duration of Visa</p>
            </div>
          </div>
        </div>
      </div>
      <Layout.Spacer height={30} />

      <p style={styles.uniDetailsHeader}>Visa Processing Journey</p>
      <p style={{ fontSize: "12px", fontWeight: "500" }}>
        Below is the representation of the visa processing journey for student.
      </p>
      <div style={styles.visaProcessingContainer}>
        <div style={{ display: "flex" }}>
          <div style={styles.visaProcessingLeftPart}></div>
          <div style={styles.visaProcessingMiddlePart}>
            <div style={styles.visaProcessingMiddleUpperPart}>
              {visaProcessingImage?.map((item) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    marginBottom: "5px",
                    paddingLeft: item.id === 1 ? "88px" : "0px",
                    paddingRight: item.id === 2 ? "88px" : "0px",
                  }}
                  key={item.id}
                >
                  <img src={item.image} height={40} width={40} />
                </div>
              ))}
            </div>
            <div style={styles.visaProcessingMiddleLowerPart}>
              {visaProcessingValues.map((item) => (
                <div style={styles.visaProcessingValue} key={item.id}>
                  {item.value}
                </div>
              ))}
            </div>
          </div>
          <div style={styles.visaProcessingRightPart}>
            <img src={Images.Visa} height={60} width={60} />
          </div>
        </div>
        <div
          style={{
            ...styles.flexSpaceBetween,
            marginTop: "10px",
          }}
        >
          <div
            style={{
              ...styles.visaProcessingBottomDivText,
              paddingLeft: "4%",
            }}
          >
            Application
          </div>
          <div
            style={{
              ...styles.visaProcessingBottomDivText,
              paddingRight: "4%",
              color: Colors.Primary,
            }}
          >
            Student Visa
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisaInformation;
