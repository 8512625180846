import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { Pagination } from "antd";

import {
  AiFillRightCircle,
  AiOutlineCheckSquare,
  AiOutlineCloseSquare,
} from "react-icons/ai";
import { MdLocationPin } from "react-icons/md";

import { RequestAction } from "../../store/actions";

import { Colors } from "../../config";
import { Layout, Table, Text } from "../../components";

import styled from "styled-components";
import styles from "./styles";

function Requests() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const requests = useSelector((state) => state.request);

  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    const payload = {
      limit,
      offset,
    };
    dispatch(RequestAction.getRequests(payload));
  }, []);

  const handleChangeStatus = (id, status) => {
    const payload = {
      id,
      status,
    };
    dispatch(
      RequestAction.changeRequestStatus(payload, () => {
        const payload = {
          limit,
          offset,
        };
        dispatch(RequestAction.getRequests(payload));
      })
    );
  };

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * limit;
    setOffset(offset);
    setPage(pageNumber);
    const payload = {
      limit,
      offset,
    };
    dispatch(RequestAction.getRequests(payload));
  };

  return (
    <div>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Requests"
      />

      <Layout.Spacer height={20} />
      <Table.AntDesign
        rowKey={(record) => record._id}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            width: 450,
            render: (text, record) => (
              <ColumnDiv>
                <img src={record?.profilePic} alt="img" />
                <span>
                  <Text.Heading
                    fontSize="12px"
                    fontWeight="600"
                    color="#081C15"
                    textTransform="capitalize"
                    textAlign="left"
                    text={record?.name}
                  />
                  <span>
                    <MdLocationPin
                      color="#474747"
                      style={{ marginRight: "5px" }}
                    />
                    <Text.Heading
                      fontSize="10px"
                      fontWeight="400"
                      color="#474747"
                      textTransform="capitalize"
                      textAlign="left"
                      text={record?.location}
                    />
                  </span>
                </span>
              </ColumnDiv>
            ),
          },
          {
            title: "QS World Ranking",
            dataIndex: "QSWorldRanking",
            align: "center",
            width: 200,
            onCell: (record) => ({
              onClick: () => navigate(`/universities/${record._id}/general`),
            }),
            render: (text, record) => (
              <div style={styles.universityTableText}>
                {record?.summary?.qsWorldRanking}
              </div>
            ),
          },
          {
            title: "Average Fee",
            dataIndex: "feeStructure",
            align: "center",
            render: (text, record) => (
              <span style={styles.universityTableText}>
                {record?.summary?.fee_structure}
              </span>
            ),
          },
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            align: "center",
            width: 220,
            render: (text, record) => (
              <span style={styles.flexRowSpaceBetween}>
                <div style={{ marginLeft: "65px" }}>
                  <AiOutlineCheckSquare
                    color={Colors.Primary}
                    fontSize={"30px"}
                    cursor={"pointer"}
                    onClick={() => handleChangeStatus(record._id, "accepted")}
                  />
                  <AiOutlineCloseSquare
                    color={Colors.Red}
                    fontSize={"30px"}
                    cursor={"pointer"}
                    onClick={() => handleChangeStatus(record._id, "rejected")}
                  />
                </div>
                <AiFillRightCircle
                  color={Colors.Primary}
                  fontSize={"30px"}
                  cursor={"pointer"}
                  onClick={() =>
                    navigate(`/universities/${record._id}/general`)
                  }
                />
              </span>
            ),
          },
        ]}
        data={requests.requests}
        pagination={false}
        loading={requests.isLoading}
      />
      <Layout.Spacer height={20} />
      <div style={styles.flexCenter}>
        <Pagination
          responsive="true"
          showSizeChanger={false}
          hideOnSinglePage={false}
          current={page}
          pageSize={10}
          total={requests.count}
          onChange={handlePaginationChange}
        />
      </div>
    </div>
  );
}

export default Requests;

const ColumnDiv = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: contain;
    margin-right: 10px;
    background: white;
  }
  span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    span:last-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;
