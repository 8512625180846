import {
  GET_REQUESTS,
  GET_REQUESTS_FAILURE,
  GET_REQUESTS_SUCCESS,
  CHANGE_REQUEST_STATUS,
  CHANGE_REQUEST_STATUS_FAILURE,
  CHANGE_REQUEST_STATUS_SUCCESS,
} from "../constants";

export default class RequestAction {
  static getRequests(payload, cb) {
    return {
      type: GET_REQUESTS,
      payload,
      cb,
    };
  }
  static getRequestsSuccess(payload) {
    return {
      type: GET_REQUESTS_SUCCESS,
      payload,
    };
  }
  static getRequestsFailure(payload) {
    return {
      type: GET_REQUESTS_FAILURE,
      payload,
    };
  }
  static changeRequestStatus(payload, cb) {
    return {
      type: CHANGE_REQUEST_STATUS,
      payload,
      cb,
    };
  }
  static changeRequestStatusSuccess(payload) {
    return {
      type: CHANGE_REQUEST_STATUS_SUCCESS,
      payload,
    };
  }
  static changeRequestStatusFailure(payload) {
    return {
      type: CHANGE_REQUEST_STATUS_FAILURE,
      payload,
    };
  }
}
