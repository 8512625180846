import { useEffect, useState } from "react";

import { Colors, Images } from "../../config";
import { Layout, Text } from "../../components";

import styles from "./styles";

const findOcc = (arr, key) => {
  let arr2 = [];
  arr?.forEach((x) => {
    if (
      arr2.some((val) => {
        return val[key] === x[key];
      })
    ) {
      arr2.forEach((k) => {
        if (k[key] === x[key]) {
          k["occurrence"]++;
        }
      });
    } else {
      let a = {};
      a[key] = x[key];
      a["occurrence"] = 1;
      arr2.push(a);
    }
  });
  return arr2;
};

const findOccRegion = (arr, key) => {
  let arr2 = [];
  arr?.forEach((x) => {
    if (
      arr2.some((val) => {
        return val[key] === x[key];
      })
    ) {
      arr2.forEach((k) => {
        if (k[key] === x[key]) {
          k["occurrence"]++;
        }
      });
    } else {
      let a = {};
      a[key] = x[key];
      a["occurrence"] = 1;
      arr2.push(a);
    }
  });
  return arr2;
};

function General({ data, preferredProgram }) {
  const applicationsData = data?.map((item) => item.universityId);

  const [asia, setAsia] = useState("0");
  const [europe, setEurope] = useState("0");
  const [australia, setAustralia] = useState("0");
  const [southAmerica, setSouthAmerica] = useState("0");
  const [northAmerica, setNorthAmerica] = useState("0");
  const [africa, setAfrica] = useState("0");

  const applicationDataOccurence = findOcc(applicationsData, "location");

  useEffect(() => {
    const applicationDataOccurenceRegion = findOccRegion(
      applicationsData,
      "region"
    );
    applicationDataOccurenceRegion.map((occurence) => {
      if (occurence.region === "asia") {
        setAsia(occurence.occurrence);
      }
      if (occurence.region === "europe") {
        setEurope(occurence.occurrence);
      }
      if (occurence.region === "australia") {
        setAustralia(occurence.occurrence);
      }
      if (occurence.region === "north america") {
        setNorthAmerica(occurence.occurrence);
      }
      if (occurence.region === "south america") {
        setSouthAmerica(occurence.occurrence);
      }
      if (occurence.region === "africa") {
        setAfrica(occurence.occurrence);
      }
    });
  }, [applicationDataOccurence]);

  const maxOccurenceSorted = applicationDataOccurence.sort(
    (a, b) => b.occurrence - a.occurrence
  );

  const mapValues = [
    { id: 1, value: northAmerica, style: { top: "40%", left: "27%" } },
    { id: 2, value: southAmerica, style: { top: "70%", left: "33%" } },
    { id: 3, value: europe, style: { top: "40%", left: "50%" } },
    { id: 4, value: africa, style: { top: "60%", left: "53%" } },
    { id: 5, value: asia, style: { top: "40%", left: "65%" } },
    { id: 6, value: australia, style: { top: "75%", left: "78%" } },
  ];

  return (
    <>
      <Layout.Spacer height={20} />
      <div style={styles.flexRowCenter}>
        <div style={{ marginRight: "40px" }}>
          <div style={styles.cardContainer}>
            <div style={styles.flexColumnCenter}>
              <img src={Images.TotalApplication} alt="" width={100} />
              <Layout.Spacer height={5} />
              <Text.Heading
                fontSize={"16px"}
                fontWeight={"500"}
                color={Colors.BlackText2}
                text={"Total Applications"}
              />
              <Text.Heading
                fontSize="22px"
                color={Colors.Primary}
                fontWeight={"600"}
                textTransform="capitalize"
                text={data?.length}
              />
            </div>
          </div>
        </div>
        <div>
          <div style={styles.cardContainerRightSide}>
            <div style={styles.cardContainer}>
              <img src={Images.Exchange1} alt="" width={100} />
              <Layout.Spacer height={5} />
              <Text.Heading
                fontSize={"16px"}
                fontWeight={"500"}
                color={Colors.BlackText2}
                text={"Country of Preference"}
              />
              <Text.Heading
                fontSize="22px"
                color={Colors.Primary}
                fontWeight={"600"}
                textTransform="capitalize"
                text={maxOccurenceSorted[0]?.location || "-"}
              />
            </div>
            <div style={styles.cardContainer}>
              <div style={styles.flexColumnCenter}>
                <img src={Images.Graduation} alt="" width={100} />
                <Layout.Spacer height={5} />
                <Text.Heading
                  fontSize={"16px"}
                  fontWeight={"500"}
                  color={Colors.BlackText2}
                  text={"Program of Preference"}
                />
                <Text.Heading
                  fontSize="22px"
                  color={Colors.Primary}
                  fontWeight={"600"}
                  textTransform="capitalize"
                  text={preferredProgram || "-"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Layout.Spacer height={40} />
      <div style={styles.flexRowCenter}>
        <div style={styles.mapContainer}>
          <img src={Images.Map} alt="" width={850} height={450} />
          {mapValues?.map((item) => (
            <div
              style={{
                ...item.style,
                ...styles.mapCircle,
              }}
              key={item.id}
            >
              <h2 style={styles.mapValue}>{item.value}</h2>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default General;
