import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";
import moment from "moment";

import { AiFillRightCircle } from "react-icons/ai";
import { BsCheckCircleFill } from "react-icons/bs";
import { FcDocument } from "react-icons/fc";
import { IoMdCloseCircle } from "react-icons/io";

import { ApplicationAction } from "../../store/actions";

import { Button, Layout, Modal, Table, Text } from "../../components";
import { Colors } from "../../config";

import styles from "./styles";

function Applications() {
  const dispatch = useDispatch();

  const applications = useSelector((state) => state.application);

  const [showModal, setShowModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [application, setApplication] = useState(null);
  const [applicationType, setApplicationType] = useState("all");
  const [applicationData, setApplicationData] = useState([]);
  const [applicationCount, setApplicationCount] = useState(0);

  const applicationsData = [
    {
      name: "Total Applications",
      value: applications?.totalApplicationsCount,
      type: "all",
    },
    {
      name: "Approved Applications",
      value: applications?.approvedApplicationsCount,
      type: "approved",
    },
    {
      name: "Pending Applications",
      value: applications?.pendingApplicationsCount,
      type: "pending",
    },
    {
      name: "Declined Applications",
      value: applications?.declinedApplicationsCount,
      type: "declined",
    },
  ];

  useEffect(() => {
    if (applicationType === "declined") {
      setApplicationData(applications?.applications?.declinedApplications);
      setApplicationCount(applications.declinedApplicationsCount);
    } else if (applicationType === "approved") {
      setApplicationData(applications?.applications?.approvedApplications);
      setApplicationCount(applications.approvedApplicationsCount);
    } else if (applicationType === "all") {
      setApplicationData(applications?.applications?.allApplications);
      setApplicationCount(applications.totalApplicationsCount);
    } else {
      setApplicationData(applications?.applications?.pendingApplications);
      setApplicationCount(applications.pendingApplicationsCount);
    }
  }, [applications, applicationsData]);

  useEffect(() => {
    const payload = {
      limit,
      offset,
    };
    dispatch(ApplicationAction.getApplications(payload));
  }, []);

  const handleChangeApplicationStatus = (id, status) => {
    dispatch(
      ApplicationAction.changeApplicationStatus(id, status, () => {
        setShowModal(false);
        const payload = {
          limit,
          offset: 0,
        };
        dispatch(ApplicationAction.getApplications(payload));
        setPage(1);
      })
    );
  };

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * limit;
    setOffset(offset);
    setPage(pageNumber);
    const payload = {
      limit,
      offset: offset,
    };
    dispatch(ApplicationAction.getApplications(payload));
  };

  const appColumns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      width: 150,
      onCell: (record) => {
        return {
          onClick: () => {
            setShowModal(true);
            setApplication(record);
          },
        };
      },
      render: (text, record) => (
        <span style={{ ...styles.flexColumn, paddingLeft: "20px" }}>
          <Text.Heading
            style={styles.tableText}
            textAlign="left"
            text={record?.studentId?.name}
          />
          <Text.Heading
            fontSize={"10px"}
            fontWeight={"400"}
            color={Colors.GrayText}
            textAlign="left"
            textTransform="capitalize"
            text={`Grade ${record?.studentId?.grade}`}
          />
        </span>
      ),
    },
    {
      title: "University",
      dataIndex: "instituteName",
      align: "center",
      width: 200,
      onCell: (record) => {
        return {
          onClick: () => {
            setShowModal(true);
            setApplication(record);
          },
        };
      },
      render: (text) => <span style={styles.tableText}>{text}</span>,
    },
    {
      title: "Date of Submission",
      dataIndex: "dateOfApplication",
      align: "center",
      width: 100,
      onCell: (record) => {
        return {
          onClick: () => {
            setShowModal(true);
            setApplication(record);
          },
        };
      },
      render: (text, record) => (
        <span style={styles.tableText}>
          {moment(record?.dateOfApplication).format("DD MMM YYYY")}
        </span>
      ),
    },
  ];

  return (
    <>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Applications"
      />
      <Layout.Spacer height={20} />

      <div style={styles.applicationDivContainer}>
        {applicationsData.map((item, index) => (
          <div
            style={{
              ...styles.applicationDiv,
              border:
                applicationType === item?.type
                  ? `1px solid ${Colors.Primary}`
                  : "",
            }}
            key={index}
            onClick={() => setApplicationType(item?.type)}
          >
            <Text.Heading
              fontSize="26px"
              color={Colors.Black}
              fontWeight={"600"}
              text={item.value}
            />
            <Text.Heading
              fontSize={"14px"}
              fontWeight={"600"}
              color={Colors.Primary}
              text={item.name}
            />
            <Layout.Spacer height={20} />
          </div>
        ))}
      </div>
      <Layout.Spacer height={20} />

      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text={
          applicationType === "all"
            ? "All Applications"
            : applicationType === "pending"
            ? "Application Approval Requests"
            : applicationType === "declined"
            ? "Declined Applications"
            : "Approved Applciations"
        }
      />

      <Layout.Spacer height={20} />
      <Table.AntDesign
        rowKey={(record) => record._id}
        columns={[
          ...appColumns,
          {
            title: " ",
            dataIndex: "",
            align: "center",
            width: 50,
            render: (text, record) => {
              if (record.status === "rejected") {
                return (
                  <div style={{ color: "red", fontWeight: "600" }}>
                    Declined
                  </div>
                );
              } else if (record.status === "approved") {
                return (
                  <div style={{ color: Colors.Success, fontWeight: "600" }}>
                    Approved
                  </div>
                );
              } else {
                return (
                  <div style={styles.iconRowDiv}>
                    <BsCheckCircleFill
                      fontSize={"19px"}
                      cursor={"pointer"}
                      color={Colors.Primary}
                      onClick={() =>
                        handleChangeApplicationStatus(record?._id, "approved")
                      }
                    />
                    <IoMdCloseCircle
                      fontSize={"24px"}
                      cursor={"pointer"}
                      color={"#F6465E"}
                      onClick={() =>
                        handleChangeApplicationStatus(record?._id, "rejected")
                      }
                    />
                    <AiFillRightCircle
                      fontSize={"24px"}
                      cursor={"pointer"}
                      color={Colors.Primary}
                      onClick={() => {
                        setShowModal(true);
                        setApplication(record);
                      }}
                    />
                  </div>
                );
              }
            },
          },
        ]}
        loading={applications?.isLoading}
        data={applicationData}
        pagination={false}
      />
      <Layout.Spacer height={20} />
      <div style={styles.flexRowCenter}>
        <Pagination
          responsive="true"
          showSizeChanger={false}
          hideOnSinglePage={false}
          current={page}
          pageSize={10}
          total={applicationCount}
          onChange={handlePaginationChange}
        />
      </div>

      <Layout.Spacer height={20} />

      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => setShowModal(false)}
          closable={false}
          className="ant-modal"
        >
          <div>
            <Text.Heading
              fontSize={"18px"}
              fontWeight={"600"}
              color={Colors.Primary}
              text="Application Approval Request"
            />

            <Layout.Spacer height={20} />
            <div style={styles.grid}>
              <div style={styles.flexRowStart}>
                <Text.Heading
                  fontSize={"14px"}
                  fontWeight={"600"}
                  color={Colors.BlackText}
                  text="Name"
                />
              </div>
              <div style={{ textAlign: "left" }}>
                <Text.Heading
                  fontSize={"14px"}
                  fontWeight={"500"}
                  color={Colors.BlackText}
                  textTransform="capitalize"
                  text={application?.studentId?.name}
                />
              </div>
            </div>
            <Layout.Spacer height={5} />
            <div style={styles.grid}>
              <div style={styles.flexRowStart}>
                <Text.Heading
                  fontSize={"14px"}
                  fontWeight={"600"}
                  color={Colors.BlackText}
                  text="University name"
                />
              </div>
              <div style={{ textAlign: "left" }}>
                <Text.Heading
                  fontSize={"14px"}
                  fontWeight={"500"}
                  color={Colors.BlackText}
                  textTransform="capitalize"
                  text={application.instituteName}
                />
              </div>
            </div>
            <Layout.Spacer height={5} />

            <div style={styles.flexRowStart}>
              <Text.Heading
                fontSize={"14px"}
                fontWeight={"600"}
                color={Colors.BlackText}
                text="Application proof"
              />
            </div>

            <Layout.Spacer height={20} />
            <div style={styles.modalCard}>
              <a href={application.proofOfApplication}>
                <FcDocument color={Colors.Secondary2} fontSize={60} />
              </a>
              <Text.Heading
                fontSize={"16px"}
                fontWeight={"400"}
                color={Colors.BlackText}
                text="File attached. Click to open"
              />
            </div>
            <Layout.Spacer height={30} />
            <div style={{ ...styles.grid, gridGap: "20px" }}>
              <Button.Primary
                style={{
                  backgroundColor: Colors.Red4,
                  ...styles.modalButton,
                }}
                onClick={() =>
                  handleChangeApplicationStatus(application?._id, "rejected")
                }
              >
                Reject
              </Button.Primary>

              <Button.Primary
                style={{
                  backgroundColor: Colors.Primary,
                  ...styles.modalButton,
                }}
                onClick={() =>
                  handleChangeApplicationStatus(application?._id, "approved")
                }
              >
                Approve
              </Button.Primary>
            </div>
            <Layout.Spacer height={10} />
          </div>
        </Modal.Basic>
      )}
    </>
  );
}

export default Applications;
