import { Colors } from "../../config";

const styles = {
  trackerRowContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "20px",
    backgroundColor: Colors.LightGray,
    padding: "10px 15px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  trackerImage: {
    height: "40px",
    width: "40px",
    borderRadius: "50px",
    marginRight: "10px",
    backgroundColor: "white",
    objectFit: "contain",
  },
  trackerUniName: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#081C15",
    textTransform: "capitalize",
  },

  trackerDeadline: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#081C15",
  },
  trackerDate: {
    fontSize: "10px",
    fontWeight: "400",
    color: Colors.GrayText,
  },

  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  iconDivPrimary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "26px",
    width: "26px",
    borderRadius: "100%",
    marginRight: "5px",
    backgroundColor: Colors.Primary,
    border: `1px solid ${Colors.Primary}`,
  },
  iconDivSecondary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "26px",
    width: "26px",
    borderRadius: "100%",
    marginRight: "5px",
    backgroundColor: "transparent",
    border: `1px solid ${Colors.Primary}`,
  },
};

export default styles;
