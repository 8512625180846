import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { DatePicker } from "antd";

import { CgAttachment } from "react-icons/cg";

import { SchoolAction, BillingAction } from "../../store/actions";

import { Button, Layout, Text, TextField, Modal } from "../../components";
import { Colors, Utils } from "../../config";

import styles from "./styles";

const EditSchool = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const fileSelect = useRef();

  const id = location.pathname.split("/")[2];

  const [buttonText, setButtonText] = useState("Save");
  const [buttonDsiabled, setButtonDsiabled] = useState(false);
  const [error, setError] = useState("");
  const [state, setState] = useState({
    name: "",
    imageUrl: "",
    website: "",
    phoneNumber: "",
    email: "",
    location: "",
    noOfStudents: "",
    deptHead: "",
    linkedIn: "",
  });
  const [billingState, setBillingState] = useState({
    paymentType: "",
    subPeriod: "",
    amount: "",
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [validityDate, setValidityDate] = useState(null);
  const [modalError, setModalError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [invoiceButtonDisabled, setInvoiceButtonDisabled] = useState(false);
  const [invoiceButtonText, setInvoiceButtonText] = useState("Upload");

  useEffect(() => {
    dispatch(
      SchoolAction.getSchoolDetails(id, (res) => {
        setState({
          name: res?.name,
          imageUrl: res?.imageUrl,
          website: res?.website,
          phoneNumber: res?.phoneNumber,
          email: res?.email,
          location: res?.location,
          noOfStudents: res?.noOfStudents,
          deptHead: res?.deptHead,
          linkedIn: res?.linkedIn,
        });
      })
    );
    const payload = {
      schoolId: id,
    };
    dispatch(
      BillingAction.getBilling(payload, (res) => {
        setBillingState({
          paymentType: res?.updatedBilling?.paymentType,
          subPeriod: res?.updatedBilling?.subscriptionPeriod,
          amount: res?.updatedBilling?.amount,
        });
      })
    );
  }, []);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const handleBillingChange = (e) => {
    setBillingState({
      ...billingState,
      [e.target.name]: e.target.value,
    });
  };

  const defaultStates = () => {
    setButtonText("Save");
    setButtonDsiabled(false);
  };
  const defaultStatesModal = () => {
    setInvoiceButtonText("Upload");
    setInvoiceButtonDisabled(false);
  };

  const handleSubmit = () => {
    setButtonText("Saving...");
    setButtonDsiabled(true);
    if (
      ((billingState.paymentType === "stripe" ||
        billingState.paymentType === "manual") &&
        !billingState.amount) ||
      (billingState.paymentType === "trial" && !validityDate)
    ) {
      setError("Please fill all the required fields");
      defaultStates();
    } else {
      setError("");

      const payload = {
        schoolId: id,
        subsriptionPeriod: billingState.subPeriod,
        amount: billingState.amount,
        paymentType: billingState.paymentType,
        validUntilDate: validityDate,
      };

      dispatch(
        BillingAction.editBilling(payload, (res) => {
          if (res.status === 200) {
            Utils.showSnackBar({ message: "Data Updated" });
          } else {
            Utils.showSnackBar({
              message: "Something went wrong",
              severity: "error",
            });
          }
          defaultStates();
        })
      );
    }
  };

  const handleInvoiceSubmit = () => {
    setInvoiceButtonText("Uploading...");
    setInvoiceButtonDisabled(true);
    if (!selectedFile || !fromDate || !toDate) {
      setModalError("Please fill all the required fields");
      defaultStatesModal();
    } else {
      setError("");

      let payload = new FormData();
      payload.append("schoolId", id);
      payload.append("paidAt", fromDate);
      payload.append("valdUntil", toDate);
      payload.append("file", selectedFile);
      payload.append("amount", billingState.amount);

      dispatch(
        BillingAction.uploadInvoice(payload, () => {
          defaultStatesModal();
          setShowModal(false);
        })
      );
    }
  };

  return (
    <div>
      <div style={styles.justifyBetween}>
        <Text.Heading
          fontSize={"18px"}
          fontWeight={"600"}
          color={Colors.Primary}
          text="Edit School"
        />
        {billingState?.paymentType === "manual" && (
          <Button.Primary
            style={styles.uploadInvoiceButton}
            onClick={() => setShowModal(true)}
          >
            Upload Invoice
          </Button.Primary>
        )}
      </div>

      <div style={styles.flexCenter}>
        <img
          src={state?.imageUrl}
          alt="school"
          style={styles.addSchooolImage}
        />
      </div>

      <Layout.Spacer height={20} />
      <div style={styles.inputFieldsContainer}>
        <div style={styles.pointerNone}>
          <TextField.CustomInput
            placeholder="Greenwich Academy"
            label="School Name*"
            name="name"
            value={state.name}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div style={styles.pointerNone}>
          <TextField.CustomInput
            placeholder="www.greenwich.org"
            label="Website*"
            name="website"
            value={state.website}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div style={styles.pointerNone}>
          <label style={styles.labelStyle}>Phone number*</label>
          <PhoneInput
            inputProps={{
              name: "phone",
              required: true,
            }}
            country={"my"}
            placeholder="Phone Number*"
            excludeCountries={[]}
            value={state.phoneNumber}
            onChange={(phone) =>
              setState({
                ...state,
                phoneNumber: phone,
              })
            }
            containerClass="phone-container"
            inputClass="phone-input"
            countryCodeEditable={false}
          />
        </div>
        <div style={styles.pointerNone}>
          <TextField.CustomInput
            placeholder="contact@greenwich.edu"
            label="Email*"
            name="email"
            value={state.email}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>

        <div style={styles.pointerNone}>
          <TextField.CustomInput
            placeholder="Name of the Head of Department"
            label="Head of Department"
            name="deptHead"
            value={state.deptHead}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div style={styles.pointerNone}>
          <TextField.CustomInput
            placeholder="School LinkedIn Link"
            label="LinkedIn"
            name="linkedIn"
            value={state.linkedIn}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>

        <div style={styles.pointerNone}>
          <TextField.CustomInput
            placeholder="Greenwich, United States"
            label="Location*"
            name="location"
            value={state.location}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div style={styles.pointerNone}>
          <TextField.CustomInput
            placeholder="200"
            label="No. of Students"
            name="noOfStudents"
            value={state.noOfStudents}
            onChange={handleChange}
            style={styles.textfieldStyle}
          />
        </div>
        <div>
          <div style={{ color: "black" }}>Payment Method</div>
          <select
            value={billingState.paymentType}
            onChange={handleBillingChange}
            name="paymentType"
            style={{
              ...styles.textfieldStyle,
              padding: "0px 5px",
              outline: "none",
            }}
          >
            <option value="trial">Trial</option>
            <option value="manual">Manual</option>
            <option value="stripe">Stripe</option>
          </select>
        </div>

        {billingState.paymentType === "stripe" && (
          <div>
            <div style={{ color: "black" }}>Subscription Period</div>
            <select
              value={billingState.subPeriod}
              onChange={handleBillingChange}
              name="subPeriod"
              style={{
                ...styles.textfieldStyle,
                padding: "0px 5px",
                outline: "none",
              }}
            >
              <option value="1">Monthly</option>
              <option value="12">Yearly</option>
            </select>
          </div>
        )}
        {(billingState.paymentType === "stripe" ||
          billingState.paymentType === "manual") && (
          <div>
            <TextField.CustomInput
              placeholder="200"
              label="Amount (USD)*"
              name="amount"
              value={billingState.amount}
              onChange={handleBillingChange}
              style={styles.textfieldStyle}
            />
          </div>
        )}
        {billingState.paymentType === "trial" && (
          <div>
            <div style={{ color: "black" }}>Validity</div>
            <DatePicker
              style={styles.meetingDatePicker}
              onChange={(date, dateString) => {
                setValidityDate(dateString);
              }}
            />
          </div>
        )}
      </div>
      <Layout.Spacer height={20} />
      {error && (
        <div style={styles.flexCenter}>
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"500"}
            color={Colors.Danger}
            text={error}
          />
        </div>
      )}
      <Layout.Spacer height={30} />
      <div style={styles.flexCenter}>
        <Button.Primary
          style={styles.addSchoolButton}
          onClick={handleSubmit}
          disabled={buttonDsiabled}
        >
          {buttonText}
        </Button.Primary>
      </div>
      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => {
            setShowModal(false);
          }}
          closeIcon={true}
          className="ant-modal"
        >
          <Layout.Spacer height={20} />
          <Text.Heading
            fontSize={"24px"}
            fontWeight={"600"}
            color={Colors.Primary}
            text={"Upload Invoice"}
          />
          <div style={{ textAlign: "left" }}>
            <Text.Heading style={styles.boxHeader} text="Valid From" />
            <DatePicker
              style={styles.meetingDatePicker}
              onChange={(date, dateString) => {
                setFromDate(dateString);
              }}
            />
            <Layout.Spacer height={20} />
            <Text.Heading style={styles.boxHeader} text="Valid Till" />
            <DatePicker
              style={styles.meetingDatePicker}
              onChange={(date, dateString) => {
                setToDate(dateString);
              }}
            />
            <Layout.Spacer height={20} />
            <Text.Heading style={styles.boxHeader} text="Invoice" />
            <div style={{ width: "100%" }}>
              <div
                style={styles.invoiceFileInput}
                onClick={() => {
                  document.getElementById("getFile").click();
                }}
              >
                <input
                  style={{ display: "none" }}
                  type="file"
                  name="csv"
                  id="getFile"
                  ref={fileSelect}
                  onChange={(event) => {
                    setSelectedFile(event.target.files[0]);
                  }}
                  accept=".doc,.docx,.csv,.xlsx, .pdf"
                />
                <div>{selectedFile ? selectedFile.name : "Select a file"}</div>
                <div>
                  <CgAttachment color={Colors.Primary} />
                </div>
              </div>
            </div>
          </div>
          <Layout.Spacer height={20} />
          <div style={styles.flexCenter}>
            <Button.Primary
              style={styles.uploadInvoiceButton}
              onClick={handleInvoiceSubmit}
              disabled={invoiceButtonDisabled}
            >
              {invoiceButtonText}
            </Button.Primary>
          </div>
          <Layout.Spacer height={20} />
        </Modal.Basic>
      )}
    </div>
  );
};

export default EditSchool;
