import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ProfileAction } from "../../store/actions";

import { Layout, Text } from "../../components";
import { Colors, Images } from "../../config";

import styles from "./style";

function Profile() {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(ProfileAction.getUserProfile());
  }, []);

  const values = [
    { id: 1, title: "Full Name", value: profile?.user?.fullName },
    { id: 2, title: "Email", value: profile?.user?.email },
    { id: 3, title: "Phone Number", value: `+${profile?.user?.phoneNumber}` },
    { id: 4, title: "Account Type", value: profile?.user?.role },
  ];
  return (
    <>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="User Profile"
      />
      <Layout.Spacer height={60} />
      <div style={styles.profileContainer}>
        <div style={styles.justifyCenter}>
          <img
            src={
              profile?.user?.profileImage
                ? profile?.user?.profileImage
                : Images.Profile
            }
            alt="user"
            style={styles.profileImage}
            width={170}
          />
          <div style={styles.line}></div>
        </div>

        <div style={styles.flexColumn}>
          {values.map((item) => (
            <div key={item.id}>
              <Text.Heading
                width="200px"
                fontSize={"18px"}
                fontWeight={"600"}
                color={Colors.Primary}
                text={item.title}
              />
              <Layout.Spacer height={20} />
            </div>
          ))}
        </div>
        <div style={styles.flexColumn}>
          {values.map((item) => (
            <div key={item.id}>
              <Text.Heading
                fontSize={"18px"}
                fontWeight={"400"}
                color={Colors.BlackText}
                text={item.value}
                textTransform={
                  item.id === 1 || item.id === 4 ? "capitalize" : ""
                }
              />
              <Layout.Spacer height={20} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Profile;
