import { Navigate } from "react-router";

import {
  About,
  AddSchool,
  AddTracker,
  Announcements,
  AddAnnouncement,
  Applications,
  ContactUs,
  ChangePassword,
  Dashboard,
  EditUniversity,
  EditProfile,
  EditSchool,
  ForgotPassword,
  FAQs,
  Invoices,
  IndividualStudents,
  Payments,
  PaymentDetails,
  Profile,
  PrivacyPolicy,
  Requests,
  ResetPassword,
  SchoolDetails,
  Schools,
  SignIn,
  Support,
  SpecificTracker,
  StudentDetails,
  TermsAndCondition,
  Tracker,
  Universities,
  UniversityDetails,
  VerifyOTP,
} from "../../containers";

export const routes = {
  protected: {
    // home
    dashboard: "/home",

    // application
    applications: "/applications",

    // announcements
    announcements: "/announcements",
    addAnnouncement: "/announcements/add-announcement",

    // universities
    universities: "/universities",

    universityGeneralDetails: "/universities/:id/general",
    universityStudentLifeDetails: "/universities/:id/student-life",
    universityVisaDetails: "/universities/:id/visa",
    universityProgramsDetails: "/universities/:id/programs",
    universityFinanceDetails: "/universities/:id/finances",
    universityPostGraduationDetails: "/universities/:id/post-graduation",
    universityApplicationJourneyDetails:
      "/universities/:id/application-journey",
    universitySummaryDetails: "/universities/:id/summary",

    editUniversityGeneralDetails: "/edit-university/:id/",
    editUniversityStudentLifeDetails: "/edit-university/:id/student-life",
    editUniversityVisaDetails: "/edit-university/:id/visa",
    editUniversityProgramsDetails: "/edit-university/:id/programs",
    editUniversityFinanceDetails: "/edit-university/:id/finances",
    editUniversityPostGraduationDetails: "/edit-university/:id/post-graduation",
    editUniversityApplicationJourneyDetails:
      "/edit-university/:id/application-journey",
    editUniversitySummaryDetails: "/edit-university/:id/summary",

    // school
    addSchool: "/schools/add-school",
    schools: "/schools",
    schoolDetails: "/schools/:id",
    editSchool: "/edit-school/:id",
    studentDetails: "/schools/student-details/:id",

    // individual students
    individualStudents: "/individualStudents",

    // request
    requests: "/requests",

    // settings
    profile: "/profile",
    settings: "/settings",
    changePassword: "/settings/change-password",
    about: "/settings/about",
    privacyPolicy: "/privacypolicy",
    termsAndConditions: "/termsandconditions",

    contactUs: "/support",
    faqs: "/support/faqs",

    // payments
    payments: "/payments",
    paymentDetails: "/payments/details/:id",
    invoices: "/payments/invoices/:id",

    // tracker
    tracker: "/tracker",
    specificTracker: "/tracker/:id",
    addTracker: "/tracker/addTracker",
  },
  public: {
    login: "/login",
    forgotPassword: "/forgot-password",
    verifyCode: "/verify-code",
    resetPassword: "/reset-password",
  },
};

export const PROTECTED_ROUTES = [
  // home
  {
    path: routes.protected.dashboard,
    exact: false,
    component: <Dashboard />,
  },
  // applications
  {
    path: routes.protected.applications,
    exact: false,
    component: <Applications />,
  },
  // announcements
  {
    path: routes.protected.announcements,
    exact: false,
    component: <Announcements />,
  },
  {
    path: routes.protected.addAnnouncement,
    exact: false,
    component: <AddAnnouncement />,
  },
  // universities
  {
    path: routes.protected.universities,
    exact: false,
    component: <Universities />,
  },
  {
    path: routes.protected.universityGeneralDetails,
    exact: false,
    component: <UniversityDetails />,
  },
  {
    path: routes.protected.universityStudentLifeDetails,
    exact: false,
    component: <UniversityDetails />,
  },
  {
    path: routes.protected.universityApplicationJourneyDetails,
    exact: false,
    component: <UniversityDetails />,
  },
  {
    path: routes.protected.universityFinanceDetails,
    exact: false,
    component: <UniversityDetails />,
  },
  {
    path: routes.protected.universityPostGraduationDetails,
    exact: false,
    component: <UniversityDetails />,
  },
  {
    path: routes.protected.universityProgramsDetails,
    exact: false,
    component: <UniversityDetails />,
  },
  {
    path: routes.protected.universitySummaryDetails,
    exact: false,
    component: <UniversityDetails />,
  },
  {
    path: routes.protected.universityVisaDetails,
    exact: false,
    component: <UniversityDetails />,
  },
  {
    path: routes.protected.editUniversityGeneralDetails,
    exact: false,
    component: <EditUniversity />,
  },
  {
    path: routes.protected.editUniversityStudentLifeDetails,
    exact: false,
    component: <EditUniversity />,
  },
  {
    path: routes.protected.editUniversityApplicationJourneyDetails,
    exact: false,
    component: <EditUniversity />,
  },
  {
    path: routes.protected.editUniversityFinanceDetails,
    exact: false,
    component: <EditUniversity />,
  },
  {
    path: routes.protected.editUniversityPostGraduationDetails,
    exact: false,
    component: <EditUniversity />,
  },
  {
    path: routes.protected.editUniversityProgramsDetails,
    exact: false,
    component: <EditUniversity />,
  },
  {
    path: routes.protected.editUniversitySummaryDetails,
    exact: false,
    component: <EditUniversity />,
  },
  {
    path: routes.protected.editUniversityVisaDetails,
    exact: false,
    component: <EditUniversity />,
  },
  // payments
  {
    path: routes.protected.payments,
    exact: false,
    component: <Payments />,
  },
  {
    path: routes.protected.paymentDetails,
    exact: false,
    component: <PaymentDetails />,
  },
  {
    path: routes.protected.invoices,
    exact: false,
    component: <Invoices />,
  },
  // schools
  {
    path: routes.protected.schools,
    exact: false,
    component: <Schools />,
  },
  {
    path: routes.protected.editSchool,
    exact: false,
    component: <EditSchool />,
  },
  {
    path: routes.protected.addSchool,
    exact: false,
    component: <AddSchool />,
  },
  {
    path: routes.protected.schoolDetails,
    exact: false,
    component: <SchoolDetails />,
  },
  {
    path: routes.protected.studentDetails,
    exact: false,
    component: <StudentDetails />,
  },
  {
    path: routes.protected.requests,
    exact: false,
    component: <Requests />,
  },
  // individual students
  {
    path: routes.protected.individualStudents,
    exact: false,
    component: <IndividualStudents />,
  },
  // trackers
  {
    path: routes.protected.tracker,
    exact: false,
    component: <Tracker />,
  },
  {
    path: routes.protected.specificTracker,
    exact: false,
    component: <SpecificTracker />,
  },
  {
    path: routes.protected.addTracker,
    exact: false,
    component: <AddTracker />,
  },
  // settings
  {
    path: routes.protected.profile,
    exact: false,
    component: <Profile />,
  },
  {
    path: routes.protected.settings,
    exact: false,
    component: <EditProfile />,
  },
  {
    path: routes.protected.changePassword,
    exact: false,
    component: <ChangePassword />,
  },
  {
    path: routes.protected.about,
    exact: false,
    component: <About />,
  },
  {
    path: routes.protected.termsAndConditions,
    exact: false,
    component: <TermsAndCondition />,
  },
  {
    path: routes.protected.privacyPolicy,
    exact: false,
    component: <PrivacyPolicy />,
  },
  {
    path: routes.protected.support,
    exact: false,
    component: <Support />,
  },
  {
    path: routes.protected.contactUs,
    exact: false,
    component: <ContactUs />,
  },
  {
    path: routes.protected.faqs,
    exact: false,
    component: <FAQs />,
  },
  {
    path: "*",
    exact: false,
    component: <Navigate to={routes.protected.dashboard} />,
  },
];

export const PUBLIC_ROUTES = [
  {
    path: routes.public.login,
    exact: false,
    component: <SignIn />,
  },
  {
    path: routes.public.forgotPassword,
    exact: false,
    component: <ForgotPassword />,
  },
  {
    path: routes.public.verifyCode,
    exact: false,
    component: <VerifyOTP />,
  },
  {
    path: routes.public.resetPassword,
    exact: false,
    component: <ResetPassword />,
  },
  {
    path: "*",
    exact: false,
    component: <Navigate to={routes.public.login} />,
  },
];
