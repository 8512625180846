import {
  SIGNIN,
  SIGNIN_FAILURE,
  SIGNIN_SUCCESS,
  LOGOUT,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  LOADER_FALSE,
  LOADER_TRUE,
} from "../constants";

const initialState = {
  isLoggedIn: localStorage.getItem("authUser") ? true : false,
  user: localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser"))
    : {},
  isLoading: false,
  error: null,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNIN:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case SIGNIN_SUCCESS:
      state = {
        ...state,
        user: { ...action.payload },
        isLoggedIn: true,
        isLoading: false,
      };
      break;
    case SIGNIN_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case FORGOT_PASSWORD:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case FORGOT_PASSWORD_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case FORGOT_PASSWORD_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case VERIFY_OTP:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case VERIFY_OTP_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case VERIFY_OTP_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case RESET_PASSWORD:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case RESET_PASSWORD_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case LOGOUT:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case LOGOUT_SUCCESS:
      state = {
        user: {},
        isLoggedIn: false,
        isLoading: false,
      };
      break;
    case LOGOUT_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case LOADER_TRUE:
      state = {
        ...state,
        isLoading: true,
      };
      break;

    case LOADER_FALSE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }

  return state;
}
