import {
  ADD_ANNOUNCEMENT,
  ADD_ANNOUNCEMENT_FAILURE,
  ADD_ANNOUNCEMENT_SUCCESS,
  CHANGE_ANNOUNCEMENT_STATUS,
  CHANGE_ANNOUNCEMENT_STATUS_FAILURE,
  CHANGE_ANNOUNCEMENT_STATUS_SUCCESS,
  GET_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS_FAILURE,
  GET_ANNOUNCEMENTS_SUCCESS,
} from '../constants';

const initialState = {
  count: null,
  announcements: [],
  announcement: {},
  isLoading: false,
  error: null,
};

export default function AnnouncementReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ANNOUNCEMENT:
    case GET_ANNOUNCEMENTS:
    case CHANGE_ANNOUNCEMENT_STATUS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case ADD_ANNOUNCEMENT_SUCCESS:
    case CHANGE_ANNOUNCEMENT_STATUS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case GET_ANNOUNCEMENTS_SUCCESS:
      state = {
        ...state,
        count: action?.payload?.count,
        announcements: action?.payload?.data,
        announcement: {},
        isLoading: false,
      };
      break;
    case ADD_ANNOUNCEMENT_FAILURE:
    case GET_ANNOUNCEMENTS_FAILURE:
    case CHANGE_ANNOUNCEMENT_STATUS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
}
