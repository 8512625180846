import { useState } from "react";

import LoginForm from "./LoginForm";
import { Button, Layout } from "../../components";

import styles from "./styles";

const SignIn = () => {
  const [userRoles, setUserRoles] = useState(null);

  const AccountTypeSelect = () => (
    <>
      <Layout.HeaderSection title="Login" subtitle="Select One To Continue" />
      <Layout.Spacer height={30} />
      <div style={{ width: "100%" }}>
        <Button.Primary
          style={{ ...styles.buttonStyle, fontWeight: "600" }}
          onClick={() => setUserRoles("admin")}
        >
          Admin
        </Button.Primary>
        <Layout.Spacer height={20} />
        <Button.Primary
          style={{ ...styles.buttonStyle, fontWeight: "600" }}
          onClick={() => setUserRoles("super-admin")}
        >
          Super Admin
        </Button.Primary>
      </div>
    </>
  );

  const AdminLogin = () => (
    <>
      <Layout.HeaderSection title="Admin Login" subtitle="Login to continue" />
      <Layout.Spacer height={30} />
      <LoginForm role={userRoles} />
    </>
  );

  const SuperAdminLogin = () => (
    <>
      <Layout.HeaderSection
        title="Super Admin Login"
        subtitle="Login to continue"
      />
      <Layout.Spacer height={30} />
      <LoginForm role={userRoles} />
    </>
  );

  return (
    <Layout.LoginLayout
      userRoles={userRoles}
      handleUserRoles={() => setUserRoles(null)}
    >
      {userRoles === null ? <AccountTypeSelect /> : null}
      {userRoles === "admin" ? <AdminLogin /> : null}
      {userRoles === "super-admin" ? <SuperAdminLogin /> : null}
    </Layout.LoginLayout>
  );
};

export default SignIn;
