import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";

import { AiFillRightCircle } from "react-icons/ai";
import { IoCallOutline } from "react-icons/io5";
import { IoGlobeOutline } from "react-icons/io5";
import { MdLocationPin, MdMailOutline } from "react-icons/md";

import { SchoolAction, StudentAction } from "../../store/actions";

import { Layout, Table, Text } from "../../components";
import { Colors, Images } from "../../config";

import styles from "./styles";

function SchoolDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const school = useSelector((state) => state.school.school);
  const students = useSelector((state) => state.student);

  const id = location.pathname.split("/")[2];

  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(SchoolAction.getSchoolDetails(id));
    const payload = {
      limit,
      offset,
      schoolId: id,
    };
    dispatch(StudentAction.getStudents(payload));
  }, []);

  const handlePaginationChange = (pageNumber) => {
    let offset = (pageNumber - 1) * limit;
    const payload = {
      limit,
      offset,
      schoolId: id,
    };
    dispatch(StudentAction.getStudents(payload));
    setOffset(offset);
    setPage(pageNumber);
  };

  const handleWebClick = (web) => {
    window.open(web, "_blank");
  };

  const handleEmailClick = (e, email) => {
    e.preventDefault();
    const provider = email.includes("@gmail.com")
      ? "https://mail.google.com/"
      : "https://outlook.live.com/";
    const url = `${provider}mail/u/0/#inbox?compose=${encodeURIComponent(
      email
    )}`;
    window.open(url, "_blank");
  };

  return (
    <div>
      <div>
        <div style={styles.justifyBetween}>
          <div>
            <div style={styles.schoolImageContainer}>
              <img
                src={school?.imageUrl || Images.SchoolProfile}
                alt=""
                style={styles.schoolImage}
              />
            </div>
            <div style={styles.schoolDetailsName}>{school?.name}</div>
            <div style={styles.schoolDetailsLocation}>
              <MdLocationPin style={{ marginRight: "10px" }} />
              {school?.location}
            </div>
          </div>
          <div style={styles.schoolInfoContainer}>
            <div style={styles.schoolInfoLeftBox}>
              <div>
                <IoGlobeOutline style={styles.leftBoxIcons} />
                <Text.Heading
                  style={styles.leftBoxText}
                  text={
                    school?.website?.length > 22
                      ? school?.website?.substring(0, 22) + "..."
                      : school?.website || "NA"
                  }
                  title={school?.website}
                  onClick={() => handleWebClick(school?.website)}
                />
                <Layout.Spacer height={10} />
              </div>
              <div>
                <IoCallOutline style={styles.leftBoxIcons} />
                <Text.Heading
                  style={styles.leftBoxText}
                  text={`+${school?.phoneNumber}`}
                />
                <Layout.Spacer height={10} />
              </div>
              <div>
                <MdMailOutline style={styles.leftBoxIcons} />

                <a
                  href={`mailto:${school?.email}`}
                  onClick={(e) => handleEmailClick(e, school?.email)}
                >
                  <Text.Heading
                    style={styles.leftBoxText}
                    text={
                      school?.email?.length > 22
                        ? school?.email?.substring(0, 22) + "..."
                        : school?.email
                    }
                    title={school?.email}
                  />
                </a>
              </div>
            </div>
            <div style={styles.schoolInfoRightBox}>
              <Text.Heading
                style={styles.rightBoxText}
                text={school?.enlistedStudents}
              />
              <Text.Heading
                style={styles.rightBoxTitle}
                text="Total Students"
              />
            </div>
          </div>
        </div>
      </div>

      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Students"
      />
      <Layout.Spacer height={20} />
      <Table.AntDesign
        rowKey={(record) => record._id}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            width: 500,
            onCell: (record) => ({
              onClick: () => {
                record.status !== "pending" &&
                  navigate(`/schools/student-details/${record?._id}`);
              },
            }),
            render: (text, record) => (
              <div>
                <img
                  src={record?.profileImage || Images.DummyImage}
                  alt=""
                  style={styles.studentTableImage}
                />
                <span style={styles.studentTableName}>{text}</span>
              </div>
            ),
          },
          {
            title: "Grade",
            dataIndex: "grade",
            align: "center",
            width: 350,
            onCell: (record) => ({
              onClick: () => {
                record.status !== "pending" &&
                  navigate(`/schools/student-details/${record?._id}`);
              },
            }),
            render: (text, record) => <span>{text}</span>,
          },
          {
            title: "Application Status",
            dataIndex: "status",
            align: "center",
            width: 350,
            onCell: (record) => ({
              onClick: () => {
                record.status !== "pending" &&
                  navigate(`/schools/student-details/${record?._id}`);
              },
            }),
            render: (text, record) => (
              <span style={styles.alignCenterJustifyBetween}>
                <span style={{ marginLeft: "100px" }}>
                  {record?.applications.length !== 0
                    ? "Applied"
                    : "Not applied"}
                </span>
                {record.status === "pending" ? (
                  <div style={{ marginRight: "20px" }}>Pending</div>
                ) : (
                  <AiFillRightCircle
                    style={{ ...styles.rightIcon, marginRight: "20px" }}
                    onClick={() => {
                      record.status !== "pending" &&
                        navigate(`/schools/student-details/${record?._id}`);
                    }}
                  />
                )}
              </span>
            ),
          },
        ]}
        data={students.students}
        loading={students.isLoading}
        pagination={false}
      />
      <Layout.Spacer height={20} />
      <div style={styles.flexCenter}>
        <Pagination
          responsive="true"
          showSizeChanger={false}
          hideOnSinglePage={false}
          current={page}
          pageSize={10}
          total={students.count}
          onChange={handlePaginationChange}
        />
      </div>
    </div>
  );
}

export default SchoolDetails;
