import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";

import moment from "moment";

import { AiOutlineSearch, AiOutlineCloudDownload } from "react-icons/ai";

import { IndividualStudentAction } from "../../store/actions";

import { Layout, Table, Text, TextField, Modal } from "../../components";
import { Colors, Images } from "../../config";

import styles from "./styles";

const IndividualStudents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const individualStudents = useSelector(
    (state) => state?.individualStudents?.individualStudents
  );
  const individualStudentsLoading = useSelector(
    (state) => state?.individualStudents?.isLoading
  );

  const [showModal, setShowModal] = useState(false);
  const [addressState, setAddressState] = useState({
    homeAddress: "NA",
    city: "NA",
    landmark: "NA",
    postalCode: "NA",
  });
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([
    {
      name: "All",
      selected: true,
    },
    {
      name: "Cash on Delivery",
      value: "CashOnDelivery",
      selected: false,
    },
    {
      name: "Jazz Cash",
      value: "JazzCash",
      selected: false,
    },
    {
      name: "Easy Paisa",
      value: "EasyPaisa",
      selected: false,
    },
    {
      name: "Credit/Debit Card",
      value: "Card",
      selected: false,
    },
  ]);

  const addressData = [
    { text: "Home Address", value: addressState.homeAddress },
    { text: "City", value: addressState.city },
    { text: "Landmark", value: addressState.landmark },
    { text: "Postal Code", value: addressState.postalCode },
  ];

  useEffect(() => {
    const selectedFilter = filter.filter((item) => item.selected === true);
    const payload = {
      search,
      filter: selectedFilter[0].name === "All" ? "" : selectedFilter[0].value,
      limit,
      offset: 0,
    };
    dispatch(IndividualStudentAction.getIndividualStudents(payload));

    setPage(1);
  }, [filter, search]);

  const handleAddress = (record) => {
    setAddressState({
      homeAddress: record?.homeAddress,
      city: record?.city,
      landmark: record?.landmark,
      postalCode: record?.postalCode,
    });
    setShowModal(true);
  };

  const handleFilterStatus = (status) => {
    const newFilters = filter.map((filter) => {
      if (filter.name === status.name) {
        return {
          ...filter,
          selected: !filter.selected,
        };
      } else {
        return {
          ...filter,
          selected: false,
        };
      }
    });
    setFilter(newFilters);
  };

  const handlePaginationChange = (pageNumber) => {
    const selectedFilter = filter.filter((item) => item.selected === true);
    let offset = (pageNumber - 1) * limit;

    const payload = {
      search,
      filter: selectedFilter[0].name === "All" ? "" : selectedFilter[0].value,
      limit,
      offset: offset,
    };
    dispatch(IndividualStudentAction.getIndividualStudents(payload));

    setOffset(offset);
    setPage(pageNumber);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const updatePaymentStatus = (id, value, type) => {
    const payload = {
      id: id,
      paymentStatus: value,
    };
    dispatch(
      IndividualStudentAction.updateIndividualStudentPayment(payload, () => {
        const selectedFilter = filter.filter((item) => item.selected === true);
        const payload = {
          search,
          filter:
            selectedFilter[0].name === "All" ? "" : selectedFilter[0].value,
          limit,
          offset,
        };
        dispatch(IndividualStudentAction.getIndividualStudents(payload));
      })
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 250,
      onCell: (record) => ({
        onClick: () => navigate(`/individualStudents/${record?._id}`),
      }),
      render: (text, record) => (
        <div style={styles.alignCenter}>
          <img
            src={record?.studentId?.profileImage || Images.DummyImage}
            alt="img"
            style={styles.imageStyle}
          />
          <div>
            <Text.Heading
              style={styles.studentName}
              text={record?.studentId?.name}
            />
          </div>
        </div>
      ),
    },

    {
      title: "Subscription Plan",
      dataIndex: "subscriptionPlan",
      align: "center",
      width: 150,
      onCell: (record) => ({
        onClick: () => navigate(`/individualStudents/${record?._id}`),
      }),
      render: (text, record) => <div>{record?.subscriptionPlan}</div>,
    },
    {
      title: "Referral Used",
      dataIndex: "referralUsed",
      align: "center",
      width: 150,
      onCell: (record) => ({
        onClick: () => navigate(`/individualStudents/${record?._id}`),
      }),
      render: (text, record) => <div>{record?.referralUsed}</div>,
    },
    {
      title: "Payment Method",
      dataIndex: "paymmentMethod",
      align: "center",
      width: 180,
      onCell: (record) => ({
        onClick: () => navigate(`/individualStudents/${record?._id}`),
      }),
      render: (text, record) => <div>{record?.paymentMethod || "NA"}</div>,
    },
    {
      title: "Alerted at",
      dataIndex: "alertedAt",
      align: "center",
      width: 200,
      onCell: (record) => ({
        onClick: () => navigate(`/individualStudents/${record?._id}`),
      }),
      render: (text, record) => {
        if (record?.alert) {
          return (
            <div>
              {moment(record?.alertedAt).format("DD MMMM YYYY hh:mm:ss A") ||
                "NA"}
            </div>
          );
        } else {
          return <div>NA</div>;
        }
      },
    },
    {
      title: "Payment type",
      dataIndex: "paymentType",
      align: "center",
      width: 200,
      onCell: (record) => ({
        onClick: () => navigate(`/individualStudents/${record?._id}`),
      }),
      render: (text, record) => {
        if (record?.paymentType) {
          return <div>{record?.paymentType}</div>;
        } else {
          return <div>NA</div>;
        }
      },
    },
    {
      title: "Payment cycle",
      dataIndex: "paymentCycle",
      align: "center",
      width: 200,
      onCell: (record) => ({
        onClick: () => navigate(`/individualStudents/${record?._id}`),
      }),
      render: (text, record) => {
        if (record?.startDate && record?.endDate) {
          return (
            <div>
              {moment(record?.startDate).format("DD MMMM YYYY") || "NA"} -{" "}
              {moment(record?.endDate).format("DD MMMM YYYY") || "NA"}
            </div>
          );
        } else {
          return <div>NA</div>;
        }
      },
    },

    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      align: "center",
      width: 200,

      render: (text, record) => {
        if (record?.paymentMethod === "CashOnDelivery") {
          return (
            <div
              className={
                record?.paymentStatus === "paid"
                  ? "student-action-activate"
                  : "student-action-deactivate"
              }
              style={styles.flexCenter}
            >
              <TextField.SelectField
                value={record?.paymentStatus || "activate"}
                onChange={(value) =>
                  updatePaymentStatus(record?._id, value, record?.paymentType)
                }
                data={[
                  {
                    label: "Paid",
                    value: "paid",
                  },
                  {
                    label: "Unpaid",
                    value: "unpaid",
                  },
                ]}
              />
            </div>
          );
        } else {
          return (
            <div
              style={{
                textTransform: "uppercase",
                fontWeight: "600",
                color:
                  record?.paymentStatus === "paid"
                    ? Colors.Success
                    : Colors.Red,
              }}
            >
              {record?.paymentStatus || "NA"}
            </div>
          );
        }
      },
    },
    {
      title: "Attachments",
      dataIndex: "attachments",
      align: "center",
      width: 200,
      render: (text, record) => {
        if (!record?.receipt || record?.receipt === "NA") {
          if (record?.paymentMethod === "CashOnDelivery") {
            return (
              <div
                style={styles.flexCenter}
                onClick={() => handleAddress(record)}
              >
                <div style={styles.addressInfo}>Address Info</div>
              </div>
            );
          }
        } else {
          return (
            <div
              style={styles.flexCenter}
              onClick={() => handleAddress(record)}
            >
              <div style={styles.addressInfo}>Address Info</div>
              <div style={{ cursor: "pointer" }}>
                <AiOutlineCloudDownload
                  fontSize="25px"
                  color={Colors.Primary}
                  cursor={"pointer"}
                  onClick={() => {
                    const url = record?.receipt;
                    window.open(url, "_blank");
                  }}
                />
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div>
      <TextField.CustomInput
        placeholder="Search Student"
        suffix={<AiOutlineSearch style={styles.searchIcon} />}
        value={search}
        onChange={handleChange}
        style={styles.searchInput}
      />
      <Layout.Spacer height={20} />
      <div style={styles.flexRow}>
        <Text.Heading
          style={styles.filterText}
          text="Filter by Payment Method"
        />
        <div style={styles.flexRow}>
          {filter.map((filter) => (
            <div
              style={{
                ...styles.filter,
                backgroundColor: filter?.selected
                  ? Colors.Primary
                  : Colors.White,
                color: filter?.selected ? Colors.White : Colors.BlackText2,
                border: filter?.selected ? "" : "1px solid #387f768c",
              }}
              onClick={() => handleFilterStatus(filter)}
              key={filter?.name}
            >
              {filter?.name}
            </div>
          ))}
        </div>
      </div>
      <Layout.Spacer height={15} />
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        text="Individual Students"
      />
      <Layout.Spacer height={20} />
      <Table.AntDesign
        rowKey={(record) => record._id}
        columns={columns}
        data={individualStudents?.individualStudents}
        loading={individualStudentsLoading}
        pagination={false}
      />
      <Layout.Spacer height={20} />
      <div style={styles.flexCenter}>
        <Pagination
          responsive="true"
          showSizeChanger={false}
          hideOnSinglePage={false}
          current={page}
          pageSize={10}
          total={individualStudents?.count}
          onChange={handlePaginationChange}
        />
      </div>
      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => setShowModal(false)}
          closable={false}
          style={{ padding: "20px" }}
        >
          <Layout.Spacer height={10} />
          <Text.Heading
            fontSize={"18px"}
            fontWeight={"600"}
            color={Colors.Primary}
            text={"Address Information"}
          />
          <Layout.Spacer height={15} />

          {addressData?.map((item) => (
            <>
              <div style={styles.justifyBetween}>
                <Text.Heading
                  fontSize={"14px"}
                  fontWeight={"600"}
                  color={Colors.Primary}
                  text={item.text}
                />
                <Text.Heading
                  fontSize={"14px"}
                  fontWeight={"600"}
                  color={Colors.Black}
                  text={item.value}
                />
              </div>
              <Layout.Spacer height={15} />
            </>
          ))}

          <Layout.Spacer height={10} />
        </Modal.Basic>
      )}
    </div>
  );
};

export default IndividualStudents;
