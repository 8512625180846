import React from "react";

import { Colors } from "../../config";
import { Layout, Text } from "../../components";

function About() {
  return (
    <>
      <Text.Heading
        fontSize={"18px"}
        fontWeight={"600"}
        color={Colors.Primary}
        textAlign="left"
        text={"Privacy Policy"}
      />
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"16px"}
        fontWeight={"600"}
        color={Colors.Black}
        textAlign="left"
        textTransform="capitalize"
        text={"Introduction"}
      />
      <Layout.Spacer height={10} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.BlackText}
        textAlign="left"
        text={
          'UniVision (hereinafter referred to as the “Company”), is committed to providing the highest level of security and online privacy. “Personal Information" means any information that may be used to identify an individual, including, but not limited to, a first and last name, phone number and an email address or other identifiable contact information.'
        }
      />
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.BlackText}
        textAlign="left"
        text={
          "In general, you can visit the UniVision website and webpages without telling us who you are or revealing any Personal Information about yourself in the process. Personal Information may be collected through the submission of service requests, online banking transactions or through the use of online financial tools."
        }
      />
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.BlackText}
        textAlign="left"
        text={
          "This Privacy Policy demonstrates our commitment to protecting information that the users share with us. This Privacy Policy applies to data gathered on the website & webpages. This Privacy Policy shall be reviewed periodically as we may update it from time to time, so we recommend reviewing this accordingly. Each time the user accesses the UniVision or provides us with information, the user is, by doing so, effectively consenting to the practices described in this Privacy Policy at that time."
        }
      />
      <Layout.Spacer height={20} />

      <Text.Heading
        fontSize={"16px"}
        fontWeight={"600"}
        color={Colors.Black}
        textAlign="left"
        textTransform="capitalize"
        text={"Respect of Right to Privacy"}
      />
      <Layout.Spacer height={10} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.BlackText}
        textAlign="left"
        text={
          "UniVision maintains strict policies and security controls to ensure that user information contained in the systems and files is protected. UniVision respects user’s right to privacy. UniVision is committed to ensuring the privacy of its users’ personal information."
        }
      />
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"16px"}
        fontWeight={"600"}
        color={Colors.Black}
        textAlign="left"
        textTransform="capitalize"
        text={"Privacy Policy and our Cookie Policy"}
      />
      <Layout.Spacer height={10} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.BlackText}
        textAlign="left"
        text={
          "At UniVision, we use cookies only for the protection and convenience of our users. Cookies enable us to serve secure pages to our users without asking them to sign in repeatedly. Once you agree to allow our website and webpages to use cookies, you also agree to our usage of the data it collects. This may include data that enables us to analyze web traffic, the web pages you spend the most time on, and the websites you visit."
        }
      />
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.BlackText}
        textAlign="left"
        text={
          "The data we collect by using cookies is used to customize our website to your needs. After we use the data for statistical analysis, the data is completely removed from our systems routinely. Please note that cookies don't allow us to gain control of your computer in any way or form. They are strictly used to monitor which pages you find useful and which you do not so that we can provide a better experience for you."
        }
      />
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"16px"}
        fontWeight={"600"}
        color={Colors.Black}
        textAlign="left"
        textTransform="capitalize"
        text={"A Limited Employee Access to User’s Personal Information"}
      />
      <Layout.Spacer height={10} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.BlackText}
        textAlign="left"
        text={
          "UniVision limits its access to your personal information to only employees who hold a business reason for having access to user’s information. UniVision employees are aware about the importance of confidentiality and customer privacy."
        }
      />
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"16px"}
        fontWeight={"600"}
        color={Colors.Black}
        textAlign="left"
        textTransform="capitalize"
        text={"Third Party Disclosure Restrictions"}
      />
      <Layout.Spacer height={10} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.BlackText}
        textAlign="left"
        text={
          "UniVision follows strict privacy procedures in protecting your personal information. Third parties provide certain services available on the School Admin portal such as payment gateway services on UniVision’s behalf. UniVision may provide information, including Personal Information, that the Company collects on the Web to third-party service providers to help us deliver programs, products, information, and services. Additionally, service providers are also an important means by which the Company maintains its Web site and mailing lists."
        }
      />
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.BlackText}
        textAlign="left"
        text={
          "Any Personal Information will be protected and UniVision will take reasonable measures to ensure that these third-party service providers are obligated to protect Personal Information on the Company’s behalf. However, UniVision does not assume responsibility for any action of a third party regarding this information in the case of a data breach. The Company does not intend to transfer Personal Information without your consent to third parties who are not bound to act on UniVision behalf unless such transfer is legally required. Neither does UniVision provide personal information to other companies for the purpose of independent telemarketing or direct mail marketing of any non-financial products or services of those companies."
        }
      />
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"16px"}
        fontWeight={"600"}
        color={Colors.Black}
        textAlign="left"
        textTransform="capitalize"
        text={"User’s Consent"}
      />
      <Layout.Spacer height={10} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.BlackText}
        textAlign="left"
        text={
          "By using the UniVision mobile and web applications, the user consents to the terms of UniVision’s Online Privacy Policy and to UniVision’s processing of Personal Information for the purposes given previously. At UniVision, we take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data on a best effort basis. We restrict access to our users personal identifying information to employees who need to know that information to operate or improve our services."
        }
      />
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"16px"}
        fontWeight={"600"}
        color={Colors.Black}
        textAlign="left"
        textTransform="capitalize"
        text={"Changes to this Privacy Policy"}
      />
      <Layout.Spacer height={10} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.BlackText}
        textAlign="left"
        text={
          "UniVision reserves the right to update, change or modify this policy at any time. The policy shall come to effect from the date of such update, change or modification."
        }
      />
      <Layout.Spacer height={20} />
      <Text.Heading
        fontSize={"16px"}
        fontWeight={"600"}
        color={Colors.Black}
        textAlign="left"
        textTransform="capitalize"
        text={"Contact Information"}
      />
      <Layout.Spacer height={10} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.BlackText}
        textAlign="left"
        text={
          "If you have additional questions regarding the privacy and security of your personal information, consider referring to UniVision’s Terms and Conditions, however, if the query persists, please contact us on admin@univision.hk. UniVision welcomes your comments regarding this Privacy Policy."
        }
      />
      <Layout.Spacer height={10} />
      <Text.Heading
        fontSize={"14px"}
        fontWeight={"500"}
        color={Colors.BlackText}
        textAlign="left"
        text={
          "However, it is clarified that UniVision is not obligated to comply with any requests or changes suggested by the User and UniVision shall have the exclusive decision-making right in this regard."
        }
      />
      <Layout.Spacer height={30} />
    </>
  );
}

export default About;
