import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Settings from ".";

import { AuthAction, ProfileAction } from "../../store/actions";

import { Button, Layout, Text, TextField } from "../../components";
import { Colors, Utils } from "../../config";

import styles from "./styles";

function ChangePassword() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.user.user);

  const [state, setState] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChangePassword = () => {
    if (!state.password) {
      setError({
        ...error,
        password: "Password cannot be left empty",
      });
    } else if (!Utils.passwordRegex.test(state.password)) {
      setError({
        ...error,
        password: "Please enter a valid password",
      });
    } else if (!state.newPassword) {
      setError({
        ...error,
        password: "",
        newPassword: "Password cannot be left empty",
      });
    } else if (!Utils.passwordRegex.test(state.newPassword)) {
      setError({
        ...error,
        newPassword: "Please enter a valid password",
      });
    } else if (!state.confirmPassword) {
      setError({
        password: "",
        newPassword: "",
        confirmPassword: "Password cannot be left empty",
      });
    } else if (!Utils.passwordRegex.test(state.confirmPassword)) {
      setError({
        ...error,
        confirmPassword: "Please enter a valid password",
      });
    } else if (state.newPassword !== state.confirmPassword) {
      setError({
        password: "",
        newPassword: "",
        confirmPassword: "Password did not match",
      });
    } else {
      setError({
        password: "",
        newPassword: "",
        confirmPassword: "",
      });
      const body = {
        email: auth.email,
        password: state.password,
        newPassword: state.confirmPassword,
      };
      dispatch(
        ProfileAction.changePassword(body, (res) => {
          if (
            res.data.status === 400 &&
            res.data.message === "Wrong password."
          ) {
            setError({
              password: "Incorrect password",
            });
          } else {
            setError({
              password: "",
              newPassword: "",
              confirmPassword: "",
            });
            setState({
              password: "",
              newPassword: "",
              confirmPassword: "",
            });
            dispatch(AuthAction.Logout());
          }
        })
      );
    }
  };

  return (
    <>
      <Settings>
        <Text.Heading
          fontSize={"16px"}
          fontWeight={"600"}
          color={Colors.BlackText2}
          text="Security Settings"
        />
        <Layout.Spacer height={20} />
        <Text.Heading
          fontSize={"15px"}
          fontWeight={"700"}
          color={Colors.Primary}
          text="Reset Password"
        />
        <Layout.Spacer height={5} />
        <Text.Heading
          fontSize={"12px"}
          fontWeight={"500"}
          color={Colors.BlackText}
          text="The password must be 8 characters long and must include atleast one special character and atleast one number."
        />
        <Layout.Spacer height={20} />
        <div style={styles.flexColumn}>
          <div style={styles.flexColumn}>
            <TextField.CustomInput
              password
              placeholder="********"
              label="Current Password"
              value={state.password}
              onChange={(e) =>
                setState({
                  ...state,
                  password: e.target.value,
                })
              }
              style={{ ...styles.textfieldStyle, width: "500px" }}
            />
            {error.password && (
              <Text.Heading
                fontSize={"14px"}
                fontWeight={"400"}
                color={"red"}
                textAlign="left"
                text={error.password}
              />
            )}
          </div>
          <Layout.Spacer height={20} />
          <div style={styles.flexColumn}>
            <TextField.CustomInput
              password
              placeholder="********"
              label="New Password"
              value={state.newPassword}
              onChange={(e) =>
                setState({
                  ...state,
                  newPassword: e.target.value,
                })
              }
              style={{ ...styles.textfieldStyle, width: "500px" }}
            />
            {error.newPassword && (
              <Text.Heading
                fontSize={"14px"}
                fontWeight={"400"}
                color={"red"}
                textAlign="left"
                text={error.newPassword}
              />
            )}
          </div>
          <Layout.Spacer height={20} />
          <div style={styles.flexColumn}>
            <TextField.CustomInput
              password
              placeholder="********"
              label="Confirm New Password"
              value={state.confirmPassword}
              onChange={(e) =>
                setState({
                  ...state,
                  confirmPassword: e.target.value,
                })
              }
              style={{ ...styles.textfieldStyle, width: "500px" }}
            />
            {error.confirmPassword && (
              <Text.Heading
                fontSize={"14px"}
                fontWeight={"400"}
                color={"red"}
                textAlign="left"
                text={error.confirmPassword}
              />
            )}
          </div>
        </div>
        <Layout.Spacer height={20} />
        <Button.Primary
          style={styles.buttonStyle}
          onClick={handleChangePassword}
        >
          Save Changes
        </Button.Primary>

        <Layout.Spacer height={40} />
      </Settings>
    </>
  );
}

export default ChangePassword;
