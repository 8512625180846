import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Lottie from "react-lottie-player";

import { ImBin2 } from "react-icons/im";

import { UniversityAction } from "../../store/actions";

import { Button, Layout, Text, TextField, Modal } from "../../components";
import { Colors } from "../../config";

import Done from "../../assets/images/57767-done.json";

import styles from "./styles";

function Finances() {
  const location = useLocation();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.university.university?.finances);
  const id = location.pathname.split("/")[2];

  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [state, setState] = useState({
    scholarship: [
      {
        sr: "",
        title: "",
        link: "",
        amount: "",
        duration: "",
        eligibilityCriteria: "",
      },
    ],
  });

  useEffect(() => {
    dispatch(UniversityAction.getUniversityDetails(id));
  }, []);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeScholarship = (value, key, index) => {
    let newValue = [...state.scholarship];
    newValue[index][key] = value;
    setState({
      ...state,
      scholarship: newValue,
    });
  };

  const handleRemoveScholarship = (index) => {
    var array = [...state.scholarship];
    array.splice(index, 1);
    setState({
      ...state,
      scholarship: array,
    });
  };

  const handleAddMoreScholarship = () => {
    let checkFieldsValidation = checkFields();
    if (checkFieldsValidation) {
      setError("Please fill all fields");
    } else {
      setError("");
      setState({
        ...state,
        scholarship: [
          ...state.scholarship,
          {
            sr: "",
            title: "",
            link: "",
            amount: "",
            // amountCurrency: "USD",
            duration: "",
            eligibilityCriteria: "",
          },
        ],
      });
    }
  };

  const checkFields = () => {
    let flag = false;
    state.scholarship.forEach((item) => {
      if (!item.title) {
        flag = true;
      } else if (!item.amount) {
        flag = true;
      } else if (!item.eligibilityCriteria) {
        flag = true;
      } else if (!item.duration) {
        flag = true;
      } else if (!item.sr) {
        flag = true;
      } else if (!item.eligibilityCriteria) {
        flag = true;
      } else if (!item.link) {
        flag = true;
      }
    });
    return flag;
  };

  const handleClear = () => {
    setState({
      scholarship: [
        {
          sr: "",
          title: "",
          link: "",
          amount: "",
          // amountCurrency: "USD",
          duration: "",
          eligibilityCriteria: "",
        },
      ],
    });
  };

  const handleNext = () => {
    setSaveButtonDisabled(true);
    if (checkFields()) {
      setSaveButtonDisabled(false);
      setError("Please fill all required fields");
    } else {
      setError("");
      let dataToUpload = new FormData();
      dataToUpload.append("finances", JSON.stringify(state.scholarship));
      dataToUpload.append("tab", "finances");

      dispatch(
        UniversityAction.editUniversity(id, dataToUpload, (res) => {
          setSaveButtonDisabled(false);
          if (res.status === 200) {
            setShowModal(true);
          }
        })
      );
    }
  };

  useEffect(() => {
    const scholarshipsData = data.scholarships.map((item) => {
      return {
        sr: item.sr ? item.sr : "NA",
        title: item?.title ? item?.title : "NA",
        link: item.link ? item.link : "NA",
        amount: item?.amount ? item?.amount : "NA",
        // amountCurrency:
        //   university.studentLife?.onCampus?.averageMonthlyAccommodationCost.split(
        //     " "
        //   )[0],
        duration: item?.duration ? item?.duration : "NA",
        eligibilityCriteria: item?.eligibilityCriteria
          ? item?.eligibilityCriteria
          : "NA",
      };
    });

    setState({
      scholarship: scholarshipsData,
    });
  }, []);

  return (
    <div style={styles.inputContainer}>
      <div style={styles.justifyBetween}>
        <Text.Heading
          fontSize={"16px"}
          fontWeight={"700"}
          color={Colors.Primary}
          text="Financial Aid Scholarships"
        />
        <div onClick={handleClear}>
          <Text.Heading
            fontSize={"10px"}
            fontWeight={"400"}
            color={Colors.Gray}
            text="Clear Fields"
          />
          <ImBin2 color={Colors.Primary} fontSize={14} />
        </div>
      </div>
      <div>
        {state?.scholarship?.map((item, index) => (
          <>
            <Layout.Spacer height={20} />
            <div style={styles.programNumber}>Scholarship {index + 1}</div>
            <Layout.Spacer height={10} />
            <div style={styles.costTitleContainer}>
              <div>
                <div style={styles.inputLabel}>Sr No.</div>
                <TextField.CustomInput
                  placeholder="1"
                  name="sr"
                  value={item.sr}
                  onChange={(e) =>
                    handleChangeScholarship(e.target.value, "sr", index)
                  }
                  style={styles.textfieldStyle}
                />
              </div>
              <div>
                <div style={styles.inputLabel}>Scholarship Title</div>
                <TextField.CustomInput
                  placeholder="Red Cross Scholarship"
                  name="title"
                  value={item.title}
                  onChange={(e) =>
                    handleChangeScholarship(e.target.value, "title", index)
                  }
                  style={styles.textfieldStyle}
                />
              </div>
              <div>
                <div style={styles.inputLabel}>Scholarship (Link)</div>
                <TextField.CustomInput
                  placeholder="https://link.com"
                  name="link"
                  value={item.link}
                  onChange={(e) =>
                    handleChangeScholarship(e.target.value, "link", index)
                  }
                  style={styles.textfieldStyle}
                />
              </div>
              <div className="input-group">
                <div style={styles.inputLabel}>Amount</div>
                <TextField.CustomInput
                  placeholder="USD 1000"
                  name="amount"
                  value={item.amount}
                  onChange={(e) =>
                    handleChangeScholarship(e.target.value, "amount", index)
                  }
                  style={styles.textfieldStyle}
                />
              </div>

              <div>
                <div style={styles.inputLabel}>Award Type</div>
                <TextField.CustomInput
                  placeholder="Enter Type"
                  name="duration"
                  value={item.duration}
                  onChange={(e) =>
                    handleChangeScholarship(e.target.value, "duration", index)
                  }
                  style={styles.textfieldStyle}
                />
              </div>
              <div>
                <div style={styles.inputLabel}>Requirements</div>
                <TextField.CustomTextArea
                  rows={1}
                  name="eligibilityCriteria"
                  value={item.eligibilityCriteria}
                  onChange={(e) =>
                    handleChangeScholarship(
                      e.target.value,
                      "eligibilityCriteria",
                      index
                    )
                  }
                  placeholder="Requirements"
                  style={styles.textfieldStyle}
                />
              </div>
            </div>

            {state.scholarship.length > 1 && index !== 0 && (
              <div style={styles.flexRowEnd}>
                <Button.Primary
                  style={styles.removeButton}
                  onClick={() => handleRemoveScholarship(index)}
                >
                  Remove Scholarship
                </Button.Primary>
              </div>
            )}
          </>
        ))}
      </div>
      <Layout.Spacer height={20} />
      <div style={styles.flexRowEnd}>
        <Button.Primary
          style={{ ...styles.buttonStyle, margin: "0px", width: "250px" }}
          onClick={handleAddMoreScholarship}
        >
          Add More Scholarships
        </Button.Primary>
      </div>

      {error && (
        <div style={styles.flexColumnCenter}>
          <Layout.Spacer height={20} />
          <Text.Heading
            fontSize={"16px"}
            fontWeight={"500"}
            color={Colors.Danger}
            text={error}
          />
        </div>
      )}

      <Layout.Spacer height={20} />
      <div style={styles.flexColumnCenter}>
        <Button.Primary
          style={styles.buttonStyle}
          onClick={handleNext}
          disabled={saveButtonDisabled}
        >
          Save/Continue
        </Button.Primary>
      </div>

      {showModal && (
        <Modal.Basic
          isModalVisible={showModal}
          centered={true}
          handleCancel={() => setShowModal(false)}
          closeIcon={true}
          className="ant-modal"
        >
          <div style={styles.flexColumnCenter}>
            <Lottie
              loop
              animationData={Done}
              play
              style={styles.modalIcon}
            ></Lottie>
            <div style={styles.modalText}>Successful!</div>
            <Button.Primary
              style={styles.modalButtonStyle}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Done
            </Button.Primary>
          </div>
        </Modal.Basic>
      )}
    </div>
  );
}
export default Finances;
